export default [
  {
    name: 'Hold Raegh',
    factions: ['Dweghom'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/hold-raegh.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/hold-raegh.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Tempered Sorcerer',
    factions: ['Dweghom'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/tempered-sorcerer.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/tempered-sorcerer.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Hellbringer Sorcerer',
    factions: ['Dweghom'],
    type: 'Characters',
    size: '100mm',
    maxMeleeRange: 1,
    resource1: 18,
    avatar: '/Conquest/avatars/hellbringer-sorcerer.jpg',
    commandCard: null,
  },
  {
    name: 'Tempered Steelshaper',
    factions: ['Dweghom'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/tempered-steelshaper.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/tempered-steelshaper.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Ardent Kerawegh',
    factions: ['Dweghom'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/ardent-kerawegh.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/ardent-kerawegh.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Bergont Raegh',
    factions: ['Dweghom'],
    type: 'Characters',
    size: '100mm',
    maxMeleeRange: 1,
    resource1: 23,
    avatar: '/Conquest/avatars/bergont-raegh.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/bergont-raegh.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Bodyguard',
    factions: ['Dweghom'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/dweghom-default.jpg',
    commandCard: null,
  },
  {
    name: 'Duelist',
    factions: ['Dweghom'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/dweghom-default.jpg',
    commandCard: null,
  },
  {
    name: 'Warrior',
    factions: ['Dweghom'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/dweghom-default.jpg',
    commandCard: null,
  },
  {
    name: 'Advisor',
    factions: ['Dweghom'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/dweghom-default.jpg',
    commandCard: null,
  },
  {
    name: 'Bannerman',
    factions: ['Dweghom'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/dweghom-default.jpg',
    commandCard: null,
  },
  {
    name: 'Acolyte',
    factions: ['Dweghom'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/dweghom-default.jpg',
    commandCard: null,
  },
  {
    name: 'Exemplar',
    factions: ['Dweghom'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/exemplar.jpg',
    commandCard: null,
  },
  {
    name: 'Flamecaster',
    factions: ['Dweghom'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/flamecaster.jpg',
    commandCard: null,
  },
  {
    name: 'Herald of Fire',
    factions: ['Dweghom'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/herald-of-fire.jpg',
    commandCard: null,
  },
  {
    name: 'Herald of Stone',
    factions: ['Dweghom'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/herald-of-stone.jpg',
    commandCard: null,
  },
  {
    name: 'Mnemancer Apprentice',
    factions: ['Dweghom'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/mnemancer-apprentice.jpg',
    commandCard: null,
  },
  {
    name: 'Herald of Magma',
    factions: ['Dweghom'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/herald-of-magma.jpg',
    commandCard: null,
  },
  {
    name: 'Flame Berserkers',
    factions: ['Dweghom'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/flame-berserkers.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/flame-berserkers.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Hold Ballistae',
    factions: ['Dweghom'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/hold-ballistae.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/hold-ballistae.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Hold Warriors',
    factions: ['Dweghom'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/hold-warriors.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/hold-warriors.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Fireforged',
    factions: ['Dweghom'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/fireforged.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/fireforged.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Initiates (Shields)',
    factions: ['Dweghom'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/initiates-shields.jpg',
    commandCard: null,
  },
  {
    name: 'Initiates (Pikes)',
    factions: ['Dweghom'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/initiates-pikes.jpg',
    commandCard: null,
  },
  {
    name: 'Wardens',
    factions: ['Dweghom'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/wardens.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/wardens.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Dragonslayers',
    factions: ['Dweghom'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/dragonslayers.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/dragonslayers.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Hold Thanes',
    factions: ['Dweghom'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/hold-thanes.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/hold-thanes.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Inferno Automata',
    factions: ['Dweghom'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/inferno-automata.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/inferno-automata.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Hellbringer Drake',
    factions: ['Dweghom'],
    type: 'Regiments',
    size: '100mm',
    maxMeleeRange: 1,
    resource1: 15,
    avatar: '/Conquest/avatars/hellbringer-drake.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/hellbringer-drake.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Steelforged',
    factions: ['Dweghom'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/steelforged.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/steelforged.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Ironclad Drake',
    factions: ['Dweghom'],
    type: 'Regiments',
    size: '100mm',
    maxMeleeRange: 1,
    resource1: 23,
    avatar: '/Conquest/avatars/ironclad-drake.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/ironclad-drake.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Magmaforged',
    factions: ['Dweghom'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/magmaforged.jpg',
    commandCard: null,
  },
  {
    name: 'Stoneforged',
    factions: ['Dweghom'],
    type: 'Regiments',
    size: '100mm',
    maxMeleeRange: 1,
    resource1: 20,
    avatar: '/Conquest/avatars/stoneforged.jpg',
    commandCard: null,
  },
  {
    name: 'Blooded (Crow)',
    factions: ['Nords'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/blooded-crow.jpg',
    commandCard: null,
  },
  {
    name: 'Blooded (Wolf)',
    factions: ['Nords'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/blooded-wolf.jpg',
    commandCard: null,
  },
  {
    name: 'Jarl',
    factions: ['Nords'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/jarl.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/jarl.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Konungyr',
    factions: ['Nords'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 7,
    avatar: '/Conquest/avatars/konungyr.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/konungyr.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Shaman',
    factions: ['Nords'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/shaman.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/shaman.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Volva',
    factions: ['Nords'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/volva.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/volva.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Vargyr Lord',
    factions: ['Nords'],
    type: 'Characters',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 8,
    avatar: '/Conquest/avatars/vargyr-lord.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/vargyr-lord.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Bodyguard',
    factions: ['Nords'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/nords-default.jpg',
    commandCard: null,
  },
  {
    name: 'Duelist',
    factions: ['Nords'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/nords-default.jpg',
    commandCard: null,
  },
  {
    name: 'Warrior',
    factions: ['Nords'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/nords-default.jpg',
    commandCard: null,
  },
  {
    name: 'Tracker',
    factions: ['Nords'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/nords-default.jpg',
    commandCard: null,
  },
  {
    name: 'Tactician',
    factions: ['Nords'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/nords-default.jpg',
    commandCard: null,
  },
  {
    name: 'Bannerman',
    factions: ['Nords'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/nords-default.jpg',
    commandCard: null,
  },
  {
    name: 'Acolyte',
    factions: ['Nords'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/nords-default.jpg',
    commandCard: null,
  },
  {
    name: 'Shield Biter',
    factions: ['Nords'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/shield-biter.jpg',
    commandCard: null,
  },
  {
    name: 'Captain',
    factions: ['Nords'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/captain.jpg',
    commandCard: null,
  },
  {
    name: 'Skald',
    factions: ['Nords'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/skald.jpg',
    commandCard: null,
  },
  {
    name: 'Thegn',
    factions: ['Nords'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 6,
    avatar: '/Conquest/avatars/thegn.jpg',
    commandCard: null,
  },
  {
    name: 'Savage',
    factions: ['Nords'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/savage.jpg',
    commandCard: null,
  },
  {
    name: 'Raiders',
    factions: ['Nords'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/raiders.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/raiders.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Stalkers',
    factions: ['Nords'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/stalkers.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/stalkers.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Huskarls',
    factions: ['Nords'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/huskarls.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/huskarls.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Trolls',
    factions: ['Nords'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/trolls.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/trolls.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Fenr Beastpack',
    factions: ['Nords'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/fenr-beastpack.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/fenr-beastpack.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Bow Chosen',
    factions: ['Nords'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/bow-chosen.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/bow-chosen.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Valkyries',
    factions: ['Nords'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/valkyries.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/valkyries.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Ugr',
    factions: ['Nords'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/ugr.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/ugr.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Mountain Jotnar',
    factions: ['Nords'],
    type: 'Regiments',
    size: '100mm',
    maxMeleeRange: 1,
    resource1: 18,
    avatar: '/Conquest/avatars/mountain-jotnar.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/mountain-jotnar.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Ice Jotnar',
    factions: ['Nords'],
    type: 'Regiments',
    size: '100mm',
    maxMeleeRange: 1,
    resource1: 17,
    avatar: '/Conquest/avatars/ice-jotnar.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/ice-jotnar.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Sea Jotnar',
    factions: ['Nords'],
    type: 'Regiments',
    size: '100mm',
    maxMeleeRange: 1,
    resource1: 17,
    avatar: '/Conquest/avatars/sea-jotnar.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/sea-jotnar.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Ulfhednar',
    factions: ['Nords'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/ulfhednar.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/ulfhednar.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Bearsarks',
    factions: ['Nords'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/bearsarks.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/bearsarks.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Werewargs',
    factions: ['Nords'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/werewargs.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/werewargs.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Maharajah',
    factions: ['Sorcerer Kings'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/maharajah.jpg',
    commandCard: null,
  },
  {
    name: 'Sorcerer',
    factions: ['Sorcerer Kings'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/sorcerer.jpg',
    commandCard: null,
  },
  {
    name: 'Raj',
    factions: ['Sorcerer Kings'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/raj.jpg',
    commandCard: null,
  },
  {
    name: 'Sardar',
    factions: ['Sorcerer Kings'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/sardar.jpg',
    commandCard: null,
  },
  {
    name: 'Favored of Hormus',
    factions: ['Sorcerer Kings'],
    type: 'Characters',
    size: '100mm',
    maxMeleeRange: 1,
    resource1: 28,
    avatar: '/Conquest/avatars/sorcerers-default.jpg',
    commandCard: null,
  },
  {
    name: 'Rajakur Corps',
    factions: ['Sorcerer Kings'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/sorcerers-default.jpg',
    commandCard: null,
  },
  {
    name: 'Bound Elemental (Efreet Sword Dancer)',
    factions: ['Sorcerer Kings'],
    type: 'Retinues',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/sorcerers-default.jpg',
    commandCard: null,
  },
  {
    name: 'Bound Elemental (Steelheart Djinn)',
    factions: ['Sorcerer Kings'],
    type: 'Retinues',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/sorcerers-default.jpg',
    commandCard: null,
  },
  {
    name: 'Rajakur',
    factions: ['Sorcerer Kings'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/rajakur.jpg',
    commandCard: null,
  },
  {
    name: 'Ghols',
    factions: ['Sorcerer Kings'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/ghols.jpg',
    commandCard: null,
  },
  {
    name: 'Efreet Sword Dancers',
    factions: ['Sorcerer Kings'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/efreet-sword-dancers.jpg',
    commandCard: null,
  },
  {
    name: 'Efreet Flamecasters',
    factions: ['Sorcerer Kings'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/efreet-flamecasters.jpg',
    commandCard: null,
  },
  {
    name: 'Steelheart Djinn',
    factions: ['Sorcerer Kings'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/steelheart-djinn.jpg',
    commandCard: null,
  },
  {
    name: 'Windborne Djinn',
    factions: ['Sorcerer Kings'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/windborne-djinn.jpg',
    commandCard: null,
  },
  {
    name: 'Rakshasa Bakasura',
    factions: ['Sorcerer Kings'],
    type: 'Regiments',
    size: '100mm',
    maxMeleeRange: 1,
    resource1: 20,
    avatar: '/Conquest/avatars/rakshasa-bakasura.jpg',
    commandCard: null,
  },
  {
    name: 'Rakshasa Ravanar',
    factions: ['Sorcerer Kings'],
    type: 'Regiments',
    size: '100mm',
    maxMeleeRange: 1,
    resource1: 20,
    avatar: '/Conquest/avatars/rakshasa-ravanar.jpg',
    commandCard: null,
  },
  {
    name: 'Dhanur Disciples',
    factions: ['Sorcerer Kings'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/dhanur-disciples.jpg',
    commandCard: null,
  },
  {
    name: 'Mahut',
    factions: ['Sorcerer Kings'],
    type: 'Regiments',
    size: '100mm',
    maxMeleeRange: 1,
    resource1: 28,
    avatar: '/Conquest/avatars/mahut.jpg',
    commandCard: null,
  },
  {
    name: 'Polemarch',
    factions: ['City States'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/polemarch.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/polemarch.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Aristarch',
    factions: ['City States'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/aristarch.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/aristarch.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Mechanist',
    factions: ['City States'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/mechanist.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/mechanist.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Eidolon',
    factions: ['City States'],
    type: 'Characters',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 8,
    avatar: '/Conquest/avatars/eidolon.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/eidolon.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Ipparchos',
    factions: ['City States'],
    type: 'Characters',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 7,
    avatar: '/Conquest/avatars/ipparchos.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/ipparchos.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Bodyguard',
    factions: ['City States'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/states-default.jpg',
    commandCard: null,
  },
  {
    name: 'Veteran',
    factions: ['City States'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/states-default.jpg',
    commandCard: null,
  },
  {
    name: 'Warrior',
    factions: ['City States'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/states-default.jpg',
    commandCard: null,
  },
  {
    name: 'Lochagos',
    factions: ['City States'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/lochagos.jpg',
    commandCard: null,
  },
  {
    name: 'Dorilates',
    factions: ['City States'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/dorilates.jpg',
    commandCard: null,
  },
  {
    name: 'Andromachos',
    factions: ['City States'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/andromachos.jpg',
    commandCard: null,
  },
  {
    name: 'Sacred Band',
    factions: ['City States'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/sacred-band-officer.jpg',
    commandCard: null,
  },
  {
    name: 'Thorakites',
    factions: ['City States'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/thorakites.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/thorakites.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Hoplites',
    factions: ['City States'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/hoplites.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/hoplites.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Phalangites',
    factions: ['City States'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/phalangites.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/phalangites.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Agema',
    factions: ['City States'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/agema.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/agema.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Companion Cavalry',
    factions: ['City States'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/companion-cavalry.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/companion-cavalry.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Minotaur Haspists',
    factions: ['City States'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/minotaur-haspists.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/minotaur-haspists.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Minotaur Thyreans',
    factions: ['City States'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/minotaur-thyreans.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/minotaur-thyreans.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Promethean Giant',
    factions: ['City States'],
    type: 'Regiments',
    size: '100mm',
    maxMeleeRange: 1,
    resource1: 18,
    avatar: '/Conquest/avatars/promethean.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/promethean.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Hephaestian Giant',
    factions: ['City States'],
    type: 'Regiments',
    size: '100mm',
    maxMeleeRange: 1,
    resource1: 18,
    avatar: '/Conquest/avatars/hephaestian.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/hephaestian.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Satyroi',
    factions: ['City States'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/satyroi.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/satyroi.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Selinoi',
    factions: ['City States'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/selinoi.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/selinoi.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Inquisitors',
    factions: ['City States'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 6,
    avatar: '/Conquest/avatars/inquisitors.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/inquisitors.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Skorpios',
    factions: ['City States'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 10,
    avatar: '/Conquest/avatars/skorpios.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/skorpios.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Flogobollon',
    factions: ['City States'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 10,
    avatar: '/Conquest/avatars/flogobollon.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/flogobollon.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Talos',
    factions: ['City States'],
    type: 'Regiments',
    size: '100mm',
    maxMeleeRange: 1,
    resource1: 16,
    avatar: '/Conquest/avatars/talos.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/talos.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Sacred Band',
    factions: ['City States'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/sacred-band.jpg',
    commandCard: null,
  },
  {
    name: 'Clockwork Hoplites',
    factions: ['City States'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/clockwork-hoplites.jpg',
    commandCard: null,
  },
  {
    name: 'Imperial Officer',
    factions: ['Hundred Kingdoms'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/imperial-officer.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/imperial-officer.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Noble Lord',
    factions: ['Hundred Kingdoms'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/noble-lord.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/noble-lord.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Mounted Noble Lord',
    factions: ['Hundred Kingdoms'],
    type: 'Characters',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 7,
    avatar: '/Conquest/avatars/mounted-noble-lord.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/mounted-noble-lord.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Chapter Mage',
    factions: ['Hundred Kingdoms'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/chapter-mage.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/chapter-mage.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Theist Priest',
    factions: ['Hundred Kingdoms'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/theist-priest.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/theist-priest.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Priory Commander',
    factions: ['Hundred Kingdoms'],
    type: 'Characters',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 7,
    avatar: '/Conquest/avatars/priory-commander.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/priory-commander.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Order of the Ashen Dawn',
    factions: ['Hundred Kingdoms'],
    type: 'Characters',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 6,
    avatar: '/Conquest/avatars/order-of-the-ashen-dawn.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/order-of-the-ashen-dawn.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Bodyguard',
    factions: ['Hundred Kingdoms'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/100k-default.jpg',
    commandCard: null,
  },
  {
    name: 'Duelist',
    factions: ['Hundred Kingdoms'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/100k-default.jpg',
    commandCard: null,
  },
  {
    name: 'Warrior',
    factions: ['Hundred Kingdoms'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/100k-default.jpg',
    commandCard: null,
  },
  {
    name: 'Advisor',
    factions: ['Hundred Kingdoms'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/100k-default.jpg',
    commandCard: null,
  },
  {
    name: 'Bannerman',
    factions: ['Hundred Kingdoms'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/100k-default.jpg',
    commandCard: null,
  },
  {
    name: 'Acolyte',
    factions: ['Hundred Kingdoms'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/100k-default.jpg',
    commandCard: null,
  },
  {
    name: 'Armsmaster',
    factions: ['Hundred Kingdoms'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/armsmaster.jpg',
    commandCard: null,
  },
  {
    name: 'Drillmaster',
    factions: ['Hundred Kingdoms'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/drillmaster.jpg',
    commandCard: null,
  },
  {
    name: 'Errant of the Order of the Shield',
    factions: ['Hundred Kingdoms'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/errant-of-the-order-of-the-shield.jpg',
    commandCard: null,
  },
  {
    name: 'Neophyte',
    factions: ['Hundred Kingdoms'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/neophyte.jpg',
    commandCard: null,
  },
  {
    name: 'Null Mage',
    factions: ['Hundred Kingdoms'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/null-mage.jpg',
    commandCard: null,
  },
  {
    name: 'Servite',
    factions: ['Hundred Kingdoms'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/servite.jpg',
    commandCard: null,
  },
  {
    name: 'Seasoned Veteran',
    factions: ['Hundred Kingdoms'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/seasoned-veteran.jpg',
    commandCard: null,
  },
  {
    name: 'Men-At-Arms',
    factions: ['Hundred Kingdoms'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/men-at-arms.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/men-at-arms.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Household Knights',
    factions: ['Hundred Kingdoms'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/household-knights.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/household-knights.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Household Guard',
    factions: ['Hundred Kingdoms'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/household-guard.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/household-guard.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Gilded Legion',
    factions: ['Hundred Kingdoms'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/gilded-legion.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/gilded-legion.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Steel Legion',
    factions: ['Hundred Kingdoms'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/steel-legion.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/steel-legion.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Mercenary Crossbowmen',
    factions: ['Hundred Kingdoms'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/mercenary-crossbowmen.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/mercenary-crossbowmen.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Longbowmen',
    factions: ['Hundred Kingdoms'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/longbowmen.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/longbowmen.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Militia',
    factions: ['Hundred Kingdoms'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/militia.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/militia.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Militia Bowmen',
    factions: ['Hundred Kingdoms'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/militia-bowmen.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/militia-bowmen.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Hunter Cadre',
    factions: ['Hundred Kingdoms'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/hunter-cadre.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/hunter-cadre.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Imperial Ranger Corps',
    factions: ['Hundred Kingdoms'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/imperial-ranger-corps.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/imperial-ranger-corps.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Mounted Squires',
    factions: ['Hundred Kingdoms'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/mounted-squires.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/mounted-squires.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Order of the Crimson Tower',
    factions: ['Hundred Kingdoms'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/order-of-the-crimson-tower.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/order-of-the-crimson-tower.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Order of the Sealed Temple',
    factions: ['Hundred Kingdoms'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/order-of-the-sealed-temple.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/order-of-the-sealed-temple.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Xhiliarch',
    factions: ['Old Dominion'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 6,
    avatar: '/Conquest/avatars/xhiliarch.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/xhiliarch.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Strategos',
    factions: ['Old Dominion'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/strategos.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/strategos.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Mounted Strategos',
    factions: ['Old Dominion'],
    type: 'Characters',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 7,
    avatar: '/Conquest/avatars/mounted-strategos.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/mounted-strategos.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Hierodeacon',
    factions: ['Old Dominion'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/hierodeacon.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/hierodeacon.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Archimandrite',
    factions: ['Old Dominion'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/archimandrite.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/archimandrite.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Bodyguard',
    factions: ['Old Dominion'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/dominion-default.jpg',
    commandCard: null,
  },
  {
    name: 'Duelist',
    factions: ['Old Dominion'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/dominion-default.jpg',
    commandCard: null,
  },
  {
    name: 'Warrior',
    factions: ['Old Dominion'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/dominion-default.jpg',
    commandCard: null,
  },
  {
    name: 'Advisor',
    factions: ['Old Dominion'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/dominion-default.jpg',
    commandCard: null,
  },
  {
    name: 'Labarophoros',
    factions: ['Old Dominion'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/dominion-default.jpg',
    commandCard: null,
  },
  {
    name: 'Acolyte',
    factions: ['Old Dominion'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/dominion-default.jpg',
    commandCard: null,
  },
  {
    name: 'Optio',
    factions: ['Old Dominion'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/optio.jpg',
    commandCard: null,
  },
  {
    name: 'Dark Cenotaph',
    factions: ['Old Dominion'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/dark-cenotaph.jpg',
    commandCard: null,
  },
  {
    name: 'Profane Reliquary',
    factions: ['Old Dominion'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/profane-reliquary.jpg',
    commandCard: null,
  },
  {
    name: 'Hetairos',
    factions: ['Old Dominion'],
    type: 'Officers',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/hetairos.jpg',
    commandCard: null,
  },
  {
    name: 'Legionnaires',
    factions: ['Old Dominion'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/legionnaires.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/legionnaires.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Praetorian Guard',
    factions: ['Old Dominion'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/praetorian-guard.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/praetorian-guard.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Athanatoi',
    factions: ['Old Dominion'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/athanatoi.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/athanatoi.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Varangian Guard',
    factions: ['Old Dominion'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/varangian-guard.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/varangian-guard.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Moroi',
    factions: ['Old Dominion'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/moroi.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/moroi.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Kheres',
    factions: ['Old Dominion'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/kheres.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/kheres.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Kataphraktoi',
    factions: ['Old Dominion'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/kataphraktoi.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/kataphraktoi.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Bone Golems',
    factions: ['Old Dominion'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 6,
    avatar: '/Conquest/avatars/bone-golems.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/bone-golems.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Karyatids',
    factions: ['Old Dominion'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 6,
    avatar: '/Conquest/avatars/karyatids.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/karyatids.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Kanephors',
    factions: ['Old Dominion'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 6,
    avatar: '/Conquest/avatars/kanephors.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/kanephors.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Buccephaloi',
    factions: ['Old Dominion'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 6,
    avatar: '/Conquest/avatars/buccephaloi.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/buccephaloi.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Cultists',
    factions: ['Old Dominion'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/cultists.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/cultists.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Hashashin',
    factions: ['Old Dominion'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/hashashin.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/hashashin.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Centaur Kerykes',
    factions: ['Old Dominion'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 6,
    avatar: '/Conquest/avatars/centaur-kerykes.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/centaur-kerykes.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Centaur Prodromoi',
    factions: ['Old Dominion'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/centaur-prodromoi.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/centaur-prodromoi.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Biomancer',
    factions: ['Spires'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/biomancer.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/biomancer.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Pheromancer',
    factions: ['Spires'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/pheromancer.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/pheromancer.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'High Clone Executor',
    factions: ['Spires'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/high-clone-executor.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/high-clone-executor.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Lineage Highborne',
    factions: ['Spires'],
    type: 'Characters',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 8,
    avatar: '/Conquest/avatars/lineage-highborne.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/lineage-highborne.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Lineage Prideborne',
    factions: ['Spires'],
    type: 'Characters',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 8,
    avatar: '/Conquest/avatars/lineage-prideborne.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/lineage-prideborne.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Mimetic Assassin',
    factions: ['Spires'],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/mimetic-assassin.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/mimetic-assassin.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Bodyguard',
    factions: ['Spires'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/spires-default.jpg',
    commandCard: null,
  },
  {
    name: 'Duelist',
    factions: ['Spires'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/spires-default.jpg',
    commandCard: null,
  },
  {
    name: 'Warrior',
    factions: ['Spires'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/spires-default.jpg',
    commandCard: null,
  },
  {
    name: 'Advisor',
    factions: ['Spires'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/spires-default.jpg',
    commandCard: null,
  },
  {
    name: 'Assistant',
    factions: ['Spires'],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/spires-default.jpg',
    commandCard: null,
  },
  {
    name: 'Assault Preceptor',
    factions: ['Spires'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/assault-preceptor.jpg',
    commandCard: null,
  },
  {
    name: 'Ward  Preceptor',
    factions: ['Spires'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 6,
    avatar: '/Conquest/avatars/ward-preceptor.jpg',
    commandCard: null,
  },
  {
    name: 'Catabolic Node',
    factions: ['Spires'],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/catabolic-node.jpg',
    commandCard: null,
  },
  {
    name: 'Force Grown Drones',
    factions: ['Spires'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/force-grown-drones.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/force-grown-drones.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Vanguard Clones',
    factions: ['Spires'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/vanguard-clones.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/vanguard-clones.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Vanguard Clone Infiltrators',
    factions: ['Spires'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/vanguard-clone-infiltrators.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/vanguard-clone-infiltrators.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Brute Drones',
    factions: ['Spires'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/brute-drones.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/brute-drones.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Abomination',
    factions: ['Spires'],
    type: 'Regiments',
    size: '100mm',
    maxMeleeRange: 1,
    resource1: 15,
    avatar: '/Conquest/avatars/abomination.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/abomination.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Stryx',
    factions: ['Spires'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/stryx.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/stryx.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Avatara',
    factions: ['Spires'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/avatara.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/avatara.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Centaur Avatara',
    factions: ['Spires'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/centaur-avatara.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/centaur-avatara.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Leonine Avatara',
    factions: ['Spires'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/leonine-avatara.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/leonine-avatara.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Incarnate Sentinels',
    factions: ['Spires'],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 6,
    avatar: '/Conquest/avatars/incarnate-sentinels.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/incarnate-sentinels.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Onslaught Drones',
    factions: ['Spires'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/onslaught-drones.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/onslaught-drones.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Bound Clones',
    factions: ['Spires'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/bound-clones.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/bound-clones.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Marksman Clones',
    factions: ['Spires'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/marksman-clones.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/marksman-clones.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Siegebreaker Behemoth',
    factions: ['Spires'],
    type: 'Regiments',
    size: '100mm',
    maxMeleeRange: 1,
    resource1: 18,
    avatar: '/Conquest/avatars/siegebreaker-behemoth.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/siegebreaker-behemoth.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Desolation Drones',
    factions: ['Spires'],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/desolation-drones.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/desolation-drones.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Matriarch Queen',
    factions: ["W'adrhŭn"],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/matriarch-queen.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/matriarch-queen.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Apex Queen',
    factions: ["W'adrhŭn"],
    type: 'Characters',
    size: '100mm',
    maxMeleeRange: 1,
    resource1: 23,
    avatar: '/Conquest/avatars/apex-queen.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/apex-queen.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Predator',
    factions: ["W'adrhŭn"],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/predator.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/predator.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Mounted Predator',
    factions: ["W'adrhŭn"],
    type: 'Characters',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 7,
    avatar: '/Conquest/avatars/wadrhun-default.jpg',
    commandCard: null,
  },
  {
    name: 'Scion of Conquest',
    factions: ["W'adrhŭn"],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/scion-of-conquest.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/scion-of-conquest.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Chieftain',
    factions: ["W'adrhŭn"],
    type: 'Characters',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/chieftain.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/chieftain.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Thunder Chieftain',
    factions: ["W'adrhŭn"],
    type: 'Characters',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 7,
    avatar: '/Conquest/avatars/thunder-chieftain.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/thunder-chieftain.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Bodyguard',
    factions: ["W'adrhŭn"],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/wadrhun-default.jpg',
    commandCard: null,
  },
  {
    name: 'Duelist',
    factions: ["W'adrhŭn"],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/wadrhun-default.jpg',
    commandCard: null,
  },
  {
    name: 'Warrior',
    factions: ["W'adrhŭn"],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/wadrhun-default.jpg',
    commandCard: null,
  },
  {
    name: 'Tactician',
    factions: ["W'adrhŭn"],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/wadrhun-default.jpg',
    commandCard: null,
  },
  {
    name: 'Totem Bearer',
    factions: ["W'adrhŭn"],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/wadrhun-default.jpg',
    commandCard: null,
  },
  {
    name: 'Acolyte',
    factions: ["W'adrhŭn"],
    type: 'Retinues',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/wadrhun-default.jpg',
    commandCard: null,
  },
  {
    name: 'Aberration',
    factions: ["W'adrhŭn"],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/aberration.jpg',
    commandCard: null,
  },
  {
    name: 'Champion',
    factions: ["W'adrhŭn"],
    type: 'Officers',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/wadrhun-default.jpg',
    commandCard: null,
  },
  {
    name: 'Braves',
    factions: ["W'adrhŭn"],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/braves.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/braves.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Blooded',
    factions: ["W'adrhŭn"],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/blooded-wadrhun.jpg',
    commandCard: null,
  },
  {
    name: 'Hunters',
    factions: ["W'adrhŭn"],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/hunters.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/hunters.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Slingers',
    factions: ["W'adrhŭn"],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/slingers.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/slingers.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Warbred',
    factions: ["W'adrhŭn"],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 7,
    avatar: '/Conquest/avatars/warbred.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/warbred.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Hunting Pack',
    factions: ["W'adrhŭn"],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 3,
    avatar: '/Conquest/avatars/hunting-pack.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/hunting-pack.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Raptor Riders',
    factions: ["W'adrhŭn"],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 4,
    avatar: '/Conquest/avatars/raptor-riders.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/raptor-riders.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Apex Predator',
    factions: ["W'adrhŭn"],
    type: 'Regiments',
    size: '100mm',
    maxMeleeRange: 1,
    resource1: 18,
    avatar: '/Conquest/avatars/apex-predator.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/apex-predator.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Veterans',
    factions: ["W'adrhŭn"],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/veterans.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/veterans.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Chosen of Conquest',
    factions: ["W'adrhŭn"],
    type: 'Regiments',
    size: '27mm',
    maxMeleeRange: 1,
    resource1: 2,
    avatar: '/Conquest/avatars/chosen-of-conquest.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/chosen-of-conquest.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
  {
    name: 'Thunder Riders',
    factions: ["W'adrhŭn"],
    type: 'Regiments',
    size: '54mm',
    maxMeleeRange: 1,
    resource1: 5,
    avatar: '/Conquest/avatars/thunder-riders.jpg',
    commandCard: {
      frontImageUrl: '/Conquest/cards/thunder-riders.jpg',
      backImageUrl: '/Conquest/cards/card-back.jpg',
    },
  },
];
