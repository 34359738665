function rollDice(num, die) {
  let rolls = [];
  for (var i = 0; i < num; i++) {
    rolls.push(Math.floor(Math.random() * die) + 1);
  }
  return rolls;
}

function roll(num, die, log) {
  const rolls = rollDice(num, die);
  const total = rolls.reduce((total, num) => {
    return total + num;
  }, 0);
  log(`${num}d${die}: ${rolls.join(' + ')} = ${total}`);
}

export default function ({ log }) {
  const results = [];
  results.push({
    label: `Tough Check`,
    avatar: '/emoji/game_die.png',
    run() {
      const roll = rollDice(1, 6)[0];
      log(`${roll}: ${roll >= 5 ? 'Tough' : 'No Tough'}`, 'Tough Check');
    },
  });
  results.push({
    label: `4+ Tough Check`,
    avatar: '/emoji/game_die.png',
    run() {
      const roll = rollDice(1, 6)[0];
      log(`${roll}: ${roll >= 4 ? 'Tough' : 'No Tough'}`, '4+ Tough Check');
    },
  });
  [1, 2, 3, 4, 5, 6, 7].forEach((i) =>
    results.push({
      label: `Roll ${i}d6`,
      avatar: '/emoji/game_die.png',
      run: () => roll(i, 6, log),
    })
  );
  [1, 2, 3, 4, 5].forEach((i) =>
    results.push({
      label: `Roll ${i}d3`,
      avatar: '/emoji/game_die.png',
      run: () => roll(i, 3, log),
    })
  );
  return results;
}
