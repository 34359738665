import { createTheme } from '@mui/material/styles';
import blueGrey from '@mui/material/colors/blueGrey';
import orange from '@mui/material/colors/orange';

export const palette = {
  primary: {
    light: '#62727b',
    main: blueGrey[800],
    dark: '#102027',
    contrastText: '#fff',
  },
  secondary: {
    light: '#ffad42',
    main: orange[700],
    dark: '#bb4d00',
    contrastText: '#fff',
  },
};

// https://material.io/tools/color/#!/?view.left=0&view.right=0&primary.color=37474F&secondary.color=F57C00&primary.text.color=ffffff&secondary.text.color=000000
export default createTheme({
  palette,
  typography: {
    useNextVariants: true,
  },
  zIndex: {
    snackbar: 1290, // default=1400, modal=1300. We want the snackbar to be under the modal
  },
});
