import setup from './setup';
import gameActions from './gameActions';
import AddModelDialog from './AddModelDialog';
import Deck from './Deck';
import deckActions from './deckActions';
import modals from './modals';
import Terrain from './Terrain';

export default {
  gameActions,
  deckActions,
  modals,
  setup,
  AddModelDialog,
  Deck,
  Terrain,
};
