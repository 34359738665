let timeOffset = 0;

export default function currentTime(game) {
  let now = new Date().getTime();
  if (
    game.attrs.clockSwitchedAt &&
    game.attrs.clockSwitchedAt > now + timeOffset
  ) {
    // our clock is behind the other player
    timeOffset = game.attrs.clockSwitchedAt - new Date().getTime();
  }
  return now + +timeOffset;
}
