import { COLORS } from '../ColorPicker';

export function toggleAura({ canvas, selection }) {
  const results = [];
  if (selection && selection.type === 'token') {
    results.push({
      label: `Toggle Aura`,
      iconText: 'T',
      color: '#2196f3',
      run() {
        selection.update({
          auraRadius: -1 * (selection.attrs.auraRadius || 0),
        });
      },
    });
  }
  results.push({
    label: `Toggle All Auras`,
    iconText: 'T',
    color: '#f44336',
    run() {
      const tokens = canvas
        .getObjects()
        .filter((o) => o.record && o.record.type === 'token')
        .map((o) => o.record);
      if (tokens.find((t) => t.attrs.auraRadius && t.attrs.auraRadius > 0)) {
        tokens.forEach((t) => {
          if (t.attrs.auraRadius && t.attrs.auraRadius > 0) {
            t.update({ auraRadius: -1 * t.attrs.auraRadius });
          }
        });
      } else {
        tokens.forEach((t) => {
          if (t.attrs.auraRadius && t.attrs.auraRadius < 0) {
            t.update({ auraRadius: -1 * t.attrs.auraRadius });
          }
        });
      }
    },
  });
  return results;
}

export function auraRadius({ selection }) {
  if (!selection || selection.type !== 'token') return [];
  const results = [];
  [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ].forEach((i) => {
    results.push({
      label: `Set Aura ${i}”`,
      iconText: `${i}`,
      color: '#2196f3',
      run() {
        selection.update({ auraRadius: i });
      },
    });
  });
  return results;
}

export function auraColor({ selection }) {
  if (!selection || selection.type !== 'token') return [];
  const colors = Object.entries(COLORS);
  colors.push(['transparent', 'Transparent']);
  return colors.map(([color, label]) => ({
    label: `Set Aura ${label}`,
    iconText: `${label.split('')[0]}`,
    color: color === 'transparent' ? '#000000' : color,
    run() {
      selection.update({ auraColor: color });
    },
  }));
}

export function meleeRange({ selection }) {
  if (!selection || selection.type !== 'token') return [];
  return [
    0, 0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
    20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
  ].map((i) => ({
    label: `Set Melee Range ${i}”`,
    iconText: `${i}`,
    color: '#f44336',
    run() {
      selection.update({ maxMeleeRange: i });
    },
  }));
}
