import React, { Component } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth';

import { auth } from '../initFirebase';
import Dialog from '../WTDialog';

class SignInDialog extends Component {
  state = {
    email: '',
    password: '',
    emailError: '',
    passwordError: '',
    forgotPassword: false,
  };
  login() {
    this.setState({ emailError: '', passwordError: '' });
    signInWithEmailAndPassword(
      auth,
      this.state.email,
      this.state.password
    ).catch((error) => {
      if (error.code === 'auth/wrong-password') {
        this.setState({ passwordError: error.message });
      } else {
        this.setState({ emailError: error.message });
      }
    });
  }
  resetPassword() {
    sendPasswordResetEmail(auth, this.state.email)
      .then(() => {
        alert('Please check your email for your password reset link');
        this.props.onClose();
      })
      .catch((error) => {
        this.setState({ emailError: error.message });
      });
  }
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {this.state.forgotPassword ? 'Forgot Password' : 'Sign In'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography gutterBottom>
              {this.state.forgotPassword
                ? 'Please enter your email address.'
                : 'Please enter your email address and password.'}
            </Typography>

            {!this.state.forgotPassword && (
              <Typography gutterBottom>
                Don't have an account?
                <Button onClick={this.props.onSignUp}>Sign Up Now</Button>
              </Typography>
            )}
          </DialogContentText>
          <TextField
            autoFocus
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            margin="normal"
            value={this.state.email}
            onChange={({ target }) => {
              this.setState({ email: target.value });
            }}
            error={this.state.emailError.length > 0}
            helperText={this.state.emailError}
          />
          {!this.state.forgotPassword && (
            <TextField
              id="password"
              label="Password"
              type="password"
              fullWidth
              margin="normal"
              value={this.state.password}
              onChange={({ target }) => {
                this.setState({ password: target.value });
              }}
              error={this.state.passwordError.length > 0}
              helperText={this.state.passwordError}
            />
          )}
        </DialogContent>
        <DialogActions>
          {this.state.forgotPassword ? (
            <React.Fragment>
              <Button
                onClick={() => {
                  this.setState({ forgotPassword: false });
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={this.resetPassword.bind(this)}
                color="primary"
                variant="contained"
              >
                Reset Password
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button onClick={this.props.onClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ forgotPassword: true });
                }}
              >
                Forgot Password?
              </Button>
              <Button
                onClick={this.login.bind(this)}
                color="primary"
                variant="contained"
              >
                Sign In
              </Button>
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default SignInDialog;
