import React, { Component, Fragment } from 'react';

import Fab from '@mui/material/Fab';
import { drawerWidth } from './SelectionDrawer';
import { CheckCircle } from '@mui/icons-material';

class ConfirmAllButton extends Component {
  render() {
    const { show, onClick } = this.props;

    return (
      show && (
        <Fab
          color="primary"
          style={{
            position: 'fixed',
            margin: 8 * 2,
            right: drawerWidth,
            bottom: 65,
          }}
          onClick={onClick}
        >
          <CheckCircle style={{ color: '#85e085' }} />
        </Fab>
      )
    );
  }
}

export default ConfirmAllButton;
