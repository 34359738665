export default function ({ num, log, rollDice, shift }) {
  const dice = num === 0 ? 10 : num;
  const rolls = rollDice(dice, 6);
  const total = rolls.reduce((total, roll) => {
    return total + roll;
  }, 0);

  let text = `${dice}d6: ${rolls.join(' + ')} = ${total}`;
  if (shift) {
    const column = rollDice(1, 6);
    text += ` to the ${column[0]}`;
    log(text, 'Damage');
  } else {
    log(text);
  }
}
