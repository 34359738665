import React, { Component, Fragment } from 'react';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import formatMillis from './formatMillis';
import timeElapsed from './timeElapsed';
import toggleActivePlayer from './toggleActivePlayer';
import togglePause from './togglePause';
import Dialog from '../../WTDialog';
import TimeDisplay from './TimeDisplay';

export default class ClockDialog extends Component {
  state = {
    adjustClock1: false,
    adjustClock2: false,
  };
  addSeconds(player, seconds) {
    const { game, log } = this.props;
    let remaining = game.attrs[`clockPlayer${player}TimeRemaining`] || 0;
    const elapsed = timeElapsed(player, game);
    if (remaining - elapsed < 0) remaining = elapsed;
    remaining = Math.max(remaining + 1000 * seconds, 0);
    game.update({
      [`clockPlayer${player}TimeRemaining`]: remaining,
      [`clockPlayer${player}Expired`]: false,
    });
    if (seconds > 0) {
      log(
        `Added ${formatMillis(seconds * 1000)} to ${game.attrs[`player${player}Label`]} clock`
      );
    } else {
      log(
        `Removed ${formatMillis(-1 * seconds * 1000)} from ${game.attrs[`player${player}Label`]} clock`
      );
    }
  }
  playerClock = (player) => {
    const { game, log } = this.props;
    return (
      <Grid item key={player} xs={12} sm={6}>
        <Typography variant="h6" gutterBottom>
          {game.attrs[`player${player}Label`]}
        </Typography>
        <Typography variant="h2" gutterBottom>
          <TimeDisplay
            clockActivePlayer={game.attrs.clockActivePlayer}
            game={game}
            player={player}
            log={log}
          />
        </Typography>
        <Button
          style={{ marginRight: 16, marginBottom: 16 }}
          onClick={() =>
            this.setState({
              [`adjustClock${player}`]: !this.state[`adjustClock${player}`],
            })
          }
        >
          Adjust Clock
        </Button>
        {this.state[`adjustClock${player}`] && (
          <div>
            <Button
              style={{ marginRight: 16, marginBottom: 16 }}
              onClick={() => this.addSeconds(player, -1)}
            >
              -1S
            </Button>
            <Button
              style={{ marginRight: 16, marginBottom: 16 }}
              onClick={() => this.addSeconds(player, -10)}
            >
              -10S
            </Button>
            <Button
              style={{ marginRight: 16, marginBottom: 16 }}
              onClick={() => this.addSeconds(player, -60)}
            >
              -1M
            </Button>
            <Button
              style={{ marginRight: 16, marginBottom: 16 }}
              onClick={() => this.addSeconds(player, -600)}
            >
              -10M
            </Button>
            <Button
              style={{ marginRight: 16, marginBottom: 16 }}
              onClick={() => this.addSeconds(player, -3600)}
            >
              -1H
            </Button>
            <Button
              style={{ marginRight: 16, marginBottom: 16 }}
              onClick={() => this.addSeconds(player, 1)}
            >
              +1S
            </Button>
            <Button
              style={{ marginRight: 16, marginBottom: 16 }}
              onClick={() => this.addSeconds(player, 10)}
            >
              +10S
            </Button>
            <Button
              style={{ marginRight: 16, marginBottom: 16 }}
              onClick={() => this.addSeconds(player, 60)}
            >
              +1M
            </Button>
            <Button
              style={{ marginRight: 16, marginBottom: 16 }}
              onClick={() => this.addSeconds(player, 600)}
            >
              +10M
            </Button>
            <Button
              style={{ marginRight: 16, marginBottom: 16 }}
              onClick={() => this.addSeconds(player, 3600)}
            >
              +1H
            </Button>
          </div>
        )}
      </Grid>
    );
  };
  reset = () => {
    const { game } = this.props;
    const minutes = window.prompt('How many minutes should each player have?');
    if (minutes && parseInt(minutes, 10)) {
      this.props.log(`Reset clocks to ${minutes} minutes`);
      const millis = parseInt(minutes, 10) * 60 * 1000;
      game.update({
        clockPaused: true,
        clockPlayer1TimeRemaining: millis,
        clockPlayer2TimeRemaining: millis,
        clockActivePlayer: 1,
        clockSwitchedAt: new Date().getTime(),
      });
    }
  };
  toggleClockEnabled = (clockEnabled) => {
    const { game } = this.props;
    let attrs = { clockEnabled };
    if (!game.attrs.clockActivePlayer) {
      attrs = {
        clockEnabled,
        clockPaused: true,
        clockPlayer1Expired: false,
        clockPlayer2Expired: false,
        clockPlayer1TimeRemaining: 60 * 60 * 1000,
        clockPlayer2TimeRemaining: 60 * 60 * 1000,
        clockActivePlayer: 1,
        clockSwitchedAt: new Date().getTime(),
      };
    }
    game.update(attrs);
  };
  render() {
    const { game, log, onClose, open } = this.props;
    if (!open) return null;
    const { clockEnabled } = game.attrs;
    return (
      <Dialog open onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Clock</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clockEnabled}
                    onChange={(e) => {
                      this.toggleClockEnabled(e.target.checked);
                    }}
                    name="clockEnabled"
                    color="primary"
                  />
                }
                label="Use clock?"
              />
            </Grid>
            {clockEnabled && (
              <Fragment>{[1, 2].map(this.playerClock)}</Fragment>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => togglePause(game, log)}>
            {game.attrs.clockPaused ? 'Unpause' : 'Pause'}
          </Button>
          <Button onClick={() => toggleActivePlayer(game, log)}>
            Switch Player
          </Button>
          <Button onClick={this.reset}>Reset</Button>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}
