export default [
  { label: 'Kick Scatter', type: 'KickScatter' },
  { label: 'Standard Scatter', type: 'StandardScatter' },
  {
    label: 'Burning Template',
    type: 'Image',
    attrs: {
      label: 'Burning Template',
      imageUrl: '/GuildBall/widgets/burning-template.png',
      width: 3,
      height: 3,
    },
  },
  {
    label: 'Cover Template',
    type: 'Image',
    attrs: {
      label: 'Cover Template',
      imageUrl: '/GuildBall/widgets/cover-template.png',
      width: 3,
      height: 3,
    },
  },
  {
    label: 'Poison Template',
    type: 'Image',
    attrs: {
      label: 'Poison Template',
      imageUrl: '/GuildBall/widgets/poison-template.png',
      width: 3,
      height: 3,
    },
  },
  {
    label: 'Rough Ground Template',
    type: 'Image',
    attrs: {
      label: 'Rough Ground Template',
      imageUrl: '/GuildBall/widgets/rough-ground-template.png',
      width: 3,
      height: 3,
    },
  },
  {
    label: 'Harrier',
    type: 'RingTemplate3',
    attrs: { label: 'Harrier Template', fill: '#3B6743' },
  },
];
