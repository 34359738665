import React, { Component } from 'react';

import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const COLORS = {
  '#f44336': 'Red',
  '#e91e63': 'Pink',
  '#9c27b0': 'Purple',
  '#673ab7': 'Deep Purple',
  '#3f51b5': 'Indigo',
  '#2196f3': 'Blue',
  '#03a9f4': 'Light Blue',
  '#00bcd4': 'Cyan',
  '#009688': 'Teal',
  '#4caf50': 'Green',
  '#fbc02d': 'Yellow',
  '#ff5722': 'Deep Orange',
  '#795548': 'Brown',
  '#9e9e9e': 'Grey',
  '#607d8b': 'Blue Grey',
};

export function randomColor() {
  const colors = Object.keys(COLORS);
  return colors[Math.floor(Math.random() * colors.length)];
}

export default class ColorPicker extends Component {
  render() {
    return (
      <React.Fragment>
        <InputLabel
          htmlFor="color-picker"
          style={{ marginTop: 2, marginBottom: 2, display: 'block' }}
          variant="standard"
        >
          {this.props.label}
        </InputLabel>
        <Select
          value={this.props.value}
          renderValue={(value) => {
            return COLORS[value];
          }}
          onChange={this.props.onChange}
          variant="standard"
          style={{
            display: 'block',
          }}
          inputProps={{
            name: this.props.name,
            id: 'color-picker',
          }}
        >
          {this.props.includeTransparent && (
            <MenuItem value="transparent">
              <ListItemIcon style={{ color: 'transparent' }}>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary="Transparent" />
            </MenuItem>
          )}
          {this.props.includeWhite && (
            <MenuItem value="#ffffff">
              <ListItemIcon style={{ color: '#ffffff' }}>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary="White" />
            </MenuItem>
          )}
          {Object.keys(COLORS).map((color) => {
            return (
              <MenuItem key={color} value={color}>
                <ListItemIcon style={{ color }}>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText primary={COLORS[color]} />
              </MenuItem>
            );
          })}
        </Select>
      </React.Fragment>
    );
  }
}
