export default function ({ num, log, rollDice, shift }) {
  const dice = shift ? num + 10 : num;
  const roll = rollDice(num, 6);
  let text = `${num} ${num === 1 ? 'die' : 'dice'}`;
  const target = window.prompt('Target number? (cancel for none)');
  const targetNum = target && parseInt(target);
  if (targetNum > 0 && targetNum < 7) {
    text += ` TN=${targetNum}`;
  }
  text += `: ${roll.join(',')}`;
  if (targetNum > 0 && targetNum < 7) {
    const successes = roll.filter((roll) => roll >= targetNum).length;
    text += ` = ${successes} ${successes === 1 ? 'Success' : 'Successes'}`;
  }
  log(text);
}
