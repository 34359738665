import React, { Component } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import GameState from './GameState';
import Dialog from '../WTDialog';

export default class LogDialog extends Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Game Notes</DialogTitle>
        <DialogContent>
          <GameState game={this.props.game} />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}
