export default [
  {
    label: 'Feat Token',
    type: 'Token',
    attrs: { label: 'Feat', fill: '#f44336', width: 1.9685 },
  },
  {
    label: 'Defense: Barrier',
    type: 'WallTemplate',
    attrs: { label: 'Barrier', fill: '#9e9e9e', height: 0.5, width: 4 },
  },
  {
    label: 'Defense: Powder Keg',
    type: 'RingTemplate',
    attrs: { width: 0.787402, label: 'Powder Keg', fill: '#795548' },
  },
  {
    label: 'Defense: Fire Pit',
    type: 'RingTemplate',
    attrs: { width: 2, label: 'Fire Pit', fill: '#f44336' },
  },
  {
    label: 'Defense: Spike Trap',
    type: 'WallTemplate',
    attrs: { label: 'Spike Trap', fill: '#9e9e9e', height: 2, width: 2 },
  },
  {
    label: 'Wall',
    type: 'WallTemplate',
    attrs: { label: 'Wall', fill: '#9e9e9e', height: 0.75, width: 4 },
  },
];
