import BaseWidget from './BaseWidget';
import { fabric } from 'fabric-with-gestures';
import adjustFontSize from '../../adjustFontSize';

export default class WallTemplate extends BaseWidget {
  objects() {
    const strokeWidth = this.inchesToPixels(0.05);
    this.rect = new fabric.Rect({
      left: 0,
      top: 0,
      width:
        this.inchesToPixels(this.props.record.attrs.width || 4) -
        strokeWidth / 2,
      height:
        this.inchesToPixels(this.props.record.attrs.height || 0.75) -
        strokeWidth / 2,
      fill: this.fillColor(),
      stroke: '#fff',
      strokeWidth,
      originX: 'center',
      originY: 'center',
    });
    this.label = new fabric.Text(this.labelText(), {
      stroke: '#fff',
      fill: '#fff',
      originX: 'center',
      originY: 'center',
      textAlign: 'center',
      left: 0,
      top: 0,
    });
    this.updateLabelSize();
    return [this.rect, this.label];
  }
  labelText() {
    return this.props.record.attrs.label || '';
  }
  fillColor() {
    return this.props.record.attrs.fill || '#9e9e9e';
  }
  updateLabelSize() {
    adjustFontSize(this.label, {
      height: this.inchesToPixels(0.6),
      width: this.inchesToPixels(3),
    });
  }
  updateFabricObject() {
    this.label.set({ text: this.labelText() });
    this.rect.set({ fill: this.fillColor() });
    this.updateLabelSize();
    super.updateFabricObject();
  }
  canRotate() {
    return true;
  }
}
