import React, { Component, Fragment } from 'react';
import ReactGA from 'react-ga4';

import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';

export default class extends Component {
  roll = (num, die, label) => {
    ReactGA.event({
      category: 'Roll',
      action: `${num}d${die}:${label}`,
    });
    let text;
    die = parseInt(die, 10);
    let rolls = this.props.rollDice(num, die);
    text = `${num}d${die}: ${rolls.join(' + ')}`;
    if (rolls.length > 1) {
      let total = rolls.reduce((total, num) => {
        return total + num;
      }, 0);
      text = `${text} = ${total}`;
    }
    this.props.addLog(text, label);
    this.close();
  };
  rollDeviation = () => {
    const distance = this.props.rollDice(1, 6);
    const direction = this.props.rollDice(1, 6);

    this.props.addLog(`${distance[0]}” to the ${direction[0]}`, 'Deviation');
    this.close();
  };
  rollTough = (min, label) => {
    const roll = this.props.rollDice(1, 6);

    this.props.addLog(`${roll}: ${roll >= min ? 'Tough' : 'No Tough'}`, label);
    this.close();
  };
  rollDamage = (num, includeColumn) => {
    const rolls = this.props.rollDice(num, 6);
    const total = rolls.reduce((total, num) => {
      return total + num;
    }, 0);
    let text = `${num}d6: ${rolls.join(' + ')} = ${total}`;
    if (includeColumn) {
      const column = this.props.rollDice(1, 6);
      text += ` to the ${column[0]}`;
    }
    this.props.addLog(text, 'Damage');
    this.close();
  };
  close() {
    this.props.onClose();
  }
  render() {
    return (
      <Fragment>
        <Grid item xs={12}>
          <FormLabel component="legend">Roll d3</FormLabel>
        </Grid>
        {[1, 2, 3, 4, 5, 6].map((num) => (
          <Grid key={num} item xs={2}>
            <Button onClick={() => this.roll(num, 3)}>{num}d3</Button>
          </Grid>
        ))}
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <FormLabel component="legend">Roll d6</FormLabel>
        </Grid>
        {[1, 2, 3, 4, 5, 6].map((num) => (
          <Grid key={num} item xs={2}>
            <Button onClick={() => this.roll(num, 6)}>{num}d6</Button>
          </Grid>
        ))}
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <FormLabel component="legend">Attack</FormLabel>
        </Grid>
        {[1, 2, 3, 4, 5, 6].map((num) => (
          <Grid key={num} item xs={2}>
            <Button onClick={() => this.roll(num, 6, 'Attack')}>{num}d6</Button>
          </Grid>
        ))}
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <FormLabel component="legend">Damage</FormLabel>
        </Grid>
        {[1, 2, 3, 4, 5, 6].map((num) => (
          <Grid key={num} item xs={2}>
            <Button
              onClick={() => {
                this.rollDamage(num);
              }}
            >
              {num}d6
            </Button>
          </Grid>
        ))}
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <FormLabel component="legend">Damage + Column</FormLabel>
        </Grid>
        {[1, 2, 3, 4, 5, 6].map((num) => (
          <Grid key={num} item xs={2}>
            <Button
              onClick={() => {
                this.rollDamage(num, true);
              }}
            >
              {num}d6
            </Button>
          </Grid>
        ))}
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <FormLabel component="legend">Other Rolls</FormLabel>
        </Grid>
        <Grid item xs={3}>
          <Button onClick={this.rollDeviation}>Deviation</Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            onClick={() => {
              this.rollTough(5, 'Tough Check');
            }}
          >
            Tough Check
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={() => {
              this.rollTough(4, '4+ Tough Check');
            }}
          >
            4+ Tough Check
          </Button>
        </Grid>
      </Fragment>
    );
  }
}
