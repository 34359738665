import { fabric } from 'fabric-with-gestures';
import BaseShape from './BaseShape';

export default class Circle extends BaseShape {
  createFabricObject() {
    return new fabric.Circle({
      strokeWidth: 1,
      fill: '',
      originX: 'center',
      originY: 'center',
      hasControls: false,
      layer: this.layer(),
    });
  }
  shapeAttrs(attrs, pixelsPerInch) {
    let radius = (pixelsPerInch * attrs.width) / 2;
    if (radius < 2) {
      radius = 2;
    }
    return {
      left: attrs.x * pixelsPerInch,
      top: attrs.y * pixelsPerInch,
      radius: radius - 1,
      stroke: attrs.stroke,
      fill: attrs.fill,
    };
  }
}
