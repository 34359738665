export default [
  {
    title: 'Regiment Options',
    values: [
      {
        text: 'Leader',
        color: '#000000a2',
        icon: '/emoji/star.png',
      },
      {
        text: 'Standard Bearer',
        color: '#000000a2',
        icon: '/emoji/triangular_flag_on_post.png',
      },
    ],
  },
];
