import React, { Component } from 'react';

export const withTokens = (WrappedComponent) => {
  const HOC = class extends Component {
    state = {
      tokens: {},
    };
    unregisterCallbacks = [];
    componentDidMount() {
      let connection = this.props.connection;
      this.unregisterCallbacks.push(
        connection.onAdd('token', this.add.bind(this))
      );
      this.unregisterCallbacks.push(
        connection.onChange('token', this.add.bind(this))
      );
      this.unregisterCallbacks.push(
        connection.onRemove('token', this.remove.bind(this))
      );
    }
    componentWillUnmount() {
      this.unregisterCallbacks.forEach((callback) => {
        callback();
      });
    }
    add(token) {
      this.setState((previousState, currentProps) => {
        const tokens = Object.assign({}, previousState.tokens);
        tokens[token.path] = token;
        return { ...previousState, tokens };
      });
    }
    remove(path) {
      this.setState((previousState, currentProps) => {
        const tokens = Object.assign({}, previousState.tokens);
        delete tokens[path];
        return { ...previousState, tokens };
      });
    }
    render() {
      const { tokens } = this.state;
      return (
        <React.Fragment>
          <WrappedComponent tokens={Object.values(tokens)} {...this.props} />
        </React.Fragment>
      );
    }
  };

  return HOC;
};

export default withTokens;
