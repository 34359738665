export default [
  {
    title: 'Conditions',
    values: [
      { color: '#ffffff', text: 'Bleed [3]', icon: '/emoji/drop_of_blood.png' },
      { color: '#ffffff', text: 'Burning [1]', icon: '/emoji/fire.png' },
      { color: '#ffffff', text: 'Diseased', icon: '/emoji/microbe.png' },
      {
        color: '#ffffff',
        text: 'Knocked Down [0]',
        icon: '/emoji/arrow_down.png',
      },
      { color: '#ffffff', text: 'Poison [2]', icon: '/emoji/test_tube.png' },
      { color: '#ffffff', text: 'Snared [0]', icon: '/emoji/spider_web.png' },
      { color: '#00bcd4', text: 'Taken Out', icon: '/emoji/skull.png' },
    ],
  },
];
