import React, { Component } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Dialog from '../WTDialog';

export default class InviteDialog extends Component {
  state = {
    helperText: 'Copy this link and send to the other player',
  };
  onCopy = () => {
    document.getElementById('invite-url').select();
    document.execCommand('copy');
    this.setState({ helperText: 'Copied!' });
  };
  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open) {
      this.setState({
        helperText: 'Copy this link and send to the other player',
      });
    }
  }
  render() {
    const { game, onClose, open } = this.props;
    let inviteUrl = `${window.location.protocol}//${window.location.hostname}/invite/${game.id}/${game.attrs.inviteCode}`;
    return (
      <Dialog open={open} maxWidth="xs" onClose={onClose}>
        <DialogTitle>Invite</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                readOnly
                fullWidth
                value={inviteUrl}
                helperText={this.state.helperText}
                id="invite-url"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Player names</Typography>
              <Typography variant="body2" color="textSecondary">
                Used to help identify your past games in "Continue Game"
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Player 1"
                value={game.attrs.player1Label}
                onChange={(e) => {
                  game.update({ player1Label: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Player 2"
                value={game.attrs.player2Label}
                onChange={(e) => {
                  game.update({ player2Label: e.target.value });
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Close</Button>
          <Button variant="contained" color="primary" onClick={this.onCopy}>
            Copy Link
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
