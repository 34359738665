import { Component } from 'react';

export default class Records extends Component {
  state = {
    records: {},
  };
  unregisterCallbacks = [];
  componentDidMount() {
    let type = this.props.type;
    let connection = this.props.connection;
    this.unregisterCallbacks.push(connection.onAdd(type, this.add.bind(this)));
    this.unregisterCallbacks.push(
      connection.onChange(type, this.add.bind(this))
    );
    this.unregisterCallbacks.push(
      connection.onRemove(type, this.remove.bind(this))
    );
  }
  componentWillUnmount() {
    this.unregisterCallbacks.forEach((callback) => {
      callback();
    });
  }
  add(record) {
    this.setState((previousState, currentProps) => {
      const records = Object.assign({}, previousState.records);
      records[record.path] = record;
      return { ...previousState, records };
    });
  }
  remove(path) {
    this.setState((previousState, currentProps) => {
      const records = Object.assign({}, previousState.records);
      delete records[path];
      return { ...previousState, records };
    });
  }
  render() {
    let records = Object.values(this.state.records);
    if (this.props.reverse) {
      records = records.reverse();
    }
    if (this.props.sortBy) {
      records = records.sort((a, b) => {
        let valueA = this.props.sortBy(a);
        let valueB = this.props.sortBy(b);
        return valueA > valueB ? -1 : 1;
      });
    }
    if (this.props.limit) {
      records = records.slice(0, this.props.limit);
    }
    return records.map((record, i) => {
      return this.props.children(record, i === records.length - 1);
    });
  }
}
