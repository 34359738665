import { sizeFor } from '../map/Token';

export default function modelToToken(model) {
  const { width, height, shape } = sizeFor(model.size);
  const tokenAttrs = {
    label: model.alias || model.name,
    deletable: true,
    width,
    height,
    shape,
  };
  if (model.avatar) {
    tokenAttrs.imageUrl = model.avatar;
  }
  if (model.resource1) {
    tokenAttrs.resource1 = model.resource1;
    tokenAttrs.resource1Max = model.resource1;
  }
  if (model.resource2 !== undefined) {
    tokenAttrs.resource2 = model.resource2;
    tokenAttrs.resource2Max = model.resource2Max;
  }
  if (model.resource3 !== undefined) {
    tokenAttrs.resource3 = model.resource3;
    tokenAttrs.resource3Max = model.resource3Max;
  }
  if (model.cards) {
    tokenAttrs.cards = model.cards;
  }
  tokenAttrs.maxMeleeRange = model.maxMeleeRange || 0;
  return tokenAttrs;
}
