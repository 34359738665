import React from 'react';

import CategoryIcon from '@mui/icons-material/Category';

import WIDGETS from '../Widgets';
import selectNewRecord from './selectNewRecord';

export function addWidget(
  widgetType,
  widgetAttrs,
  { canvas, connection, game, selection }
) {
  let attrs = {
    widgetType,
    x: game.attrs.width / 2,
    y: game.attrs.height / 2,
    facing: 0,
    deletable: true,
    ...widgetAttrs,
  };
  if (selection && (!widgetAttrs || !widgetAttrs.x)) {
    attrs.x = selection.attrs.x;
    attrs.y = selection.attrs.y;
    if (attrs.y < game.attrs.height / 2 && widgetType.includes('Ruler')) {
      attrs.facing = 180;
    }
    if (widgetType === 'Token' && selection.attrs.width) {
      attrs.x = attrs.x + selection.attrs.width / 2 + widgetAttrs.width / 2;
    }
  }
  if (selection && selection.attrs.width && attrs.modelOffset) {
    attrs.rotateRadius = selection.attrs.width / 2;
  }
  selectNewRecord(canvas);
  connection.add('widget', attrs);
}

export function addWidgets({
  canvas,
  connection,
  game,
  moduleData,
  selection,
  system,
}) {
  const widgets = [...(moduleData.widgets || []), ...WIDGETS];
  return widgets.map((widget) => ({
    label: `Add "${widget.label}"`,
    icon: <CategoryIcon />,
    run() {
      addWidget(widget.type, widget.attrs, {
        canvas,
        connection,
        game,
        selection,
      });
    },
  }));
}

export function customToken({ canvas, connection, game, selection, text }) {
  const tokenName = text.replace(/token/i, '').trim();
  if (tokenName.length === 0) return [];
  return [
    {
      label: `Add Small "${tokenName}" Token`,
      icon: <CategoryIcon />,
      run() {
        addWidget(
          'Token',
          { label: tokenName, fill: '#9e9e9e', width: 0.984252 },
          { canvas, connection, game, selection }
        );
      },
    },
    {
      label: `Add Large "${tokenName}" Token`,
      icon: <CategoryIcon />,
      run() {
        addWidget(
          'Token',
          { label: tokenName, fill: '#9e9e9e', width: 1.9685 },
          { canvas, connection, game, selection }
        );
      },
    },
  ];
}
