export default {
  id: 'Warmahordes',
  banner: '/Warmahordes/banner.jpg',
  title: 'Warmachine & Hordes Mark III',
  description:
    'Warmachine & Hordes is a 30mm tabletop wargame set in a steampunk world where towering warjacks and ferocious warbeasts battle for control of the Iron Kingdoms.',
  discord: 'https://discord.gg/ntxKsPb',
  rules: 'https://www.loswarmachine.com/theory-and-tactics/2020/5/19/wartable',
  gameSettings: {
    system: 'Warmahordes',
    width: 48,
    height: 48,
    resource1Label: 'Health',
    resource2Label: 'Focus / Fury',
    resource3Label: 'Souls',
    showArcs: true,
    doubleMoveCost: true,
    name: 'Warmachine & Hordes',
    backgroundImageUrl: '/backgrounds/forest_jaden.jpg',
    player1Color: '#673ab7',
    player1Label: 'Player 1',
    player2Color: '#00bcd4',
    player2Label: 'Player 2',
    clockEnabled: false,
    clockPaused: true,
    clockActivePlayer: 1,
    clockPlayer1TimeRemaining: 60 * 60 * 1000,
    clockPlayer2TimeRemaining: 60 * 60 * 1000,
  },
};
