import BaseWidget from './BaseWidget';
import { fabric } from 'fabric-with-gestures';
import adjustFontSize from '../../adjustFontSize';

export default class RingTemplate extends BaseWidget {
  objects() {
    const width = this.props.width;
    const center = this.inchesToPixels(width / 2);
    const strokeWidth = this.inchesToPixels(0.05);
    const fill = this.props.record.attrs.fill || '#9e9e9e';
    this.circle = new fabric.Circle({
      left: 0,
      top: 0,
      radius: center - strokeWidth / 2,
      fill,
      stroke: '#fff',
      strokeWidth,
    });
    const middle = new fabric.Circle({
      radius: this.inchesToPixels(0.05),
      left: center,
      top: center,
      originX: 'center',
      originY: 'center',
      fill: '#fff',
      strokeWidth: 0,
    });
    this.label = new fabric.Text(this.labelText(), {
      stroke: '#fff',
      fill: '#fff',
      originX: 'center',
      originY: 'center',
      textAlign: 'center',
      left: center,
      top: center,
    });
    this.updateLabelSize();
    return [this.circle, middle, this.label];
  }
  updateLabelSize() {
    const maxSize = this.inchesToPixels(this.props.width * 0.75);
    adjustFontSize(this.label, { height: maxSize, width: maxSize });
  }
  labelText() {
    return (this.props.record.attrs.label || '').replace(/ +/g, '\n');
  }
  updateFabricObject() {
    this.label.set({
      text: this.labelText(),
    });
    this.circle.set({
      fill: this.props.record.attrs.fill || '#9e9e9e',
    });
    this.updateLabelSize();
    super.updateFabricObject();
  }
}
