import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  child,
  getDatabase,
  orderByChild,
  query,
  off,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  onValue,
  push,
  ref,
  remove,
  set,
} from 'firebase/database';
import { getStorage } from 'firebase/storage';

var config = {
  apiKey: 'AIzaSyC23STaOTrUWYB82F9HZ_siZm2ERxes96c',
  authDomain: 'wartable-8b035.firebaseapp.com',
  databaseURL: 'https://wartable-8b035.firebaseio.com',
  projectId: 'wartable-8b035',
  storageBucket: 'wartable-8b035.appspot.com',
  messagingSenderId: '498482492582',
};
const app = initializeApp(config);
export const auth = getAuth(app);
export const db = getDatabase(app);
export const storage = getStorage(app);

class Record {
  constructor({ id, attrs, path, type }) {
    this.id = id;
    this.path = path;
    this.attrs = attrs;
    this.type = type;
  }
  update(changes) {
    const newAttrs = Object.assign({}, this.attrs, changes);
    return set(ref(db, this.path), newAttrs);
  }
  remove() {
    return remove(ref(db, this.path));
  }
}

class GameConnection {
  constructor(id) {
    this.id = id;
  }
  add(type, data) {
    let key = push(child(ref(db), this.typePath(type))).key;
    return this.update(type, key, data).then((key) => {
      return key;
    });
  }
  addToken(attrs) {
    return onValue(
      child(ref(db, this.gamePath()), 'width'),
      (width) => {
        let defaultAttrs = {
          x: width / 2,
          y: width / 2,
          color: '#000000',
          width: 1.9685,
          facing: 0,
          originalFacing: 0,
          label: '',
          conditions: '',
          notes: '',
          resource1: '',
          resource2: '',
          resource3: '',
          imageUrl: '',
        };
        return this.add('token', Object.assign(defaultAttrs, attrs));
      },
      { onlyOnce: true }
    );
  }
  update(type, key, data) {
    if (!key) {
      data = type;
      return set(ref(db, this.gamePath()), data);
    }
    return set(ref(db, this.typeKeyPath(type, key)), data);
  }
  remove(type, key) {
    if (key) {
      return remove(ref(db, this.typeKeyPath(type, key)));
    }
    return remove(ref(db, this.typePath(type)));
  }
  onAdd(type, callback) {
    let wrappedCallback = (data) => {
      callback(
        new Record({
          id: data.key,
          path: this.typeKeyPath(type, data.key),
          attrs: data.val(),
          type,
        })
      );
    };
    let r = ref(db, this.typePath(type));
    onChildAdded(r, wrappedCallback);
    return function () {
      off(r, 'child_added', wrappedCallback);
    };
  }
  onChange(type, callback) {
    let gameId = this.id;
    if (!callback) {
      callback = type;
      let wrappedCallback = (data) => {
        callback(
          new Record({
            id: gameId,
            path: this.gamePath(),
            attrs: data.val(),
            type: 'game',
          })
        );
      };
      let r = ref(db, this.gamePath());
      onValue(r, wrappedCallback);
      return function () {
        off(r, 'value', wrappedCallback);
      };
    } else {
      let wrappedCallback = (data) => {
        callback(
          new Record({
            id: data.key,
            path: this.typeKeyPath(type, data.key),
            attrs: data.val(),
            type,
          })
        );
      };
      let r = ref(db, this.typePath(type));
      onChildChanged(r, wrappedCallback);
      return function () {
        off(r, 'child_changed', wrappedCallback);
      };
    }
  }
  onRemove(type, callback) {
    let wrappedCallback = (data) => {
      callback(this.typeKeyPath(type, data.key));
    };
    let r = ref(db, this.typePath(type));
    onChildRemoved(r, wrappedCallback);
    return function () {
      off(r, 'child_removed', wrappedCallback);
    };
  }
  typeKeyPath(type, key) {
    return `${this.typePath(type)}/${key}`;
  }
  gamePath() {
    return `${this.rootPath()}/game`;
  }
  typePath(type) {
    return `${this.rootPath()}/${type}s`;
  }
  rootPath() {
    return `/games/${this.id}`;
  }
  listTokens() {
    return new Promise((resolve) => {
      onValue(
        ref(db, this.typePath('token')),
        (snapshot) => {
          const tokens = snapshot.val();
          const records = Object.keys(tokens).map((id) => {
            const attrs = tokens[id];
            return new Record({
              id,
              attrs,
              path: this.typeKeyPath('token', id),
              type: 'token',
            });
          });
          resolve(records);
        },
        { onlyOnce: true }
      );
    });
  }
  // REVIEW: Could be merged with listTokens in a generic listRecords(type)
  listDecks() {
    return new Promise((resolve) => {
      onValue(
        ref(db, this.typePath('deck')),
        (snapshot) => {
          const decks = snapshot.val();
          const records = Object.keys(decks).map((id) => {
            const attrs = decks[id];
            return new Record({
              id,
              attrs,
              path: this.typeKeyPath('deck', id),
              type: 'deck',
            });
          });
          resolve(records);
        },
        { onlyOnce: true }
      );
    });
  }
}

export function listGames(userId) {
  return new Promise((resolve) => {
    const games = query(
      ref(db, `/gamesByUser/${userId}`),
      orderByChild('sort')
    );
    onValue(
      games,
      (snapshot) => {
        let records = [];
        snapshot.forEach(function (data) {
          let gameId = data.key;
          const record = new Promise((res) => {
            onValue(
              ref(db, `/games/${gameId}/game`),
              (game) => {
                let attrs = game.val();
                res({
                  id: gameId,
                  path: `/games/${gameId}`,
                  attrs,
                  remove() {
                    remove(ref(db, `/gamesByUser/${userId}/${gameId}`));
                  },
                });
              },
              { onlyOnce: true }
            );
          });
          records.push(record);
        });
        Promise.all(records).then((resolvedRecords) => {
          resolve(resolvedRecords);
        });
      },
      { onlyOnce: true }
    );
  });
}
export function connectToGame(id) {
  return new GameConnection(id);
}
export function newGame() {
  return push(ref(db), 'games').key;
}
export function newInviteCode() {
  return '' + Math.round(Math.random() * 10e10);
}
export function resetGame(connection, gameAttrs) {
  const gameRef = ref(db, `/games/${connection.id}`);
  return set(gameRef, {
    game: { ...gameAttrs, createdAt: new Date().getTime() },
  });
}
export function createGame(userUid, attrs) {
  let gameId = newGame();
  let connection = connectToGame(gameId);
  let inviteCode = newInviteCode();
  let now = new Date().getTime();
  let gameAttrs = Object.assign(
    {
      width: 48,
      height: 48,
      shape: 'square',
      name: '',
      system: 'Other',
      inviteCode,
      userId: userUid,
      createdAt: now,
      resource1Label: 'Health',
      resource2Label: 'Mana',
      resource3Label: 'Level',
      showArcs: true,
      doubleMoveCost: false,
      backgroundImageUrl: '',
    },
    attrs
  );
  return connection
    .update(gameAttrs)
    .then(() => {
      return set(ref(db, `/gamesByUser/${userUid}/${gameId}`), {
        inviteCode,
        sort: now,
      });
    })
    .then(() => {
      return connection;
    });
}
export function touchGameSort({ userId = null, gameId = null }) {
  return set(
    ref(db, `/gamesByUser/${userId}/${gameId}/sort`),
    new Date().getTime()
  );
}

function createGameCopy(gameIdToCopy, userId, shared) {
  return new Promise((resolve) => {
    onValue(
      ref(db, `/games/${gameIdToCopy}`),
      (snapshot) => {
        let gameAttrs = snapshot.val();
        let inviteCode = newInviteCode();
        delete gameAttrs.logs;
        gameAttrs.game.inviteCode = inviteCode;
        gameAttrs.game.userId = userId;
        gameAttrs.game.createdAt = new Date().getTime();
        gameAttrs.game.shared = shared;
        const gameId = newGame();
        return set(ref(db, `/games/${gameId}`), gameAttrs).then(() =>
          resolve({ gameId, inviteCode })
        );
      },
      { onlyOnce: true }
    );
  });
}

export function shareGameCopy(gameIdToCopy, userId) {
  return createGameCopy(gameIdToCopy, userId, true);
}

export function copyGame(gameIdToCopy, userId) {
  return createGameCopy(gameIdToCopy, userId, false).then(
    ({ gameId, inviteCode }) =>
      set(ref(db, `/gamesByUser/${userId}/${gameId}`), {
        inviteCode,
        sort: new Date().getTime(),
      }).then(() => ({ gameId, inviteCode }))
  );
}
