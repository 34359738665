import React, { Component, Fragment } from 'react';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Dialog from '../../../WTDialog';

export default class DeployDialog extends Component {
  state = {
    player1Lists: null,
    player2Lists: null,
  };
  confirmLists = (player) => {
    const { game } = this.props;
    game.update({
      [`player${player}Lists`]: this.state[`player${player}Lists`],
    });
  };
  confirmList = (player, list) => {
    const { game } = this.props;
    game.update({ [`player${player}List`]: list });
  };
  enterLists = () => {
    const { game } = this.props;
    return (
      <Grid container spacing={2}>
        {[1, 2].map((player) => (
          <Grid key={`player${player}`} item xs={12} sm={6}>
            <Typography variant="h6">
              {game.attrs[`player${player}Label`]}
            </Typography>
            {game.attrs[`player${player}Lists`] ? (
              <Typography variant="body1" paragraph>
                {game.attrs[`player${player}Label`]} list(s) confirmed
              </Typography>
            ) : (
              <Fragment>
                <TextField
                  margin="normal"
                  fullWidth
                  multiline
                  rows={5}
                  label="Paste your list(s) here"
                  helperText="Use 'Copy Army Text' in War Room"
                  value={this.state[`player${player}Lists`] || ''}
                  onChange={(e) => {
                    this.setState({ [`player${player}Lists`]: e.target.value });
                  }}
                />
                <Button
                  disabled={!this.state[`player${player}Lists`]}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.confirmLists(player);
                  }}
                >
                  Confirm
                </Button>
              </Fragment>
            )}
          </Grid>
        ))}
      </Grid>
    );
  };
  parseLists = (listText) => {
    const lines = listText.split(/\n/);
    const lists = [];
    lines.forEach((line, lineNum) => {
      const matchData =
        line.match(/^\[([^\]]+)\]/) || line.match(/(.*) - \w+: \+/);
      if (matchData && !line.match(/\[Theme/)) {
        lists.push({
          label: matchData[1],
          lineNum: lineNum,
        });
      }
    });
    return lists.map((list, i) => {
      const startLine = list.lineNum;
      const endLine = lists[i + 1] ? lists[i + 1].lineNum - 3 : lines.length;
      const text = lines.slice(startLine, endLine).join('\n');
      return {
        ...list,
        text,
      };
    });
  };
  chooseList = () => {
    const { game } = this.props;
    return (
      <Grid container spacing={2}>
        {[1, 2].map((player) => (
          <Grid key={`player${player}`} item xs={12} sm={6}>
            <Typography variant="h6">
              {game.attrs[`player${player}Label`]}
            </Typography>
            <TextField
              margin="normal"
              fullWidth
              multiline
              rows={20}
              value={game.attrs[`player${player}Lists`]}
              disabled
            />
            {game.attrs[`player${player}List`] ? (
              <Typography variant="body1">
                {game.attrs[`player${player}Label`]} chose a list
              </Typography>
            ) : (
              this.parseLists(game.attrs[`player${player}Lists`]).map(
                (list, i) => (
                  <Button
                    key={i}
                    onClick={() => this.confirmList(player, list)}
                    color="primary"
                    variant="contained"
                    style={{ marginRight: 16, marginBottom: 16 }}
                  >
                    Choose {list.label}
                  </Button>
                )
              )
            )}
          </Grid>
        ))}
      </Grid>
    );
  };
  revealLists = () => {
    const { game } = this.props;
    return (
      <Grid container spacing={2}>
        {[1, 2].map((player) => (
          <Grid key={`player${player}`} item xs={12} sm={6}>
            <Typography variant="h6">
              {game.attrs[`player${player}Label`]}
            </Typography>
            <TextField
              margin="normal"
              fullWidth
              multiline
              rows={20}
              value={game.attrs[`player${player}List`].text}
              disabled
            />
            <Typography variant="body1">
              {game.attrs[`player${player}Label`]} chose{' '}
              {game.attrs[`player${player}List`].label}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  };
  dialogContent = () => {
    const { game } = this.props;
    if (game.attrs.player1List && game.attrs.player2List) {
      return this.revealLists();
    }
    if (game.attrs.player1Lists && game.attrs.player2Lists) {
      return this.chooseList();
    }
    return this.enterLists();
  };
  onReset = () => {
    this.setState({
      player1Lists: null,
      player2Lists: null,
    });
    this.props.game.update({
      player1Lists: null,
      player2Lists: null,
      player1List: null,
      player2List: null,
    });
  };
  render() {
    if (!this.props.open) return null;
    const { game } = this.props;

    return (
      <Dialog open maxWidth="md" fullWidth onClose={this.props.onClose}>
        <DialogTitle>Lists</DialogTitle>
        <DialogContent>{this.dialogContent()}</DialogContent>
        <DialogActions>
          {(game.attrs.player1Lists || game.attrs.player2Lists) && (
            <Button onClick={this.onReset}>Reset</Button>
          )}
          {game.attrs.player1List && game.attrs.player2List ? (
            <Button
              variant="contained"
              color="primary"
              onClick={this.props.onClose}
            >
              Done
            </Button>
          ) : (
            <Button onClick={this.props.onClose}>Cancel</Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}
