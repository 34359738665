export default [
  {
    name: 'Blessing of the Gods',
  },
  {
    name: 'Break Through',
  },
  {
    name: 'Careful Reconnaissance',
  },
  {
    name: 'Defenses',
  },
  {
    name: 'Duck and Cover!',
  },
  {
    name: 'For the Motherland',
  },
  {
    name: 'Hit & Run',
  },
  {
    name: 'Lucky Penny',
  },
  {
    name: 'Old Faithful',
  },
  {
    name: 'Put the Fires Out',
  },
  {
    name: 'Sapper',
  },
  {
    name: 'Accursed',
  },
  {
    name: "Fortune's Path",
  },
  {
    name: 'Fortune’s Path',
  },
  {
    name: 'Ill Omens',
  },
  {
    name: 'Labyrinth',
  },
  {
    name: 'Ruin',
  },
  {
    name: 'Sacrifice',
  },
  {
    name: 'Shroud',
  },
];
