import BackspaceIcon from '@mui/icons-material/Backspace';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import CardsIcon from '../../../game/CardsIcon';

export default function deckActions(deck, log, onModalOpen) {
  const { cards = [], discard = [], name, faceUp } = deck.attrs;

  return [
    {
      icon: <CardsIcon />,
      label: 'Arrange Deck',
      onClick: () => {
        log('Opened deck', name);
        onModalOpen('deck-edit');
      },
    },
    {
      icon: <Rotate90DegreesCcwIcon />,
      label: 'Flip Top Card',
      disabled: cards.length <= 0,
      onClick: () => {
        deck.update({ faceUp: !faceUp });
        log('Flipped top card', name);
      },
    },
    {
      icon: <BackspaceIcon />,
      label: 'Discard Top Card',
      disabled: cards.length <= 0,
      onClick: () => {
        const newCards = [...cards];
        const newDiscard = [...discard, newCards.shift()];
        deck.update({
          cards: newCards,
          discard: newDiscard,
          faceUp: false,
        });
        log('Discarded top card', name);
      },
    },
  ];
}
