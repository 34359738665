import React from 'react';
import ReactGA from 'react-ga4';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export default (props) => {
  const Icon = props.icon;
  const { toggled } = props;
  const onClick = (...args) => {
    ReactGA.event({
      category: 'Action Drawer',
      action: props.primary,
    });
    props.onClick(...args);
    if (document.activeElement) document.activeElement.blur();
  };
  return (
    <ListItem
      button
      onClick={onClick}
      style={{ backgroundColor: toggled && 'rgba(0, 0, 0, 0.54)' }}
    >
      <ListItemIcon>
        <Icon style={{ color: toggled ? '#fff' : props.iconColor }} />
      </ListItemIcon>
      <ListItemText
        style={{ color: toggled && '#fff' }}
        primary={props.primary}
      />
    </ListItem>
  );
};
