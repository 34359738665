import { fabric } from 'fabric-with-gestures';
import FabricComponent from '../FabricComponent';
import adjustFontSize from '../../adjustFontSize';
import { LAYER_SCENARIO, LAYER_TERRAIN } from '../Layers';

export default class BaseShape extends FabricComponent {
  componentDidMount() {
    super.componentDidMount();
    this.fabricObject.setControlsVisibility({
      bl: false,
      br: false,
      mb: false,
      ml: false,
      mr: false,
      mt: false,
      tl: false,
      tr: false,
      mtr: true,
    });
    this.label = new fabric.Text(this.labelText(), {
      originX: 'center',
      originY: 'center',
      evented: false,
      selectable: false,
      textAlign: 'center',
      layer: this.props.record.attrs.scenario ? LAYER_SCENARIO : LAYER_TERRAIN,
    });
    this.updateLabel();
    this.props.canvas.add(this.label);
  }
  componentDidUpdate(prevProps) {
    if (
      this.attrsChanged(prevProps, [
        'label',
        'x',
        'y',
        'stroke',
        'fill',
        'height',
        'width',
        'facing',
      ]) ||
      this.props.edit !== prevProps.edit ||
      this.props.pixelsPerInch !== prevProps.pixelsPerInch
    ) {
      super.componentDidUpdate();
      this.updateLabel();
    }
  }
  attrsChanged(prevProps, attrsToCheck) {
    let { attrs } = this.props.record;
    let prevAttrs = prevProps.record.attrs;
    return attrsToCheck.find((attr) => {
      return prevAttrs[attr] !== attrs[attr];
    });
  }
  componentWillUnmount() {
    super.componentWillUnmount();
    this.props.canvas.remove(this.label);
  }
  updateLabel() {
    const { pixelsPerInch, record } = this.props;
    const { attrs } = record;
    this.label.set({
      text: this.labelText(),
      left: attrs.x * pixelsPerInch,
      top: attrs.y * pixelsPerInch,
      fill: attrs.stroke,
      fontSize: pixelsPerInch / 2.0,
      opacity: 0.7,
    });
    adjustFontSize(this.label, {
      height: this.maxLabelSize(),
      width: this.maxLabelSize(),
    });
  }
  labelText() {
    let text = this.props.record.attrs.label || '';
    return text.split(/ +/g).join('\n');
  }
  maxLabelSize() {
    const boundingRect = this.fabricObject.getBoundingRect();
    return Math.min(boundingRect.width, boundingRect.height) / 1.5;
  }
  layer() {
    return this.props.record && this.props.record.attrs.scenario
      ? LAYER_SCENARIO
      : LAYER_TERRAIN;
  }
  fabricObjectAttrs(attrs, pixelsPerInch) {
    return Object.assign(
      {
        evented: this.props.edit,
        selectable: this.props.edit,
        opacity: attrs.opacity ?? 0.7,
        strokeWidth: attrs.strokeWidth ?? 1,
        record: this.props.record,
      },
      this.shapeAttrs(attrs, pixelsPerInch)
    );
  }
}
