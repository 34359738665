export default [
  {
    title: 'Common Effects',
    values: [
      { color: '#e91e63', text: 'Leader', icon: '/emoji/star.png' },
      { color: '#ffffff', text: 'Corrosion', icon: '/emoji/test_tube.png' },
      { color: '#ffffff', text: 'Disruption', icon: '/emoji/zap.png' },
      { color: '#ffffff', text: 'Fire', icon: '/emoji/fire.png' },
      { color: '#ffffff', text: 'Knocked Down', icon: '/emoji/arrow_down.png' },
      { color: '#00bcd4', text: 'Stationary', icon: '/emoji/snowflake.png' },
    ],
  },
  {
    title: 'Effects',
    values: [
      {
        text: 'Paralysis',
        color: '#607d8b',
        icon: '/emoji/nauseated_face.png',
      },
      {
        text: 'Blind',
        color: '#607d8b',
        icon: '/emoji/see_no_evil.png',
      },
      {
        text: 'Mini Feat Used',
        color: '#9e9e9e',
        icon: '/emoji/white_check_mark.png',
      },
      {
        text: 'Shield Guard',
        color: '#ff5722',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Artillerist',
        color: '#f44336',
        icon: '/emoji/bomb.png',
      },
      {
        text: 'Battle Plan',
        color: '#ff5722',
        icon: '/emoji/spiral_note_pad.png',
      },
      {
        text: 'Battle Driven',
        color: '#f44336',
        icon: '/emoji/crossed_swords.png',
      },
      {
        text: 'Warp Strength',
        color: '#4caf50',
        icon: '/emoji/muscle.png',
      },
      {
        text: 'Anchor',
        color: '#9e9e9e',
        icon: '/emoji/anchor.png',
      },
      {
        text: 'Countercharge',
        color: '#f44336',
        icon: '/emoji/recycle.png',
      },
      {
        text: 'Craft Talisman',
        color: '#9c27b0',
        icon: '/emoji/medal.png',
      },
      {
        text: 'Curse',
        color: '#4caf50',
        icon: '/emoji/face_with_symbols_on_mouth.png',
      },
      {
        text: 'Defensive Strike',
        color: '#f44336',
        icon: '/emoji/crossed_swords.png',
      },
      {
        text: 'Desperate Pace',
        color: '#f44336',
        icon: '/emoji/foot.png',
      },
      {
        text: 'Discombobulate',
        color: '#9c27b0',
        icon: '/emoji/dizzy_face.png',
      },
      {
        text: 'Dig In',
        color: '#795548',
        icon: '/emoji/pick.png',
      },
      {
        text: 'Enrage',
        color: '#f44336',
        icon: '/emoji/pouting_cat.png',
      },
      {
        text: 'Exhaust Fumes',
        color: '#607d8b',
        icon: '/emoji/cloud.png',
      },
      {
        text: 'Eyeless Sight',
        color: '#9e9e9e',
        icon: '/emoji/see_no_evil.png',
      },
      {
        text: 'Feat',
        color: '#673ab7',
        icon: '/emoji/boom.png',
      },
      {
        text: 'Fell Calls',
        color: '#2196f3',
        icon: '/emoji/loudspeaker.png',
      },
      {
        text: 'Fleet',
        color: '#4caf50',
        icon: '/emoji/foot.png',
      },
      {
        text: 'Good Fortune',
        color: '#9e9e9e',
        icon: '/emoji/crossed_fingers.png',
      },
      {
        text: 'Grip of Shadows',
        color: '#9e9e9e',
        icon: '/emoji/bust_in_silhouette.png',
      },
      {
        text: 'Grievous Wounds',
        color: '#f44336',
        icon: '/emoji/face_with_head_bandage.png',
      },
      {
        text: 'Hand Of Vengeance',
        color: '#f44336',
        icon: '/emoji/hand.png',
      },
      {
        text: 'Imprint',
        color: '#2196f3',
        icon: '/emoji/feet.png',
      },
      {
        text: 'Incorporeal',
        color: '#4caf50',
        icon: '/emoji/ghost.png',
      },
      {
        text: 'Inert',
        color: '#00bcd4',
        icon: '/emoji/robot_face.png',
      },
      {
        text: 'Magical Weapons',
        color: '#9e9e9e',
        icon: '/emoji/sparkles.png',
      },
      {
        text: 'Prey',
        color: '#f44336',
        icon: '/emoji/dart.png',
      },
      {
        text: 'Rangefinder',
        color: '#9e9e9e',
        icon: '/emoji/see_no_evil.png',
      },
      {
        text: 'Retaliatory Strike',
        color: '#f44336',
        icon: '/emoji/crossed_swords.png',
      },
      {
        text: 'Righteous Fury',
        color: '#ff5722',
        icon: '/emoji/rage.png',
      },
      {
        text: 'Righteous Vengeance',
        color: '#ff5722',
        icon: '/emoji/imp.png',
      },
      {
        text: 'Silencer',
        color: '#607d8b',
        icon: '/emoji/zipper_mouth_face.png',
      },
      {
        text: 'Standard Bearer',
        color: '#9e9e9e',
        icon: '/emoji/triangular_flag_on_post.png',
      },
      {
        text: 'Swift Vengeance',
        color: '#2196f3',
        icon: '/emoji/imp.png',
      },
      {
        text: 'Vengeance',
        color: '#673ab7',
        icon: '/emoji/imp.png',
      },
      {
        text: 'Fueled Up',
        color: '#9e9e9e',
        icon: '/emoji/fuelpump.png',
      },
      {
        text: 'Guidance',
        color: '#2196f3',
        icon: '/emoji/dart.png',
      },
      {
        text: 'Hymn of Battle',
        color: '#3f51b5',
        icon: '/emoji/crossed_swords.png',
      },
      {
        text: 'Hymn of Passage',
        color: '#3f51b5',
        icon: '/emoji/globe_with_meridians.png',
      },
      {
        text: 'Hymn of Shielding',
        color: '#3f51b5',
        icon: '/emoji/shield.png',
      },
      {
        text: "Hunter's Mark",
        color: '#f44336',
        icon: '/emoji/dart.png',
      },
      {
        text: 'Ice Cage',
        color: '#2196f3',
        icon: '/emoji/cold_face.png',
      },
      {
        text: 'Pathfinder',
        color: '#9e9e9e',
        icon: '/emoji/feet.png',
      },
      {
        text: 'Puppet Master',
        color: '#9c27b0',
        icon: '/emoji/lying_face.png',
      },
      {
        text: 'Shadowbind',
        color: '#9c27b0',
        icon: '/emoji/bust_in_silhouette.png',
      },
      {
        text: 'Sigil Of Power',
        color: '#ff5722',
        icon: '/emoji/radioactive_sign.png',
      },
      {
        text: 'Superior Ammunition',
        color: '#9e9e9e',
        icon: '/emoji/rocket.png',
      },
      {
        text: 'Whispers at the Gate',
        color: '#9e9e9e',
        icon: '/emoji/speak_no_evil.png',
      },
      {
        text: 'Wild',
        color: '#4caf50',
        icon: '/emoji/pouting_cat.png',
      },
    ],
  },
  {
    title: 'Spells',
    values: [
      {
        text: 'Admonition',
        color: '#4caf50',
        icon: '/emoji/man_dancing.png',
      },
      {
        text: 'Arcane Shield',
        color: '#2196f3',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Assail',
        color: '#f44336',
        icon: '/emoji/gun.png',
      },
      {
        text: 'Backlash',
        color: '#2196f3',
        icon: '/emoji/zap.png',
      },
      {
        text: 'Banishing Ward',
        color: '#2196f3',
        icon: '/emoji/hand.png',
      },
      {
        text: 'Battle Charged',
        color: '#f44336',
        icon: '/emoji/zap.png',
      },
      {
        text: 'Battle Lust',
        color: '#f44336',
        icon: '/emoji/pouting_cat.png',
      },
      {
        text: 'Black Spot',
        color: '#607d8b',
        icon: '/emoji/black_circle.png',
      },
      {
        text: 'Blur',
        color: '#ffffff',
        icon: '/emoji/wave.png',
      },
      {
        text: 'Boundless Charge',
        color: '#4caf50',
        icon: '/emoji/foot.png',
      },
      {
        text: 'Bullet Dodger',
        color: '#4caf50',
        icon: '/emoji/man_dancing.png',
      },
      {
        text: 'Calamity',
        color: '#f44336',
        icon: '/emoji/comet.png',
      },
      {
        text: 'Carnage',
        color: '#f44336',
        icon: '/emoji/drop_of_blood.png',
      },
      {
        text: 'Curse Of Shadows',
        color: '#4caf50',
        icon: '/emoji/bust_in_silhouette.png',
      },
      {
        text: 'Dash',
        color: '#4caf50',
        icon: '/emoji/foot.png',
      },
      {
        text: 'Deadeye',
        color: '#f44336',
        icon: '/emoji/eye.png',
      },
      {
        text: 'Death March',
        color: '#f44336',
        icon: '/emoji/skull.png',
      },
      {
        text: 'Deceleration',
        color: '#2196f3',
        icon: '/emoji/turtle.png',
      },
      {
        text: "Defender's Ward",
        color: '#ff5722',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Engine Of Destruction',
        color: '#f44336',
        icon: '/emoji/boom.png',
      },
      {
        text: 'Fire For Effect',
        color: '#ff5722',
        icon: '/emoji/firecracker.png',
      },
      {
        text: 'Fog Of War',
        color: '#607d8b',
        icon: '/emoji/cloud.png',
      },
      {
        text: 'Fortune',
        color: '#ff5722',
        icon: '/emoji/crossed_fingers.png',
      },
      {
        text: 'Fury',
        color: '#f44336',
        icon: '/emoji/pouting_cat.png',
      },
      {
        text: 'Ghost Walk',
        color: '#4caf50',
        icon: '/emoji/ghost.png',
      },
      {
        text: 'Guided Fire',
        color: '#2196f3',
        icon: '/emoji/rocket.png',
      },
      {
        text: 'Hallowed Avenger',
        color: '#ff5722',
        icon: '/emoji/crossed_swords.png',
      },
      {
        text: 'Hand Of Fate',
        color: '#ff5722',
        icon: '/emoji/game_die.png',
      },
      {
        text: 'Ignite',
        color: '#f44336',
        icon: '/emoji/boom.png',
      },
      {
        text: 'Incite',
        color: '#f44336',
        icon: '/emoji/face_with_symbols_on_mouth.png',
      },
      {
        text: 'Infernal Machine',
        color: '#4caf50',
        icon: '/emoji/smiling_imp.png',
      },
      {
        text: 'Inviolable Resolve',
        color: '#2196f3',
        icon: '/emoji/grimacing.png',
      },
      {
        text: 'Iron Flesh',
        color: '#607d8b',
        icon: '/emoji/magnet.png',
      },
      {
        text: 'Lamentation',
        color: '#2196f3',
        icon: '/emoji/sob.png',
      },
      {
        text: 'Lightning Shroud',
        color: '#ff5722',
        icon: '/emoji/zap.png',
      },
      {
        text: 'Marked For Death',
        color: '#ff5722',
        icon: '/emoji/skull.png',
      },
      {
        text: 'Mirage',
        color: '#2196f3',
        icon: '/emoji/palm_tree.png',
      },
      {
        text: 'Mobility',
        color: '#4caf50',
        icon: '/emoji/foot.png',
      },
      {
        text: 'Mortality',
        color: '#4caf50',
        icon: '/emoji/dizzy_face.png',
      },
      {
        text: 'Occultation',
        color: '#607d8b',
        icon: '/emoji/see_no_evil.png',
      },
      {
        text: 'Parasite',
        color: '#ffffff',
        icon: '/emoji/microbe.png',
      },
      {
        text: 'Polarity Shield',
        color: '#ff5722',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Positive Charge',
        color: '#ff5722',
        icon: '/emoji/zap.png',
      },
      {
        text: 'Pursuit',
        color: '#ff5722',
        icon: '/emoji/foot.png',
      },
      {
        text: 'Quicken',
        color: '#2196f3',
        icon: '/emoji/foot.png',
      },
      {
        text: 'Rebuke',
        color: '#f44336',
        icon: '/emoji/hand.png',
      },
      {
        text: 'Refuge',
        color: '#4caf50',
        icon: '/emoji/house.png',
      },
      {
        text: 'Road To War',
        color: '#795548',
        icon: '/emoji/crossed_swords.png',
      },
      {
        text: 'Scything Touch',
        color: '#4caf50',
        icon: '/emoji/point_up.png',
      },
      {
        text: 'Snipe',
        color: '#2196f3',
        icon: '/emoji/gun.png',
      },
      {
        text: 'Solid Ground',
        color: '#607d8b',
        icon: '/emoji/mountain.png',
      },
      {
        text: 'Spellpiercer',
        color: '#ff5722',
        icon: '/emoji/sparkles.png',
      },
      {
        text: 'Stranglehold',
        color: '#f44336',
        icon: '/emoji/scream.png',
      },
      {
        text: 'Synergy',
        color: '#9c27b0',
        icon: '/emoji/sparkling_heart.png',
      },
      {
        text: 'Tactical Supremacy',
        color: '#607d8b',
        icon: '/emoji/bomb.png',
      },
      {
        text: 'Watcher',
        color: '#ff5722',
        icon: '/emoji/eyes.png',
      },
      {
        text: 'Warpath',
        color: '#f44336',
        icon: '/emoji/crossed_swords.png',
      },
      {
        text: 'Wild Aggression',
        color: '#f44336',
        icon: '/emoji/pouting_cat.png',
      },
      {
        text: 'Weald Secrets',
        color: '#4caf50',
        icon: '/emoji/speak_no_evil.png',
      },
      {
        text: 'Wind Wall',
        color: '#2196f3',
        icon: '/emoji/wind_blowing_face.png',
      },
    ],
  },
  {
    title: 'Animi',
    values: [
      {
        text: 'Counterblast',
        color: '#f44336',
        icon: '/emoji/bomb.png',
      },
      {
        text: 'Elusive',
        color: '#2196f3',
        icon: '/emoji/wave.png',
      },
      {
        text: 'Lightning Strike',
        color: '#ff5722',
        icon: '/emoji/zap.png',
      },
      {
        text: 'Primal',
        color: '#4caf50',
        icon: '/emoji/pouting_cat.png',
      },
      {
        text: 'Rage',
        color: '#f44336',
        icon: '/emoji/rage.png',
      },
      {
        text: 'Rush',
        color: '#2196f3',
        icon: '/emoji/foot.png',
      },
      {
        text: 'Spiny Growth',
        color: '#4caf50',
        icon: '/emoji/hedgehog.png',
      },
      {
        text: 'Swarm',
        color: '#4caf50',
        icon: '/emoji/bee.png',
      },
      {
        text: 'Wraithbane',
        color: '#ff5722',
        icon: '/emoji/ghost.png',
      },
    ],
  },
];
