import BaseWidget from './BaseWidget';
import { fabric } from 'fabric-with-gestures';
import adjustFontSize from '../../adjustFontSize';

export default class Token extends BaseWidget {
  objects() {
    const center = this.inchesToPixels(this.width() / 2);
    this.circle = new fabric.Circle({
      left: 0,
      top: 0,
      originX: 'center',
      originY: 'center',
      radius: center,
      fill: this.fillColor(),
      stroke: '#fff',
      strokeWidth: 0,
    });
    this.label = new fabric.Text(this.labelText(), {
      stroke: '#fff',
      fill: '#fff',
      originX: 'center',
      originY: 'center',
      textAlign: 'center',
      left: 0,
      top: 0,
      strokeWidth: this.inchesToPixels(0.01),
    });
    this.updateLabelSize();
    return [this.circle, this.label];
  }
  labelText() {
    return (this.props.record.attrs.label || '').replace(/ +/g, '\n');
  }
  fillColor() {
    return this.props.record.attrs.fill || '#9e9e9e';
  }
  width() {
    return this.props.record.attrs.width;
  }
  updateLabelSize() {
    const maxSize = this.inchesToPixels(this.width() * 0.8);
    adjustFontSize(this.label, { height: maxSize, width: maxSize });
  }
  updateFabricObject() {
    this.label.set({ text: this.labelText() });
    this.circle.set({ fill: this.fillColor() });
    this.updateLabelSize();
    super.updateFabricObject();
  }
}
