import React, { Component, Fragment } from 'react';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';

import modelToToken from './modelToToken';
import setFormation from './setFormation';
import textToCards from './textToCards';
import textToModels from './textToModels';
import ColorPicker from '../../../game/ColorPicker';
import Dialog from '../../../WTDialog';
import { widthFor } from '../../../map/Token';

export default class DeployDialog extends Component {
  state = {
    deployTop: true,
    deployPlayer: 1,
    text: '',
  };
  addModel = ({
    model,
    deployLine,
    effects,
    group,
    width,
    xOffset,
    yOffset,
    x,
    y,
  }) => {
    const { color } = this.props;
    const { deployTop } = this.state;

    const facing = deployTop ? 180 : 0;
    const tokenAttrs = {
      ...modelToToken(model),
      width,
      effects,
      color,
      facing,
      x: x + xOffset,
      y: y + deployLine + yOffset,
      group,
    };
    this.props.onAddToken(tokenAttrs);
  };
  deploy = () => {
    const { color } = this.props;
    let { deployTop } = this.state;
    const player = this.state.deployPlayer;
    const list = this.props.game.attrs[`player${player}List`];
    let text = list ? list.text : this.state.text;

    const groups = textToModels(text, { color });
    let x = 12;
    let deployLine = deployTop ? 15 : 33;
    let offsetDirection = deployTop ? -1 : 1;
    groups.forEach((models, groupNum) => {
      if (modelToToken(models[0].model).resource2) {
        // battlegroup
        let x = 0;
        models.forEach((model) => {
          model.y = 0;
          model.x = x + model.width / 2;
          x += model.width;
        });
      } else {
        setFormation(models, offsetDirection);
      }
      const minX = Math.min(
        ...models.map((model) => model.x - model.width / 2)
      );
      const maxX = Math.max(
        ...models.map((model) => model.x + model.width / 2)
      );
      const groupWidth = maxX - minX;

      models.forEach((model) => {
        this.addModel({
          ...model,
          deployLine,
          yOffset: (offsetDirection * model.width) / 2,
          xOffset: x - minX,
        });
      });
      x += groupWidth;
      if (x > 36) {
        x = 12;
        deployLine = deployLine - offsetDirection * 4;
        if (deployLine < 3) deployLine = 3;
        if (deployLine > 45) deployLine = 45;
      }
    });
    const cards = textToCards(text);
    cards.forEach((card, i) => {
      this.props.addWidget('Token', {
        label: card.name,
        fill: color,
        width: widthFor('50mm'),
        x: 20 + i * widthFor('50mm'),
        y: deployTop ? 8 : 40,
      });
    });
    this.props.game.update({ deploy: true });
    this.props.onClose();
  };
  chooseList = () => {
    const { game } = this.props;
    return (
      <FormControl fullWidth component="fieldset" style={{ marginBottom: 16 }}>
        <FormLabel component="legend">Choose List</FormLabel>
        <RadioGroup
          aria-label="deployTop"
          name="deployTop"
          value={this.state.deployPlayer}
          onChange={(e) => {
            this.setState({ deployPlayer: parseInt(e.target.value, 10) });
          }}
        >
          {[1, 2].map((player, i) => (
            <FormControlLabel
              key={i}
              value={player}
              control={<Radio />}
              label={`${game.attrs[`player${player}Label`]}: ${game.attrs[`player${player}List`].label}`}
              style={{ marginBottom: -16 }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  };
  enterList = () => {
    const { text } = this.state;
    return (
      <TextField
        autoFocus
        margin="normal"
        fullWidth
        multiline
        rows={3}
        label="Paste your list here"
        helperText="Use 'Copy Force To Text' in the Warmachine app"
        value={text}
        onChange={(e) => {
          this.setState({ text: e.target.value });
        }}
      />
    );
  };
  dialogContent = () => {
    const { color, game } = this.props;
    const { deployTop } = this.state;
    return (
      <Fragment>
        {game.attrs.player1List && game.attrs.player2List
          ? this.chooseList()
          : this.enterList()}
        <FormControl
          fullWidth
          component="fieldset"
          style={{ marginBottom: 16 }}
        >
          <FormLabel component="legend">Deploy Side</FormLabel>
          <RadioGroup
            aria-label="deployTop"
            name="deployTop"
            value={deployTop ? 'true' : 'false'}
            onChange={(e) => {
              this.setState({ deployTop: e.target.value === 'true' });
            }}
          >
            <FormControlLabel
              style={{ marginBottom: -16 }}
              value="true"
              control={<Radio />}
              label="Top"
            />
            <FormControlLabel
              style={{ marginBottom: -16 }}
              value="false"
              control={<Radio />}
              label="Bottom"
            />
          </RadioGroup>
        </FormControl>
        <ColorPicker
          label="Base Color"
          value={color}
          onChange={(e) => this.props.onChangeColor(e.target.value)}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={this.deploy}
          style={{ marginTop: 16 }}
        >
          Deploy
        </Button>
      </Fragment>
    );
  };
  render() {
    const { onClose, open } = this.props;
    if (!open) return null;

    return (
      <Dialog open maxWidth="sm" fullWidth onClose={onClose}>
        <DialogTitle>Deploy</DialogTitle>
        <DialogContent>{this.dialogContent()}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
