export default {
  id: 'Breachstorm',
  title: 'Breachstorm',
  description:
    'Breachstorm™ is a competitive 30mm miniatures game that puts you in command of a team of elite special operatives in a race to complete asymmetric, narrative mission objectives.',
  disclaimer:
    '* War Table is not affiliated with Breachstorm Entertainment LLC.',
  banner: '/Breachstorm/banner.png',
  rules: 'https://www.breachstorm.com/rules',
  gameSettings: {
    system: 'Breachstorm',
    width: 36,
    height: 36,
    resource1Label: 'Damage',
    resource2Label: 'Actions',
    resource3Label: 'Orders',
    name: 'Breachstorm',
    backgroundImageUrl: '/Breachstorm/backgrounds/forest.jpg',
    showArcs: false,
    player1Color: '#673ab7',
    player1Label: 'Player 1',
    player2Color: '#00bcd4',
    player2Label: 'Player 2',
    clockEnabled: false,
    clockPaused: true,
    clockActivePlayer: 1,
    clockPlayer1TimeRemaining: 60 * 60 * 1000,
    clockPlayer2TimeRemaining: 60 * 60 * 1000,
  },
};
