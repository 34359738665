import React, { Component } from 'react';
import { fabric } from 'fabric-with-gestures';
import FabricComponent from './FabricComponent';
import { LAYER_UI } from './Layers';

class ButtonBackground extends FabricComponent {
  static defaultProps = {
    opacity: 0.7,
    hoverOpacity: 0.9,
  };

  createFabricObject() {
    const { canvas, height, hoverOpacity, width, onClick, opacity } =
      this.props;
    const object = new fabric.Rect({
      height,
      width,
      originX: 'center',
      originY: 'center',
      selectable: false,
      stroke: '#ffffff',
      fill: '#ffffff',
      opacity: 0.7,
      hoverCursor: 'pointer',
      rx: width / 2,
      ry: width / 2,
      layer: LAYER_UI,
    });
    object.on('mouseup', onClick);
    object.on('mouseover', () => {
      object.set({ opacity: hoverOpacity });
      canvas.renderAll();
    });
    object.on('mouseout', () => {
      object.set({ opacity });
      canvas.renderAll();
    });
    return object;
  }
  updateFabricObject() {
    const { left, top } = this.props;
    this.fabricObject.set({
      top,
      left,
    });
    this.fabricObject.setCoords();
  }
}

class ButtonLabel extends FabricComponent {
  createFabricObject() {
    const { left, text, top, width } = this.props;
    return new fabric.Text(text, {
      originX: 'center',
      originY: 'center',
      top: top,
      left: left,
      stroke: this.props.color,
      fill: this.props.color,
      fontSize: 0.5 * width,
      fontFamily: 'Roboto',
      evented: false,
      selectable: false,
      layer: LAYER_UI,
    });
  }
  updateFabricObject() {
    const { left, text, top } = this.props;
    this.fabricObject.set({
      top,
      left,
      text,
    });
    this.fabricObject.setCoords();
  }
}

export default class FabricButton extends Component {
  static defaultProps = {
    height: 25,
    width: 90,
    color: '#ffffff',
  };
  render() {
    const { canvas, color, height, left, onClick, text, top, width } =
      this.props;
    const centerLeft = left;
    const centerTop = top;
    return (
      <>
        <ButtonBackground
          top={centerTop}
          left={centerLeft}
          canvas={canvas}
          onClick={onClick}
          width={width}
          height={height}
        />
        <ButtonLabel
          top={centerTop}
          left={centerLeft}
          canvas={canvas}
          text={text}
          width={width}
          height={height}
          color={color}
        />
      </>
    );
  }
}
