import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddImage from './AddImage';
import Logo from './Logo';
import { createGame } from './initFirebase';

export class GameConfigurator extends Component {
  state = {};

  componentDidMount() {
    return createGame(this.props.user.uid).then((connection) => {
      this.setState({ connection });
      connection.onChange(this.gameUpdated.bind(this));
    });
  }
  gameUpdated(game) {
    this.setState({ game });
  }
  viewGame = () => {
    this.setState({ done: true });
  };
  onChange(attr, event) {
    let value = event.target.value;
    if (attr === 'showArcs') {
      value = event.target.checked;
    }
    this.state.game.update({ [attr]: value });
  }
  changeWidth(event) {
    let size = parseInt(event.target.value, 10);
    this.state.game.update({ width: size });
  }
  changeHeight(event) {
    let size = parseInt(event.target.value, 10);
    this.state.game.update({ height: size });
  }
  changeSize(event) {
    let size = parseInt(event.target.value, 10);
    this.state.game.update({ height: size, width: size });
  }
  setBackgroundImage = (backgroundImageUrl) => {
    this.state.game.update({ backgroundImageUrl });
  };
  systems() {
    return [
      'Guild Ball',
      'Infinity',
      'Malifaux',
      'Warmachine',
      'Warmahordes',
      'Other',
    ];
  }
  render() {
    let { game } = this.state;
    if (!game || !game.attrs) {
      return '';
    }
    if (this.state.done === true) {
      return <Redirect to={`/games/${this.state.connection.id}`} />;
    }
    return (
      <div>
        <Grid container alignContent="flex-start" style={{ height: '100vh' }}>
          <Grid item xs={12} align="center">
            <Typography variant="h2">
              <Logo />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper
              square={true}
              style={{
                minHeight: '50vh',
                maxWidth: '500px',
                marginTop: 8 * 6,
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: 8 * 3,
              }}
            >
              <Typography variant="h5" gutterBottom>
                Configure your game
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name your game"
                    value={game.attrs.name}
                    onChange={this.onChange.bind(this, 'name')}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel component="legend">Game Settings</FormLabel>
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="system-select" variant="standard">
                      Game System
                    </InputLabel>
                    <Select
                      inputProps={{ name: 'system', id: 'system-select' }}
                      value={`${game.attrs.system}`}
                      onChange={this.onChange.bind(this, 'system')}
                      variant="standard"
                    >
                      {this.systems().map((system) => {
                        return (
                          <MenuItem key={system} value={system}>
                            {system}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={game.attrs.showArcs}
                        onChange={this.onChange.bind(this, 'showArcs')}
                        value="true"
                      />
                    }
                    label="Mark front arc?"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Resource 1"
                    value={game.attrs.resource1Label}
                    onChange={this.onChange.bind(this, 'resource1Label')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Resource 2"
                    value={game.attrs.resource2Label}
                    onChange={this.onChange.bind(this, 'resource2Label')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Resource 3"
                    value={game.attrs.resource3Label}
                    onChange={this.onChange.bind(this, 'resource3Label')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel component="legend">Map Settings</FormLabel>
                </Grid>
                {game.attrs.shape === 'rect' ? (
                  <>
                    <Grid item xs={2}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="width-select" variant="standard">
                          Width
                        </InputLabel>
                        <Select
                          inputProps={{ name: 'width', id: 'width-select' }}
                          value={`${game.attrs.width}`}
                          onChange={this.changeWidth.bind(this)}
                          variant="standard"
                        >
                          <MenuItem value="24">2’</MenuItem>
                          <MenuItem value="36">3’</MenuItem>
                          <MenuItem value="48">4’</MenuItem>
                          <MenuItem value="60">5’</MenuItem>
                          <MenuItem value="72">6’</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="height-select" variant="standard">
                          Height
                        </InputLabel>
                        <Select
                          inputProps={{ name: 'height', id: 'height-select' }}
                          value={`${game.attrs.height}`}
                          onChange={this.changeHeight.bind(this)}
                          variant="standard"
                        >
                          <MenuItem value="24">2’</MenuItem>
                          <MenuItem value="36">3’</MenuItem>
                          <MenuItem value="48">4’</MenuItem>
                          <MenuItem value="60">5’</MenuItem>
                          <MenuItem value="72">6’</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="size-select" variant="standard">
                        Size
                      </InputLabel>
                      <Select
                        inputProps={{ name: 'size', id: 'size-select' }}
                        value={`${game.attrs.width}`}
                        onChange={this.changeSize.bind(this)}
                        variant="standard"
                      >
                        <MenuItem value="24">2’ x 2’</MenuItem>
                        <MenuItem value="36">3’ x 3’</MenuItem>
                        <MenuItem value="48">4’ x 4’</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="shape-select" variant="standard">
                      Shape
                    </InputLabel>
                    <Select
                      inputProps={{ name: 'shape', id: 'shape-select' }}
                      value={game.attrs.shape}
                      onChange={this.onChange.bind(this, 'shape')}
                      variant="standard"
                    >
                      <MenuItem value="square">Square</MenuItem>
                      <MenuItem value="rect">Rectangular</MenuItem>
                      <MenuItem value="circle">Circle</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <AddImage
                      imageUrl={game.attrs.backgroundImageUrl}
                      onImageUploaded={this.setBackgroundImage}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
            <div
              style={{
                marginTop: 8 * 3,
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '500px',
                textAlign: 'right',
              }}
            >
              <Button
                onClick={this.viewGame.bind(this)}
                variant="contained"
                color="primary"
              >
                Start Game
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default GameConfigurator;
