import React, { Component } from 'react';

import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import withWidth, { isWidthUp } from '../withWidth';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LaunchIcon from '@mui/icons-material/Launch';

class CardsDialog extends Component {
  popout = () => {
    const { cards, title } = this.props;
    if (window.cardsPopout && window.cardsPopout.parent) {
      window.cardsPopout.postMessage({ cards, title });
    } else {
      window.cardsPopout = window.open(
        window.location.href + '/cards',
        'CardsPopout',
        'width=400,height=600,menubar=no,status=no'
      );
      window.cardsPopout.addEventListener(
        'DOMContentLoaded',
        () => {
          setTimeout(() => {
            window.cardsPopout.postMessage({ cards, title });
          }, 1000);
        },
        false
      );
    }
    this.props.onClose();
  };
  render() {
    const { cards, open, title, width } = this.props;
    if (!open) return null;
    let maxWidth = '100%';
    if (cards.length > 1 && isWidthUp('sm', width)) {
      maxWidth = '50%';
    }
    if (cards.length > 2 && isWidthUp('lg', width)) {
      maxWidth = '25%';
    }
    return (
      <Dialog open={open} fullScreen onClose={this.props.onClose}>
        <AppBar style={{ position: 'relative' }}>
          <Toolbar
            style={{
              padding:
                'env(safe-area-inset-top) env(safe-area-inset-right) 0 env(safe-area-inset-left)',
              backgroundColor: 'white',
            }}
          >
            <IconButton
              edge="start"
              onClick={this.props.onClose}
              aria-label="Close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              {title}
            </Typography>
            {isWidthUp('md', width) && (
              <IconButton onClick={this.popout} aria-label="Pop out">
                <LaunchIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        <DialogContent
          style={{
            marginTop: 10,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            padding:
              '0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
          }}
        >
          {cards &&
            cards.map((card, i) => {
              return (
                <div key={i} style={{ maxWidth }}>
                  <img
                    alt={title}
                    src={card}
                    style={{ objectFit: 'contain', width: '100%' }}
                  />
                </div>
              );
            })}
        </DialogContent>
      </Dialog>
    );
  }
}

export default withWidth()(CardsDialog);
