import { fabric } from 'fabric-with-gestures';
import adjustFontSize from '../adjustFontSize';

export default class TokenRenderer {
  constructor(props) {
    this.props = props;
    this.width = props.width;
    this.height = props.height;
    this.shape = props.shape;
    this.highlight = props.highlight;
    this.radius = props.width / 2;
    this.center = props.width / 2;
    this.borderWidth = props.shape === 'rect' ? 5 : props.width / 12;
    this.imageWidth = props.width - 2 * this.borderWidth;
    this.imageHeight = props.height - 2 * this.borderWidth;

    this.canvasEl = document.createElement('canvas');
    this.canvasEl.width = props.width + 2;
    this.canvasEl.height = props.height + 2;
    this.canvas = new fabric.StaticCanvas(this.canvasEl, {
      renderOnAddRemove: false,
    });
  }
  base() {
    if (this.shape === 'rect') {
      return new fabric.Rect({
        width: this.width,
        height: this.height,
        fill: '#000',
      });
    }

    let gradientColor = this.props.color;
    if (gradientColor === '#000000' || !gradientColor) {
      // avoid Black token looking flat
      gradientColor = '#222222';
    }
    const borderCenter = this.radius - this.borderWidth / 2;
    const base = new fabric.Circle({
      top: this.center,
      left: this.center,
      radius: borderCenter,
      strokeWidth: this.borderWidth,
      fill: '',
      originX: 'center',
      originY: 'center',
      stroke: this.highlight ? '#ffffff' : gradientColor,
    });
    const colorStops = this.highlight
      ? [
          { offset: 0, color: '#ffffff' },
          { offset: 0.4, color: gradientColor },
          { offset: 1, color: '#000000' },
        ]
      : [
          { offset: 0, color: '#000000' },
          { offset: 0.2, color: gradientColor },
          { offset: 0.4, color: gradientColor },
          { offset: 1, color: '#000000' },
        ];
    base.set(
      'stroke',
      new fabric.Gradient({
        type: 'radial',
        coords: {
          x1: borderCenter,
          y1: borderCenter,
          x2: borderCenter,
          y2: borderCenter,
          r1: this.radius - this.borderWidth,
          r2: this.radius + 4,
        },
        colorStops,
      })
    );
    return base;
  }
  loadImage = (url) => {
    if (!url) {
      return Promise.resolve(new fabric.Image());
    }
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        url,
        (image, err) => {
          if (err) {
            reject(err);
          } else {
            resolve(image);
          }
        },
        { crossOrigin: 'anonymous' }
      );
    });
  };
  loadEffect = (effect) => {
    if (effect.icon) {
      return this.loadImage(effect.icon)
        .then((image) => ({ ...effect, image }))
        .catch(() => effect);
    }
    return effect;
  };
  avatar() {
    return this.loadImage(this.props.imageUrl).then((image) => {
      image.set({ top: this.borderWidth, left: this.borderWidth });
      image.scaleX = this.imageWidth / image.width;
      image.scaleY = this.imageHeight / image.height;
      if (this.shape !== 'rect') {
        image.set(
          'clipPath',
          new fabric.Circle({
            radius: image.width / 2,
            top: 0,
            left: 0,
            originX: 'center',
            originY: 'center',
          })
        );
      }
      return image;
    });
  }
  tint() {
    const borderCenter = this.radius - this.borderWidth / 2;
    if (!this.props.tint || this.shape === 'rect') return [];
    return [
      new fabric.Circle({
        top: this.center,
        left: this.center,
        radius: borderCenter,
        fill: this.props.tint,
        originX: 'center',
        originY: 'center',
        opacity: 0.3,
      }),
    ];
  }
  resources() {
    const results = [];
    const size = Math.min(this.width, this.height);
    const radius = size / 12;
    const s = this.props.shape === 'rect';
    const resources = [
      {
        attr: 'resource1',
        color: '#f44336',
        top: s ? radius + 10 : (this.width * 1.9) / 7,
        left: s ? radius + 10 : (this.width * 1.5) / 6,
      },
      {
        attr: 'resource2',
        color: '#2196f3',
        top: s ? radius + 10 : (this.width * 1.1) / 7,
        left: s ? this.width / 2 : (this.width * 3) / 6,
      },
      {
        attr: 'resource3',
        color: '#4caf50',
        top: s ? radius + 10 : (this.width * 1.9) / 7,
        left: s ? this.width - radius - 10 : (this.width * 4.5) / 6,
      },
    ];
    resources.forEach((resource) => {
      const resourceValue = this.props[resource.attr];
      if (resourceValue) {
        results.push(
          new fabric.Circle({
            top: resource.top,
            left: resource.left,
            radius: radius,
            originX: 'center',
            originY: 'center',
            fill: resource.color,
            strokeWiddth: 0,
            opacity: 0.8,
          })
        );
        results.push(
          new fabric.Text(`${resourceValue}`, {
            top: resource.top,
            left: resource.left,
            fontSize: 1.5 * radius,
            fill: '#fff',
            originX: 'center',
            originY: 'center',
            angle: this.props.shape === 'rect' ? -this.props.facing : 0,
          })
        );
      }
    });
    return results;
  }
  icons() {
    const effectsCopy = this.props.effects || [];
    const labels = effectsCopy.map((effect) => effect.text);
    const effectDedup = effectsCopy.filter(
      (effect, i) => !effect.text || labels.indexOf(effect.text) >= i
    );
    return Promise.all(effectDedup.map(this.loadEffect)).then((effects) => {
      const results = [];

      let leftPositions, iconScale;
      if (effects.length === 1) {
        leftPositions = [1 / 2];
        iconScale = 3;
      } else if (effects.length === 2) {
        leftPositions = [5 / 16, 11 / 16];
        iconScale = 3;
      } else if (effects.length === 3) {
        leftPositions = [4 / 16, 8 / 16, 12 / 16];
        iconScale = 4;
      } else {
        leftPositions = [1 / 2, 2 / 6, 4 / 6, 1 / 6, 5 / 6];
        iconScale = 6;
      }
      const iconWidth = this.width / iconScale - 2;
      effects.forEach((effect, i) => {
        const count = labels.filter(
          (label) => effect.text && label === effect.text
        ).length;
        let element = new fabric.Circle({
          fill: effect.color || 'transparent',
          strokeWidth: 0,
          radius: this.width / 2,
        });
        let top = (this.width * 2) / 3;
        if (i >= leftPositions.length) {
          top = (this.width * 5) / 6;
        }
        element.set({
          top: top,
          left: this.width * leftPositions[i % leftPositions.length],
          originX: 'center',
          originY: 'center',
          opacity: 0.8,
        });
        element.scaleToWidth(iconWidth);
        results.push(element);

        if (effect.image) {
          let element = effect.image;

          const imageWidth = effect.color ? iconWidth * 0.8 : iconWidth;
          element.set({
            top: top,
            left: this.width * leftPositions[i % leftPositions.length],
            originX: 'center',
            originY: 'center',
            opacity: 0.8,
            clipPath: new fabric.Circle({
              radius: element.width / 2,
              originX: 'center',
              originY: 'center',
            }),
          });
          element.scaleToWidth(imageWidth);
          results.push(element);
        } else {
          let labelText =
            effect.labelText ||
            effect.text
              .split(/\s/)
              .reduce(
                (response, word) =>
                  (response +=
                    word.indexOf('#') === 0 ? word : word.slice(0, 1)),
                ''
              );
          const label = new fabric.Text(labelText);
          label.set({
            top: top,
            left: this.width * leftPositions[i % leftPositions.length],
            originX: 'center',
            originY: 'center',
            stroke: '#ffffff',
            fill: '#ffffff',
          });
          adjustFontSize(label, {
            width: (iconWidth * 7) / 8,
            height: (iconWidth * 7) / 8,
          });
          results.push(label);
        }
        if (count > 1) {
          const countLabel = new fabric.Text(`×${count}`);
          countLabel.set({
            top: top,
            left: this.width * leftPositions[i % leftPositions.length],
            originX: 'center',
            originY: 'center',
            stroke: '#ffffff',
            fill: '#ffffff',
            textBackgroundColor: 'rgba(0,0,0,0.1)',
          });
          adjustFontSize(countLabel, {
            width: (iconWidth * 7) / 8,
            height: (iconWidth * 7) / 8,
          });
          results.push(countLabel);
        }
      });
      return results;
    });
  }
  background() {
    return new fabric.Circle({
      radius: this.radius,
      fill: '#424242',
      stroke: 0,
    });
  }
  labelText() {
    const { conditions, imageUrl, label } = this.props;
    let lines = [];
    if (!imageUrl || !imageUrl.length) {
      lines = lines.concat(label.split(/ +/g));
    }
    if (conditions && conditions.length) {
      lines = lines.concat(conditions);
    }
    return lines.join('\n');
  }
  label() {
    const { imageUrl } = this.props;
    const maxSize = this.width;
    const hasImage = imageUrl && imageUrl.length;
    const top = hasImage ? (2 * this.width) / 3 : this.width / 2;
    const height = hasImage ? maxSize * 0.3 : maxSize * 0.8;

    const label = new fabric.Text(this.labelText(), {
      top,
      left: this.width / 2,
      textAlign: 'center',
      fill: 'white',
      originX: 'center',
      originY: 'center',
      fontFamily: 'Roboto',
      opacity: hasImage ? 0.6 : 1,
    });
    adjustFontSize(label, { height, width: maxSize * 0.8 });
    return label;
  }
  toImage() {
    if (this.shape !== 'rect') {
      this.canvas.add(this.background());
    }
    this.canvas.add(this.base());
    return Promise.all([this.avatar(), this.icons()])
      .then(([avatar, icons]) => {
        this.canvas.add(avatar);
        this.canvas.add(...this.tint());
        this.canvas.add(this.label());
        this.canvas.add(...icons);
        this.canvas.add(...this.resources());
      })
      .then(() => {
        const data = this.canvas.toDataURL();
        this.canvas.clear();
        this.canvas.dispose();
        return this.loadImage(data);
      });
  }
}
