import React, { Component } from 'react';

import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

import MenuIcon from '@mui/icons-material/Menu';

import ClockHeader from './clock/ClockHeader';

class GameBar extends Component {
  render() {
    const { children, color, extensions, game, log } = this.props;
    const { CustomHeader } = extensions;
    return (
      <AppBar
        position="fixed"
        style={{
          backgroundColor: color,
          zIndex: 1200 + 1,
          paddingTop: 'env(safe-area-inset-top)',
        }}
      >
        <Toolbar>
          <IconButton
            style={{ marginLeft: -12, marginRight: 20 }}
            color="inherit"
            aria-label="Menu"
            onClick={this.props.toggleActionDrawer}
          >
            <MenuIcon />
          </IconButton>
          {CustomHeader && <CustomHeader game={game} log={log} />}
          {children}
          <ClockHeader game={game} log={log} />
        </Toolbar>
      </AppBar>
    );
  }
}

export default GameBar;
