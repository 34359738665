import React, { Component } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import Dialog from '../WTDialog';
import Widgets from './Widgets';

export default class AddWidgetDialog extends Component {
  addWidget = (type, attrs) => {
    this.props.onClose();
    this.props.onAddWidget(type, attrs);
  };
  render() {
    const { moduleData, onClose, open } = this.props;
    const widgets = [...(moduleData.widgets || []), ...Widgets];
    return (
      <Dialog open={open} maxWidth="md" onClose={onClose}>
        <DialogTitle>Add Widget</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="textSecondary">
            Note: adding a widget while a model is selected will center the
            widget on that model
          </Typography>
          <List>
            {widgets.map((widget, i) => (
              <ListItem
                key={`widget${i}`}
                button
                onClick={() => {
                  this.addWidget(widget.type, widget.attrs);
                }}
              >
                <ListItemText primary={widget.label} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  }
}
