import React, { Component } from 'react';

import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

import CloseIcon from '@mui/icons-material/Close';

class LogSnackbar extends Component {
  queue = [];
  startAt = new Date().getTime();
  state = {
    log: null,
    open: false,
  };

  componentDidMount() {
    this.props.connection.onAdd('log', this.onAddLog);
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  };

  onAddLog = (log) => {
    if (!log.attrs.createdAt || log.attrs.createdAt < this.startAt) {
      return;
    }
    this.setState({ log, open: true });
  };

  render() {
    const { log } = this.state;
    let message = '';
    if (log) {
      message = log.attrs.text;
    }
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
      >
        <SnackbarContent
          style={{
            marginBottom: 'env(safe-area-inset-bottom)',
            flexWrap: 'nowrap',
          }}
          message={message}
          action={
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon style={{ fontSize: 20 }} />
            </IconButton>
          }
        />
      </Snackbar>
    );
  }
}

export default LogSnackbar;
