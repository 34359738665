import Cards from '../data/cards';

export default function textToCards(text) {
  var lines = text.split(/\n/).filter((line) => line.trim().length > 0);

  var separator = lines.findIndex((line) => line.includes('COMMAND CARD'));
  var cardLines = lines.slice(separator + 1);
  return cardLines
    .map((cardLabel) => {
      return Cards.find((card) => cardLabel.includes(card.name));
    })
    .filter((card) => card);
}
