export default function adjustFontSize(
  textObject,
  { height = textObject.height, width = textObject.width }
) {
  textObject.set('fontSize', 100);
  while (textObject.height > height || textObject.width > width) {
    textObject.set('fontSize', textObject.fontSize - 1);
    if (textObject.fontSize < 5) {
      return;
    }
  }
}
