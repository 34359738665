import settings from '../data/settings';

export default function setup(connection) {
  connection.add('deck', {
    name: settings.gameSettings.player1StackLabel,
    x: -2.5,
    y: 44.75,
    color: settings.gameSettings.player1Color,
    cannotBeMerged: true,
    cards: [],
    discard: [],
  });

  connection.add('deck', {
    name: settings.gameSettings.player2StackLabel,
    x: -2.5,
    y: 3.25,
    color: settings.gameSettings.player2Color,
    cannotBeMerged: true,
    cards: [],
    discard: [],
  });
}
