import { fabric } from 'fabric-with-gestures';
import FabricComponent from './FabricComponent';
import { LAYER_PING } from './Layers';

export default class Ping extends FabricComponent {
  createFabricObject() {
    const { canvas, record, pixelsPerInch } = this.props;
    const { x, y } = record.attrs;
    const circle = new fabric.Circle({
      radius: 0,
      strokeWidth: 0.2 * pixelsPerInch,
      stroke: '#fff',
      originX: 'center',
      originY: 'center',
      evented: false,
      selectable: false,
      opacity: 0.2,
      layer: LAYER_PING,
      left: x * pixelsPerInch,
      top: y * pixelsPerInch,
      fill: '#2196f3',
      record,
    });
    circle.animate('radius', 2 * pixelsPerInch, {
      duration: 1000,
      onChange: () => canvas.renderAll(),
      onComplete: () => record.remove(),
      easing: fabric.util.ease.easeInQuad,
    });
    return circle;
  }
  fabricObjectAttrs(attrs, pixelsPerInch) {
    return {};
  }
}
