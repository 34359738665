import { convertToLabel } from '../AddEffectDialog';

function addEffect(tokens, effect, log) {
  tokens.forEach((token) => {
    const effects = [...(token.attrs.effects || [])];
    effects.push({
      ...effect,
    });
    token.update({ effects });
  });
  log(
    `Added ${effect.text} to ${tokens.map((token) => token.attrs.label).join(', ')}`
  );
}

export function addEffects({ moduleData, log, selections }) {
  const tokens = selections.filter((selection) => selection.type === 'token');
  if (!tokens.length) return [];

  const results = [];
  (moduleData.effects || []).forEach((section) => {
    section.values.forEach((effect) => {
      results.push({
        label: `Add "${effect.text}" Effect`,
        avatar: effect.icon,
        color: effect.color,
        run: () => addEffect(tokens, effect, log),
      });
    });
  });
  return results;
}

export function customEffect({ log, selections, text }) {
  const tokens = selections.filter((selection) => selection.type === 'token');
  if (!tokens.length) return [];
  const results = [];
  const effectName = text.replace(/effect/i, '').trim();
  if (effectName.length > 0) {
    const labelText = convertToLabel(effectName);
    results.push({
      label: `Add "${effectName}" Effect`,
      iconText: labelText,
      color: '#9e9e9e',
      run: () =>
        addEffect(
          tokens,
          { color: '#9e9e9e', text: effectName, labelText },
          log
        ),
    });
  }
  return results;
}

export function removeEffects({ log, selections }) {
  const tokens = selections.filter((selection) => selection.type === 'token');
  if (!tokens.length) return [];
  const effects = {};
  tokens.forEach((selection) => {
    if (selection.attrs.effects) {
      selection.attrs.effects.forEach(
        (effect) => (effects[effect.text] = effect)
      );
    }
  });
  return Object.values(effects).map((effect) => ({
    label: `Remove "${effect.text}" Effect`,
    avatar: effect.icon,
    color: effect.color,
    iconText: effect.labelText,
    run() {
      tokens.forEach((token) => {
        const effects = [...(token.attrs.effects || [])];
        const index = effects.findIndex((e) => e.text === effect.text);
        if (index >= 0) {
          effects.splice(index, 1);
          token.update({ effects });
        }
      });
      log(
        `Removed ${effect.text} from ${tokens.map((token) => token.attrs.label).join(', ')}`
      );
    },
  }));
}
