import React, { Component, Fragment } from 'react';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import withWidth, { isWidthDown } from './withWidth';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Dialog from './WTDialog';

class ListDetailDialog extends Component {
  state = {
    selected: null,
  };
  unselect = () => this.setState({ selected: null });
  render() {
    const {
      defaultDetail,
      detailTitle,
      itemCategories,
      onClose,
      renderDetail,
      renderItem,
      selectFirst,
      title,
      width,
    } = this.props;
    let { selected } = this.state;
    const isMobile = isWidthDown('xs', width);
    if (selectFirst && !isMobile && !selected) {
      selected = Object.values(itemCategories)[0][0];
    }
    const showLeftPane = !isMobile || !selected;
    return (
      <Dialog open fullScreen maxWidth="md" onClose={onClose}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100vh',
          }}
        >
          <DialogTitle>
            {showLeftPane ? (
              title
            ) : (
              <Fragment>
                <IconButton onClick={this.unselect}>
                  <ArrowBackIcon />
                </IconButton>
                {detailTitle(selected)}
              </Fragment>
            )}
          </DialogTitle>
          <DialogContent style={{ flexGrow: 1, padding: 0, height: '100%' }}>
            <Grid container spacing={2} style={{ height: '100%' }}>
              {showLeftPane && (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{
                    height: '100%',
                    overflow: 'auto',
                    overflowScrolling: 'touch',
                    WebkitOverflowScrolling: 'touch',
                    paddingTop: 0,
                  }}
                >
                  <List>
                    {Object.keys(itemCategories).map((category) => (
                      <Fragment key={category}>
                        <ListSubheader style={{ backgroundColor: '#fff' }}>
                          {category}
                        </ListSubheader>
                        {itemCategories[category].map((item, i) => (
                          <ListItem
                            button
                            key={`item${i}`}
                            selected={item === selected}
                            onClick={() => this.setState({ selected: item })}
                          >
                            {renderItem(item)}
                          </ListItem>
                        ))}
                      </Fragment>
                    ))}
                  </List>
                </Grid>
              )}
              <Grid item xs={12} sm={8} style={{ padding: '12px 32px' }}>
                {selected && showLeftPane && (
                  <Typography variant="h6" gutterBottom>
                    {detailTitle(selected)}
                  </Typography>
                )}
                {selected && renderDetail(selected, this.unselect)}
                {!selected && defaultDetail}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

export default withWidth()(ListDetailDialog);
