import { fabric } from 'fabric-with-gestures';
import BaseWidget from './BaseWidget';

export default class Ruler extends BaseWidget {
  objects() {
    const lines = [];
    const base = this.props.record.attrs.rotateRadius || 0;
    const maxLength = 14;
    lines.push(
      new fabric.Line(
        [
          0,
          this.inchesToPixels(maxLength),
          0,
          this.inchesToPixels(maxLength + base),
        ],
        {
          stroke: 'rgba(0,0,0,0)',
        }
      )
    );
    lines.push(
      new fabric.Line(
        [this.inchesToPixels(-0.75), 0, this.inchesToPixels(0.75), 0],
        {
          stroke: 'rgba(0,0,0,0)',
        }
      )
    );
    lines.push(
      new fabric.Line([0, 0, 0, this.inchesToPixels(maxLength)], {
        stroke: '#fff',
      })
    );
    lines.push(
      new fabric.Line(
        [
          this.inchesToPixels(-0.25),
          this.inchesToPixels(14),
          this.inchesToPixels(0.25),
          this.inchesToPixels(14),
        ],
        {
          stroke: '#fff',
        }
      )
    );
    for (var i = 0; i < maxLength; i++) {
      const tick = new fabric.Line(
        [
          this.inchesToPixels(-0.25),
          this.inchesToPixels(i),
          this.inchesToPixels(0.25),
          this.inchesToPixels(i),
        ],
        {
          stroke: '#fff',
        }
      );
      lines.push(tick);
      lines.push(
        new fabric.Text(`${maxLength - i}”`, {
          left: this.inchesToPixels(0.5),
          top: this.inchesToPixels(i),
          strokeWidth: 1,
          stroke: '#fff',
          fill: '#fff',
          fontSize: this.inchesToPixels(0.3),
          originX: 'center',
          originY: 'center',
        })
      );
    }

    return lines;
  }
  fabricObjectAttrs(attrs, pixelsPerInch) {
    const objectAttrs = super.fabricObjectAttrs(attrs, pixelsPerInch);
    return {
      ...objectAttrs,
      centeredRotation: false,
      originX: 'center',
      originY: 'bottom',
    };
  }
  canRotate() {
    return true;
  }
}
