export default [
  {
    title: 'Common Effects',
    values: [
      { color: '#e91e63', text: 'Leader', icon: '/emoji/star.png' },
      { color: '#ffffff', text: 'Corrosion', icon: '/emoji/test_tube.png' },
      { color: '#ffffff', text: 'Disruption', icon: '/emoji/zap.png' },
      { color: '#ffffff', text: 'Fire', icon: '/emoji/fire.png' },
      { color: '#ffffff', text: 'Knocked Down', icon: '/emoji/arrow_down.png' },
      { color: '#00bcd4', text: 'Stationary', icon: '/emoji/snowflake.png' },
    ],
  },
  {
    title: 'Effects',
    values: [
      {
        text: 'Paralysis',
        color: '#607d8b',
        icon: '/emoji/nauseated_face.png',
      },
      {
        text: 'Blind',
        color: '#607d8b',
        icon: '/emoji/see_no_evil.png',
      },
      {
        text: 'Mini Feat Used',
        color: '#9e9e9e',
        icon: '/emoji/white_check_mark.png',
      },
      {
        text: 'Shield Guard',
        color: '#ff5722',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Artillerist',
        color: '#f44336',
        icon: '/emoji/bomb.png',
      },
      {
        text: 'Battle Plan',
        color: '#ff5722',
        icon: '/emoji/spiral_note_pad.png',
      },
      {
        text: 'Battle Driven',
        color: '#f44336',
        icon: '/emoji/crossed_swords.png',
      },
      {
        text: 'Warp Strength',
        color: '#4caf50',
        icon: '/emoji/muscle.png',
      },
      {
        text: 'Anchor',
        color: '#9e9e9e',
        icon: '/emoji/anchor.png',
      },
      {
        text: 'Countercharge',
        color: '#f44336',
        icon: '/emoji/recycle.png',
      },
      {
        text: 'Craft Talisman',
        color: '#9c27b0',
        icon: '/emoji/medal.png',
      },
      {
        text: 'Curse',
        color: '#4caf50',
        icon: '/emoji/face_with_symbols_on_mouth.png',
      },
      {
        text: 'Defensive Strike',
        color: '#f44336',
        icon: '/emoji/crossed_swords.png',
      },
      {
        text: 'Desperate Pace',
        color: '#f44336',
        icon: '/emoji/foot.png',
      },
      {
        text: 'Discombobulate',
        color: '#9c27b0',
        icon: '/emoji/dizzy_face.png',
      },
      {
        text: 'Dig In',
        color: '#795548',
        icon: '/emoji/pick.png',
      },
      {
        text: 'Enrage',
        color: '#f44336',
        icon: '/emoji/pouting_cat.png',
      },
      {
        text: 'Exhaust Fumes',
        color: '#607d8b',
        icon: '/emoji/cloud.png',
      },
      {
        text: 'Eyeless Sight',
        color: '#9e9e9e',
        icon: '/emoji/see_no_evil.png',
      },
      {
        text: 'Feat',
        color: '#673ab7',
        icon: '/emoji/boom.png',
      },
      {
        text: 'Fell Calls',
        color: '#2196f3',
        icon: '/emoji/loudspeaker.png',
      },
      {
        text: 'Fleet',
        color: '#4caf50',
        icon: '/emoji/foot.png',
      },
      {
        text: 'Good Fortune',
        color: '#9e9e9e',
        icon: '/emoji/crossed_fingers.png',
      },
      {
        text: 'Grip of Shadows',
        color: '#9e9e9e',
        icon: '/emoji/bust_in_silhouette.png',
      },
      {
        text: 'Grievous Wounds',
        color: '#f44336',
        icon: '/emoji/face_with_head_bandage.png',
      },
      {
        text: 'Hand Of Vengeance',
        color: '#f44336',
        icon: '/emoji/hand.png',
      },
      {
        text: 'Imprint',
        color: '#2196f3',
        icon: '/emoji/feet.png',
      },
      {
        text: 'Incorporeal',
        color: '#4caf50',
        icon: '/emoji/ghost.png',
      },
      {
        text: 'Inert',
        color: '#00bcd4',
        icon: '/emoji/robot_face.png',
      },
      {
        text: 'Magical Weapons',
        color: '#9e9e9e',
        icon: '/emoji/sparkles.png',
      },
      {
        text: 'Prey',
        color: '#f44336',
        icon: '/emoji/dart.png',
      },
      {
        text: 'Rangefinder',
        color: '#9e9e9e',
        icon: '/emoji/see_no_evil.png',
      },
      {
        text: 'Retaliatory Strike',
        color: '#f44336',
        icon: '/emoji/crossed_swords.png',
      },
      {
        text: 'Righteous Fury',
        color: '#ff5722',
        icon: '/emoji/rage.png',
      },
      {
        text: 'Righteous Vengeance',
        color: '#ff5722',
        icon: '/emoji/imp.png',
      },
      {
        text: 'Silencer',
        color: '#607d8b',
        icon: '/emoji/zipper_mouth_face.png',
      },
      {
        text: 'Standard Bearer',
        color: '#9e9e9e',
        icon: '/emoji/triangular_flag_on_post.png',
      },
      {
        text: 'Swift Vengeance',
        color: '#2196f3',
        icon: '/emoji/imp.png',
      },
      {
        text: 'Vengeance',
        color: '#673ab7',
        icon: '/emoji/imp.png',
      },
      {
        text: 'Fueled Up',
        color: '#9e9e9e',
        icon: '/emoji/fuelpump.png',
      },
      {
        text: 'Guidance',
        color: '#2196f3',
        icon: '/emoji/dart.png',
      },
      {
        text: 'Hymn of Battle',
        color: '#3f51b5',
        icon: '/emoji/crossed_swords.png',
      },
      {
        text: 'Hymn of Passage',
        color: '#3f51b5',
        icon: '/emoji/globe_with_meridians.png',
      },
      {
        text: 'Hymn of Shielding',
        color: '#3f51b5',
        icon: '/emoji/shield.png',
      },
      {
        text: "Hunter's Mark",
        color: '#f44336',
        icon: '/emoji/dart.png',
      },
      {
        text: 'Ice Cage',
        color: '#2196f3',
        icon: '/emoji/cold_face.png',
      },
      {
        text: 'Pathfinder',
        color: '#9e9e9e',
        icon: '/emoji/feet.png',
      },
      {
        text: 'Puppet Master',
        color: '#9c27b0',
        icon: '/emoji/lying_face.png',
      },
      {
        text: 'Shadowbind',
        color: '#9c27b0',
        icon: '/emoji/bust_in_silhouette.png',
      },
      {
        text: 'Sigil Of Power',
        color: '#ff5722',
        icon: '/emoji/radioactive_sign.png',
      },
      {
        text: 'Superior Ammunition',
        color: '#9e9e9e',
        icon: '/emoji/rocket.png',
      },
      {
        text: 'Whispers at the Gate',
        color: '#9e9e9e',
        icon: '/emoji/speak_no_evil.png',
      },
      {
        text: 'Wild',
        color: '#4caf50',
        icon: '/emoji/pouting_cat.png',
      },
    ],
  },
  {
    title: 'Spells',
    values: [
      {
        text: 'Admonition',
        color: '#4caf50',
        icon: '/emoji/man_dancing.png',
      },
      {
        text: 'Arcane Shield',
        color: '#2196f3',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Assail',
        color: '#f44336',
        icon: '/emoji/gun.png',
      },
      {
        text: 'Backlash',
        color: '#2196f3',
        icon: '/emoji/zap.png',
      },
      {
        text: 'Banishing Ward',
        color: '#2196f3',
        icon: '/emoji/hand.png',
      },
      {
        text: 'Battle Charged',
        color: '#f44336',
        icon: '/emoji/zap.png',
      },
      {
        text: 'Battle Lust',
        color: '#f44336',
        icon: '/emoji/pouting_cat.png',
      },
      {
        text: 'Black Spot',
        color: '#607d8b',
        icon: '/emoji/black_circle.png',
      },
      {
        text: 'Blur',
        color: '#ffffff',
        icon: '/emoji/wave.png',
      },
      {
        text: 'Boundless Charge',
        color: '#4caf50',
        icon: '/emoji/foot.png',
      },
      {
        text: 'Bullet Dodger',
        color: '#4caf50',
        icon: '/emoji/man_dancing.png',
      },
      {
        text: 'Calamity',
        color: '#f44336',
        icon: '/emoji/comet.png',
      },
      {
        text: 'Carnage',
        color: '#f44336',
        icon: '/emoji/drop_of_blood.png',
      },
      {
        text: 'Curse Of Shadows',
        color: '#4caf50',
        icon: '/emoji/bust_in_silhouette.png',
      },
      {
        text: 'Dash',
        color: '#4caf50',
        icon: '/emoji/foot.png',
      },
      {
        text: 'Deadeye',
        color: '#f44336',
        icon: '/emoji/eye.png',
      },
      {
        text: 'Death March',
        color: '#f44336',
        icon: '/emoji/skull.png',
      },
      {
        text: 'Deceleration',
        color: '#2196f3',
        icon: '/emoji/turtle.png',
      },
      {
        text: "Defender's Ward",
        color: '#ff5722',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Engine Of Destruction',
        color: '#f44336',
        icon: '/emoji/boom.png',
      },
      {
        text: 'Fire For Effect',
        color: '#ff5722',
        icon: '/emoji/firecracker.png',
      },
      {
        text: 'Fog Of War',
        color: '#607d8b',
        icon: '/emoji/cloud.png',
      },
      {
        text: 'Fortune',
        color: '#ff5722',
        icon: '/emoji/crossed_fingers.png',
      },
      {
        text: 'Fury',
        color: '#f44336',
        icon: '/emoji/pouting_cat.png',
      },
      {
        text: 'Ghost Walk',
        color: '#4caf50',
        icon: '/emoji/ghost.png',
      },
      {
        text: 'Guided Fire',
        color: '#2196f3',
        icon: '/emoji/rocket.png',
      },
      {
        text: 'Hallowed Avenger',
        color: '#ff5722',
        icon: '/emoji/crossed_swords.png',
      },
      {
        text: 'Hand Of Fate',
        color: '#ff5722',
        icon: '/emoji/game_die.png',
      },
      {
        text: 'Ignite',
        color: '#f44336',
        icon: '/emoji/boom.png',
      },
      {
        text: 'Incite',
        color: '#f44336',
        icon: '/emoji/face_with_symbols_on_mouth.png',
      },
      {
        text: 'Infernal Machine',
        color: '#4caf50',
        icon: '/emoji/smiling_imp.png',
      },
      {
        text: 'Inviolable Resolve',
        color: '#2196f3',
        icon: '/emoji/grimacing.png',
      },
      {
        text: 'Iron Flesh',
        color: '#607d8b',
        icon: '/emoji/magnet.png',
      },
      {
        text: 'Lamentation',
        color: '#2196f3',
        icon: '/emoji/sob.png',
      },
      {
        text: 'Lightning Shroud',
        color: '#ff5722',
        icon: '/emoji/zap.png',
      },
      {
        text: 'Marked For Death',
        color: '#ff5722',
        icon: '/emoji/skull.png',
      },
      {
        text: 'Mirage',
        color: '#2196f3',
        icon: '/emoji/palm_tree.png',
      },
      {
        text: 'Mobility',
        color: '#4caf50',
        icon: '/emoji/foot.png',
      },
      {
        text: 'Mortality',
        color: '#4caf50',
        icon: '/emoji/dizzy_face.png',
      },
      {
        text: 'Occultation',
        color: '#607d8b',
        icon: '/emoji/see_no_evil.png',
      },
      {
        text: 'Parasite',
        color: '#ffffff',
        icon: '/emoji/microbe.png',
      },
      {
        text: 'Polarity Shield',
        color: '#ff5722',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Positive Charge',
        color: '#ff5722',
        icon: '/emoji/zap.png',
      },
      {
        text: 'Pursuit',
        color: '#ff5722',
        icon: '/emoji/foot.png',
      },
      {
        text: 'Quicken',
        color: '#2196f3',
        icon: '/emoji/foot.png',
      },
      {
        text: 'Rebuke',
        color: '#f44336',
        icon: '/emoji/hand.png',
      },
      {
        text: 'Refuge',
        color: '#4caf50',
        icon: '/emoji/house.png',
      },
      {
        text: 'Road To War',
        color: '#795548',
        icon: '/emoji/crossed_swords.png',
      },
      {
        text: 'Scything Touch',
        color: '#4caf50',
        icon: '/emoji/point_up.png',
      },
      {
        text: 'Snipe',
        color: '#2196f3',
        icon: '/emoji/gun.png',
      },
      {
        text: 'Solid Ground',
        color: '#607d8b',
        icon: '/emoji/mountain.png',
      },
      {
        text: 'Spellpiercer',
        color: '#ff5722',
        icon: '/emoji/sparkles.png',
      },
      {
        text: 'Stranglehold',
        color: '#f44336',
        icon: '/emoji/scream.png',
      },
      {
        text: 'Synergy',
        color: '#9c27b0',
        icon: '/emoji/sparkling_heart.png',
      },
      {
        text: 'Tactical Supremacy',
        color: '#607d8b',
        icon: '/emoji/bomb.png',
      },
      {
        text: 'Watcher',
        color: '#ff5722',
        icon: '/emoji/eyes.png',
      },
      {
        text: 'Warpath',
        color: '#f44336',
        icon: '/emoji/crossed_swords.png',
      },
      {
        text: 'Wild Aggression',
        color: '#f44336',
        icon: '/emoji/pouting_cat.png',
      },
      {
        text: 'Weald Secrets',
        color: '#4caf50',
        icon: '/emoji/speak_no_evil.png',
      },
      {
        text: 'Wind Wall',
        color: '#2196f3',
        icon: '/emoji/wind_blowing_face.png',
      },
    ],
  },
  {
    title: 'Animi',
    values: [
      {
        text: 'Counterblast',
        color: '#f44336',
        icon: '/emoji/bomb.png',
      },
      {
        text: 'Elusive',
        color: '#2196f3',
        icon: '/emoji/wave.png',
      },
      {
        text: 'Lightning Strike',
        color: '#ff5722',
        icon: '/emoji/zap.png',
      },
      {
        text: 'Primal',
        color: '#4caf50',
        icon: '/emoji/pouting_cat.png',
      },
      {
        text: 'Rage',
        color: '#f44336',
        icon: '/emoji/rage.png',
      },
      {
        text: 'Rush',
        color: '#2196f3',
        icon: '/emoji/foot.png',
      },
      {
        text: 'Spiny Growth',
        color: '#4caf50',
        icon: '/emoji/hedgehog.png',
      },
      {
        text: 'Swarm',
        color: '#4caf50',
        icon: '/emoji/bee.png',
      },
      {
        text: 'Wraithbane',
        color: '#ff5722',
        icon: '/emoji/ghost.png',
      },
    ],
  },
  {
    title: 'Warjack Options',
    values: [
      {
        text: 'Head A',
        color: '#673ab7',
        icon: '/emoji/head_a.png',
      },
      {
        text: 'Head B',
        color: '#673ab7',
        icon: '/emoji/head_b.png',
      },
      {
        text: 'Head C',
        color: '#673ab7',
        icon: '/emoji/head_c.png',
      },
      {
        text: 'Head D',
        color: '#673ab7',
        icon: '/emoji/head_d.png',
      },
      {
        text: 'Voltaic Spear',
        color: '#2196f3',
        icon: '/emoji/trident.png',
      },
      {
        text: 'Voltaic Broadsword',
        color: '#2196f3',
        icon: '/emoji/dagger_knife.png',
      },
      {
        text: 'Heavy Stormthrower',
        color: '#2196f3',
        icon: '/emoji/zap.png',
      },
      {
        text: 'Mag-Bolter',
        color: '#2196f3',
        icon: '/emoji/magnet.png',
      },
      {
        text: 'Voltaic Halberd',
        color: '#2196f3',
        icon: '/emoji/trident.png',
      },
      {
        text: 'Voltaic Javelin',
        color: '#2196f3',
        icon: '/emoji/bow_and_arrow.png',
      },
      {
        text: 'Electro Bombard',
        color: '#2196f3',
        icon: '/emoji/bomb.png',
      },
      {
        text: 'Voltaic Hammer',
        color: '#2196f3',
        icon: '/emoji/hammer.png',
      },
      {
        text: 'Hunter Rifle',
        color: '#2196f3',
        icon: '/emoji/gun.png',
      },
      {
        text: 'Pole Arm',
        color: '#2196f3',
        icon: '/emoji/trident.png',
      },
      {
        text: 'Flamethrower',
        color: '#2196f3',
        icon: '/emoji/fire.png',
      },
      {
        text: 'Mace',
        color: '#2196f3',
        icon: '/emoji/hammer.png',
      },
      {
        text: 'Belcher',
        color: '#2196f3',
        icon: '/emoji/fire.png',
      },
      {
        text: 'Harpoon',
        color: '#2196f3',
        icon: '/emoji/trident.png',
      },
      {
        text: 'Shredder',
        color: '#2196f3',
        icon: '/emoji/carpentry_saw.png',
      },
      {
        text: 'Scything Flail',
        color: '#2196f3',
        icon: '/emoji/anchor.png',
      },
      {
        text: 'Heavy Cannon',
        color: '#2196f3',
        icon: '/emoji/bomb.png',
      },
      {
        text: 'Deep Freezer',
        color: '#2196f3',
        icon: '/emoji/snowflake.png',
      },
      {
        text: 'Quad Chain Gun',
        color: '#2196f3',
        icon: '/emoji/gun.png',
      },
      {
        text: 'Battle Mace',
        color: '#2196f3',
        icon: '/emoji/hammer.png',
      },
      {
        text: 'Long Axe',
        color: '#2196f3',
        icon: '/emoji/axe.png',
      },
      {
        text: 'Cannon',
        color: '#2196f3',
        icon: '/emoji/gun.png',
      },
      {
        text: 'Bombard',
        color: '#2196f3',
        icon: '/emoji/bomb.png',
      },
      {
        text: 'Ice Hammer',
        color: '#2196f3',
        icon: '/emoji/hammer.png',
      },
      {
        text: 'Blaster Fist',
        color: '#2196f3',
        icon: '/emoji/gun.png',
      },
      {
        text: 'Chain Blade',
        color: '#2196f3',
        icon: '/emoji/carpentry_saw.png',
      },
      {
        text: 'Halberd',
        color: '#2196f3',
        icon: '/emoji/trident.png',
      },
      {
        text: 'Heavy Blade Fist',
        color: '#2196f3',
        icon: '/emoji/dagger_knife.png',
      },
      {
        text: 'Dawn Blade',
        color: '#2196f3',
        icon: '/emoji/sunrise.png',
      },
      {
        text: 'Force Fist',
        color: '#2196f3',
        icon: '/emoji/facepunch.png',
      },
      {
        text: 'Null Blade',
        color: '#2196f3',
        icon: '/emoji/dagger_knife.png',
      },
      {
        text: 'Right: Open Fist',
        color: '#2196f3',
        icon: '/emoji/fist.png',
      },
      {
        text: 'Dusk Blade',
        color: '#f44336',
        icon: '/emoji/sunrise.png',
      },
      {
        text: 'Force Shield',
        color: '#f44336',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Light Null Blade',
        color: '#f44336',
        icon: '/emoji/dagger_knife.png',
      },
      {
        text: 'Force Cannon',
        color: '#f44336',
        icon: '/emoji/bomb.png',
      },
      {
        text: 'Vortex Cannon',
        color: '#f44336',
        icon: '/emoji/tornado.png',
      },
      {
        text: 'Electrified Shield',
        color: '#f44336',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Battle Shield',
        color: '#f44336',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Force Lash',
        color: '#f44336',
        icon: '/emoji/zap.png',
      },
      {
        text: 'Light Blade Fist',
        color: '#f44336',
        icon: '/emoji/dagger_knife.png',
      },
      {
        text: 'Spear Fist',
        color: '#f44336',
        icon: '/emoji/trident.png',
      },
      {
        text: 'Voltaic Punching Spike',
        color: '#f44336',
        icon: '/emoji/facepunch.png',
      },
      {
        text: 'Electro Grenade Launcher',
        color: '#f44336',
        icon: '/emoji/bomb.png',
      },
      {
        text: 'Rapid Fire Light Mag-Bolter',
        color: '#f44336',
        icon: '/emoji/gun.png',
      },
      {
        text: 'Galvanic Shield',
        color: '#f44336',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Repulsor Blast',
        color: '#f44336',
        icon: '/emoji/facepunch.png',
      },
      {
        text: 'Heavy Mag-Bolter',
        color: '#f44336',
        icon: '/emoji/magnet.png',
      },
      {
        text: 'Stormblazer Cannon',
        color: '#f44336',
        icon: '/emoji/thunder_cloud_and_rain.png',
      },
      {
        text: 'Assault Shield',
        color: '#f44336',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Clawed Fist',
        color: '#f44336',
        icon: '/emoji/facepunch.png',
      },
      {
        text: 'Pneumatic Spike',
        color: '#f44336',
        icon: '/emoji/syringe.png',
      },
      {
        text: 'Heavy Bolt Thrower',
        color: '#f44336',
        icon: '/emoji/nut_and_bolt.png',
      },
      {
        text: 'Cannon Shield',
        color: '#f44336',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Claw',
        color: '#f44336',
        icon: '/emoji/nail_care.png',
      },
      {
        text: 'Quad Bolt Thrower',
        color: '#f44336',
        icon: '/emoji/nut_and_bolt.png',
      },
      {
        text: 'Ripper',
        color: '#f44336',
        icon: '/emoji/carpentry_saw.png',
      },
      {
        text: 'Heavy Cannon Shield',
        color: '#f44336',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Battle Axe',
        color: '#f44336',
        icon: '/emoji/axe.png',
      },
      {
        text: 'Dozer',
        color: '#f44336',
        icon: '/emoji/gun.png',
      },
      {
        text: 'Plow-Shield',
        color: '#f44336',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Scrap Saw',
        color: '#f44336',
        icon: '/emoji/carpentry_saw.png',
      },
      {
        text: 'Heavy Chain Gun',
        color: '#f44336',
        icon: '/emoji/gun.png',
      },
      {
        text: 'Flame Fist',
        color: '#f44336',
        icon: '/emoji/facepunch.png',
      },
      {
        text: 'Left: Open Fist',
        color: '#f44336',
        icon: '/emoji/fist_mirror.png',
      },
      {
        text: 'Flight',
        color: '#673ab7',
        icon: '/emoji/dove_of_peace.png',
      },
      {
        text: 'Force Barrier',
        color: '#673ab7',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Future Sight',
        color: '#673ab7',
        icon: '/emoji/eye.png',
      },
      {
        text: 'Right - Dawn Blade',
        color: '#f44336',
        icon: '/emoji/sunrise.png',
      },
      {
        text: 'Central Head - Diviner',
        color: '#673ab7',
        icon: '/emoji/sparkles.png',
      },
      {
        text: 'Central Head - Flame Spitter',
        color: '#673ab7',
        icon: '/emoji/fire.png',
      },
      {
        text: 'Central Head - Toothy Maw',
        color: '#673ab7',
        icon: '/emoji/tooth.png',
      },
      {
        text: 'Smaller Head - Blockhead',
        color: '#f44336',
        icon: '/emoji/hammer.png',
      },
      {
        text: 'Smaller Head - Flame Sprayer',
        color: '#f44336',
        icon: '/emoji/fire.png',
      },
      {
        text: 'Smaller Head - Gnashing Fangs',
        color: '#f44336',
        icon: '/emoji/tooth.png',
      },
      {
        text: 'Smaller Head - Horned Piercer',
        color: '#f44336',
        icon: '/emoji/crossed_swords.png',
      },
      {
        text: 'Smaller Head - Venom Spitter',
        color: '#f44336',
        icon: '/emoji/skull.png',
      },
      {
        text: 'Dorsal Feature - Bladeback',
        color: '#fbc02d',
        icon: '/emoji/crossed_swords.png',
      },
      {
        text: 'Dorsal Feature - Spineback',
        color: '#fbc02d',
        icon: '/emoji/hedgehog.png',
      },
      {
        text: 'Dorsal Feature - Wings',
        color: '#fbc02d',
        icon: '/emoji/butterfly.png',
      },
      {
        text: 'Head - Asp',
        color: '#673ab7',
        icon: '/emoji/sparkles.png',
      },
      {
        text: 'Head - Ophidian',
        color: '#673ab7',
        icon: '/emoji/athletic_shoe.png',
      },
      {
        text: 'Head - Viper',
        color: '#673ab7',
        icon: '/emoji/ballet_shoes.png',
      },
      {
        text: 'Tail - Bladed Gale',
        color: '#f44336',
        icon: '/emoji/crossed_swords.png',
      },
      {
        text: 'Tail - Chasten',
        color: '#f44336',
        icon: '/emoji/sparkles.png',
      },
      {
        text: 'Tail - Hunter’s Mark',
        color: '#f44336',
        icon: '/emoji/no_entry.png',
      },
      {
        text: 'Arms - Cleavers',
        color: '#fbc02d',
        icon: '/emoji/axe.png',
      },
      {
        text: 'Arms - Impalers',
        color: '#fbc02d',
        icon: '/emoji/pick.png',
      },
      {
        text: 'Head - Bellower',
        color: '#673ab7',
        icon: '/emoji/scream.png',
      },
      {
        text: 'Head - Cantankerous',
        color: '#673ab7',
        icon: '/emoji/imp.png',
      },
      {
        text: 'Head - Deep Dweller',
        color: '#673ab7',
        icon: '/emoji/japanese_goblin.png',
      },
      {
        text: 'Fin - Lunker',
        color: '#f44336',
        icon: '/emoji/fish.png',
      },
      {
        text: 'Fin - Minnow',
        color: '#f44336',
        icon: '/emoji/fish.png',
      },
      {
        text: 'Fin - Spearback',
        color: '#673ab7',
        icon: '/emoji/fish.png',
      },
      {
        text: 'Right Arm - Anchor',
        color: '#f44336',
        icon: '/emoji/anchor.png',
      },
      {
        text: 'Right Arm - Hammerhead',
        color: '#f44336',
        icon: '/emoji/hammer.png',
      },
      {
        text: 'Right Arm - Narwhal Spear Gauntlet',
        color: '#f44336',
        icon: '/emoji/trident.png',
      },
      {
        text: 'Left Arm - Crab Net',
        color: '#009688',
        icon: '/emoji/trident.png',
      },
      {
        text: 'Left Arm - Shark Skull Gauntlet',
        color: '#009688',
        icon: '/emoji/skull.png',
      },
      {
        text: 'Left Arm - Squid Blaster',
        color: '#009688',
        icon: '/emoji/octopus.png',
      },
      {
        text: 'Head - Gunner',
        color: '#673ab7',
        icon: '/emoji/gun.png',
      },
      {
        text: 'Head - Matey',
        color: '#673ab7',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Head - Skipper',
        color: '#673ab7',
        icon: '/emoji/scream.png',
      },
      {
        text: 'Booze - Ale Tanker',
        color: '#f44336',
        icon: '/emoji/beer.png',
      },
      {
        text: 'Booze - Grog Cask',
        color: '#f44336',
        icon: '/emoji/woozy_face.png',
      },
      {
        text: 'Booze - Rum Jug',
        color: '#673ab7',
        icon: '/emoji/test_tube.png',
      },
      {
        text: 'Right Arm - Cutlass',
        color: '#f44336',
        icon: '/emoji/crossed_swords.png',
      },
      {
        text: 'Right Arm - Harpoon',
        color: '#f44336',
        icon: '/emoji/trident.png',
      },
      {
        text: 'Right Arm - Ship’s Gun',
        color: '#f44336',
        icon: '/emoji/gun.png',
      },
      {
        text: 'Left Arm - Boarding Axe',
        color: '#009688',
        icon: '/emoji/axe.png',
      },
      {
        text: 'Left Arm - Chain Shot Explosive',
        color: '#009688',
        icon: '/emoji/bomb.png',
      },
      {
        text: 'Left Arm - Ship’s Wheel',
        color: '#009688',
        icon: '/emoji/ship.png',
      },
      {
        text: 'Head - Fire Support',
        color: '#673ab7',
        icon: '/emoji/bomb.png',
      },
      {
        text: 'Head - Shock Assault',
        color: '#673ab7',
        icon: '/emoji/lightning.png',
      },
      {
        text: 'Right Shoulder - Right Shoulder Mounted Deep Freezer',
        color: '#f44336',
        icon: '/emoji/snowflake.png',
      },
      {
        text: 'Right Shoulder - Right Shoulder Mounted Heavy Cannon',
        color: '#f44336',
        icon: '/emoji/bomb.png',
      },
      {
        text: 'Left Shoulder - Left Shoulder Mounted Deep Freezer',
        color: '#009688',
        icon: '/emoji/snowflake.png',
      },
      {
        text: 'Left Shoulder - Right Shoulder Mounted Heavy Cannon',
        color: '#009688',
        icon: '/emoji/bomb.png',
      },
      {
        text: 'Right Arm - Chain Axe',
        color: '#f44336',
        icon: '/emoji/axe.png',
      },
      {
        text: 'Right Arm - Great Axe',
        color: '#f44336',
        icon: '/emoji/link.png',
      },
      {
        text: 'Left Arm - Dozer Fist',
        color: '#009688',
        icon: '/emoji/truck.png',
      },
      {
        text: 'Left Arm - Grinder Fist',
        color: '#009688',
        icon: '/emoji/gear.png',
      },
      {
        text: 'Head - Frother',
        color: '#673ab7',
        icon: '/emoji/coffee.png',
      },
      {
        text: 'Head - Spider',
        color: '#673ab7',
        icon: '/emoji/spider.png',
      },
      {
        text: 'Right Forearm - Bile Jet',
        color: '#f44336',
        icon: '/emoji/face_vomiting.png',
      },
      {
        text: 'Right Forearm - Infernus Cannon',
        color: '#f44336',
        icon: '/emoji/fire.png',
      },
      {
        text: 'Right Fist - Demolisher Fist',
        color: '#f44336',
        icon: '/emoji/fist.png',
      },
      {
        text: 'Right Arm - Rending Claws',
        color: '#f44336',
        icon: '/emoji/nail_care.png',
      },
      {
        text: 'Left Forearm - Bile Jet',
        color: '#009688',
        icon: '/emoji/face_vomiting.png',
      },
      {
        text: 'Left Forearm - Infernus Cannon',
        color: '#009688',
        icon: '/emoji/fire.png',
      },
      {
        text: 'Left Fist - Demolisher Fist',
        color: '#009688',
        icon: '/emoji/fist.png',
      },
      {
        text: 'Left Fist - Rending Claws',
        color: '#009688',
        icon: '/emoji/nail_care.png',
      },
      {
        text: 'Head - Berserk',
        color: '#673ab7',
        icon: '/emoji/rage.png',
      },
      {
        text: 'Head - Dark Shroud',
        color: '#673ab7',
        icon: '/emoji/new_moon.png',
      },
      {
        text: 'Head - Overtake',
        color: '#673ab7',
        icon: '/emoji/athletic_shoe.png',
      },
      {
        text: 'Head - Soul Claiming',
        color: '#673ab7',
        icon: '/emoji/ghost.png',
      },
      {
        text: 'Right Arm - Fell Axe',
        color: '#f44336',
        icon: '/emoji/axe.png',
      },
      {
        text: 'Right Arm - Harpoon',
        color: '#f44336',
        icon: '/emoji/trident.png',
      },
      {
        text: 'Right Arm - Plague Cannon',
        color: '#f44336',
        icon: '/emoji/face_vomiting.png',
      },
      {
        text: 'Right Arm - Spiker',
        color: '#f44336',
        icon: '/emoji/dagger_knife.png',
      },
      {
        text: 'Left Arm - Helldriver',
        color: '#009688',
        icon: '/emoji/fire.png',
      },
      {
        text: 'Left Arm - Ripping Claw',
        color: '#009688',
        icon: '/emoji/nail_care.png',
      },
      {
        text: 'Left Arm - Spiked Shield',
        color: '#009688',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Left Arm - Vexing Cannon',
        color: '#009688',
        icon: '/emoji/bomb.png',
      },
      {
        text: 'Head - Beaked Maw',
        color: '#673ab7',
        icon: '/emoji/bird.png',
      },
      {
        text: 'Head - Deathripper',
        color: '#673ab7',
        icon: '/emoji/link.png',
      },
      {
        text: 'Head - Doomspitter',
        color: '#673ab7',
        icon: '/emoji/face_vomiting.png',
      },
      {
        text: 'Head - Venom Blaster',
        color: '#673ab7',
        icon: '/emoji/snake.png',
      },
      {
        text: 'Back - Arc Node',
        color: '#673ab7',
        icon: '/emoji/lightning.png',
      },
      {
        text: 'Back - Heavy Armor',
        color: '#009688',
        icon: '/emoji/shield.png',
      },
      {
        text: 'Back - Light Spiker',
        color: '#009688',
        icon: '/emoji/dagger_knife.png',
      },
      {
        text: 'Back - Necrovent',
        color: '#009688',
        icon: '/emoji/radioactive_sign.png',
      },
    ],
  },
  {
    title: 'Troop Options',
    values: [
      {
        text: 'Auto Cannon',
        color: '#673ab7',
        icon: '/emoji/gun.png',
      },
      {
        text: 'Grenade Porter',
        color: '#673ab7',
        icon: '/emoji/bomb.png',
      },
      {
        text: 'Rocketeer',
        color: '#673ab7',
        icon: '/emoji/rocket.png',
      },
    ],
  },
];
