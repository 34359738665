import React, { Component, Fragment } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import NumberInput from '../../../NumberInput';

export function totalFromGrid(damageGrid) {
  return damageGrid.replace(/[a-z*\n-]/g, '').length;
}

function nthIndexOf(string, pattern, n) {
  var i = -1;
  while (n-- && i++ < string.length) {
    i = string.indexOf(pattern, i);
    if (i < 0) break;
  }
  return i;
}

function chooseColumn(prompt) {
  let columnInput = window.prompt(prompt);
  if (columnInput && parseInt(columnInput, 10)) {
    return Math.min(Math.max(0, parseInt(columnInput, 10) - 1), 5);
  }
  return 0;
}

function updateGrid(model, damageGrid) {
  const effects = [...(model.attrs.effects || [])];
  const systemBoxes = damageGrid
    .split('')
    .filter((c) => !['\n', '#', '*'].includes(c))
    .map((v) => v.toUpperCase());
  const systems = [...new Set(systemBoxes)];
  systems.forEach((system) => {
    const effectText = `${system} Out`;
    const effectIndex = effects.findIndex(({ text }) => text === effectText);
    if (damageGrid.indexOf(system) >= 0) {
      if (effectIndex >= 0) {
        effects.splice(effectIndex, 1);
      }
    } else {
      if (effectIndex < 0) {
        if (system === 'F') return; // Force Field doesn't need marker
        let color = '#f44336';
        if (['M'].includes(system)) color = '#2196f3'; // Blue
        if (['S', 'O'].includes(system)) color = '#4caf50'; // Green
        effects.push({ labelText: `-${system}-`, text: effectText, color });
      }
    }
  });
  model.update({ damageGrid, effects, resource1: totalFromGrid(damageGrid) });
}

export function applyGridDamage(model, resource, amount) {
  const { damageGrid } = model.attrs;
  if (!damageGrid || resource !== 'resource1') {
    return model.update({ [resource]: model.attrs[resource] - amount });
  }
  const columns = damageGrid.split('\n');
  let amountLeft = amount;
  let currentColumn = 0;

  if (columns.length >= 6) {
    let side = 'L';
    if (columns.length >= 12) {
      if (
        totalFromGrid(columns.slice(0, 6).join('\n')) > 0 &&
        totalFromGrid(columns.slice(6, 12).join('\n')) > 0
      ) {
        let sideInput = window.prompt(`Which side? (L or R)`);
        if (sideInput === 'R') side = 'R';
      }
    }
    currentColumn =
      chooseColumn('Which column? (blank for 1)') + (side === 'L' ? 0 : 6);
  }

  if (columns.length === 8) {
    //retribution w/ force field
    amountLeft = applyColumnDamage(columns, 6, amountLeft);
    amountLeft = applyColumnDamage(columns, 7, amountLeft);
  }
  if (columns.length === 14) {
    //retribution w/ force field
    amountLeft = applyColumnDamage(columns, 12, amountLeft);
    amountLeft = applyColumnDamage(columns, 13, amountLeft);
  }
  if (columns.length === 9) {
    const warbeastColumns = {
      0: 0,
      1: 2,
      2: 3,
      3: 5,
      4: 6,
      5: 8,
    };
    currentColumn = warbeastColumns[currentColumn];
  }
  let damageColumns = columns.length === 8 ? 6 : columns.length;
  while (amountLeft > 0 && totalFromGrid(columns.join('\n')) > 0) {
    amountLeft = applyColumnDamage(columns, currentColumn, amountLeft);
    if (columns.length === 9) {
      // warbeast damage
      if (currentColumn === 6 || currentColumn === 8) {
        amountLeft = applyColumnDamage(columns, 7, amountLeft);
        currentColumn = currentColumn === 6 ? 8 : 0;
      } else if (currentColumn === 3 || currentColumn === 5) {
        amountLeft = applyColumnDamage(columns, 4, amountLeft);
        currentColumn = currentColumn === 3 ? 5 : 6;
      } else if (currentColumn === 0 || currentColumn === 2) {
        amountLeft = applyColumnDamage(columns, 1, amountLeft);
        currentColumn = currentColumn === 0 ? 2 : 3;
      }
    } else if (columns.length >= 12) {
      if (currentColumn < 6) {
        if (
          totalFromGrid(columns.slice(0, 6).join('\n')) === 0 &&
          totalFromGrid(columns.slice(6, 12).join('\n')) > 0
        ) {
          let secondColumn = chooseColumn(`Column for second side?`);
          currentColumn = 6 + secondColumn;
        } else {
          currentColumn = (currentColumn + 1) % 6;
        }
      } else {
        if (
          totalFromGrid(columns.slice(6, 12).join('\n')) === 0 &&
          totalFromGrid(columns.slice(0, 6).join('\n')) > 0
        ) {
          currentColumn = chooseColumn(`Column for second side?`);
        } else {
          currentColumn = 6 + ((currentColumn + 1) % 6);
        }
      }
    } else {
      currentColumn = (currentColumn + 1) % damageColumns;
    }
  }
  updateGrid(model, columns.join('\n'));
}

function applyColumnDamage(columns, columnNum, amountLeft) {
  const value = columns[columnNum]
    .split('')
    .map((v) => {
      if (
        amountLeft > 0 &&
        v !== '-' &&
        v !== '*' &&
        (v === '#' || v.toUpperCase() === v)
      ) {
        amountLeft = amountLeft - 1;
        if (v === '#') {
          return '*';
        }
        return v.toLowerCase();
      }
      return v;
    })
    .join('');
  columns[columnNum] = value;
  return amountLeft;
}

export default class DamageGrid extends Component {
  toggleDamage(i, j) {
    const { selection } = this.props;
    const { damageGrid } = selection.attrs;
    const position = nthIndexOf(damageGrid, '\n', i) + j + 1;
    let char = damageGrid.charAt(position);
    if (char === '#') {
      char = '*';
    } else if (char === '*') {
      char = '#';
    } else if (char.toLowerCase() === char) {
      char = char.toUpperCase();
    } else {
      char = char.toLowerCase();
    }
    const newDamageGrid =
      damageGrid.substr(0, position) + char + damageGrid.substr(position + 1);
    updateGrid(selection, newDamageGrid);
  }
  damageCell({ char, width, height, i, j }, key) {
    const disabled = char === '-';
    const checked = char.toUpperCase() === char && char !== '*';
    const noText = ['#', '*'].includes(char);
    return (
      <button
        key={key}
        style={{
          backgroundColor: checked ? null : '#f44336',
          opacity: char === '-' ? 0 : 1,
          padding: 0,
          width,
          height,
          borderRadius: 0,
          fontSize: height * 0.75,
        }}
        disabled={disabled}
        onClick={() => {
          this.toggleDamage(i, j);
        }}
      >
        {noText ? <span>&nbsp;</span> : char.toUpperCase()}
      </button>
    );
  }
  columnHeader(columns, i, size) {
    let header;
    if (
      columns.length >= 6 &&
      ((columns.length < 12 && i < 6) || (columns.length >= 12 && i < 12))
    ) {
      header = (i % 6) + 1;
    }
    if (!header) return null;
    return (
      <Typography align="center" style={{ width: size }}>
        {header}
      </Typography>
    );
  }
  renderDamageGrid() {
    const { selection } = this.props;
    const { damageGrid, resource1 } = selection.attrs;
    const columns = damageGrid.split(/\n/);
    const width = columns.length <= 6 || columns.length === 9 ? 2 : 1;
    const size = width === 2 ? 37 : 20;

    const tracks = columns.map((column, i) =>
      column.split('').map((char, j) => ({
        char,
        width: size,
        height: size,
        i,
        j,
      }))
    );
    if (tracks.length === 9) {
      let shared = tracks.splice(7, 1)[0];
      shared.forEach((cell) => (cell.width = size * 2));
      tracks[6] = tracks[6].concat(shared);
      shared.forEach((cell) => {
        tracks[7].push({
          ...cell,
          width: size,
          char: '-',
        });
      });
      shared = tracks.splice(4, 1)[0];
      shared.forEach((cell) => (cell.width = size * 2));
      tracks[3] = tracks[3].concat(shared);
      shared.forEach((cell) => {
        tracks[4].push({
          ...cell,
          width: size,
          char: '-',
        });
      });
      shared = tracks.splice(1, 1)[0];
      shared.forEach((cell) => (cell.width = size * 2));
      tracks[0] = tracks[0].concat(shared);
      shared.forEach((cell) => {
        tracks[1].push({
          ...cell,
          width: size,
          char: '-',
        });
      });
    }
    const longestTrack = Math.max.apply(
      this,
      tracks.map((track) => track.length)
    );
    tracks.forEach((track, i) => {
      while (track.length < longestTrack) {
        track.unshift({ char: '-', width: size, height: size });
      }
    });
    return (
      <Fragment>
        <Typography variant="body2">Boxes Remaining: {resource1}</Typography>
        <Grid container spacing={0} style={{ width: 224 }}>
          {tracks.map((cells, i) => (
            <Grid
              key={`${selection.id}-${i}`}
              style={{ zIndex: 20 - i }}
              item
              xs={width}
            >
              {this.columnHeader(tracks, i, size)}
              {cells.map((cell, j) => this.damageCell(cell, `${i}-${j}`))}
            </Grid>
          ))}
        </Grid>
      </Fragment>
    );
  }
  renderNumberInput() {
    const { game, selection } = this.props;
    return (
      <NumberInput
        fullWidth
        name="resource1"
        min="0"
        label={`${selection.attrs.resource1Label || game.attrs.resource1Label}${selection.attrs.resource1Max ? ` (Max ${selection.attrs.resource1Max})` : ''}`}
        value={selection.attrs.resource1}
        onChange={this.props.onChange}
      />
    );
  }
  render() {
    const { selection } = this.props;
    const { damageGrid } = selection.attrs;
    if (damageGrid) {
      return this.renderDamageGrid();
    } else {
      return this.renderNumberInput();
    }
  }
}
