import React, { Component } from 'react';

import Button from '@mui/material/Button';
import { onAuthStateChanged, signOut } from 'firebase/auth';

import { auth } from '../initFirebase';
import SignInDialog from './SignInDialog';
import SignUpDialog from './SignUpDialog';

export default class SignInLink extends Component {
  state = {};
  componentDidMount() {
    onAuthStateChanged(auth, this.authStateChanged);
  }
  authStateChanged = (user) => {
    this.setState({
      authChecked: true,
      dialog: null,
      user,
    });
  };
  closeDialog = () => {
    this.setState({ dialog: null });
  };
  signIn = () => {
    this.setState({ dialog: 'signIn' });
  };
  signUp = () => {
    this.setState({ dialog: 'signUp' });
  };
  signOut = () => {
    signOut(auth);
  };
  render() {
    const { authChecked, dialog, user } = this.state;
    if (!authChecked) {
      return null;
    }
    return (
      <div style={{ ...this.props.style }}>
        {!user || user.isAnonymous ? (
          <Button onClick={this.signIn}>Sign In</Button>
        ) : (
          <Button onClick={this.signOut}>Sign Out</Button>
        )}
        <SignInDialog
          open={dialog === 'signIn'}
          user={user}
          onSignUp={this.signUp}
          onClose={this.closeDialog}
        />
        <SignUpDialog
          open={dialog === 'signUp'}
          user={user}
          onSignIn={this.signIn}
          onClose={this.closeDialog}
        />
      </div>
    );
  }
}
