import { fabric } from 'fabric-with-gestures';
import BaseShape from './BaseShape';

export default class Image extends BaseShape {
  createFabricObject() {
    fabric.Image.fromURL(this.props.record.attrs.imageUrl, (img) => {
      this.props.canvas.remove(this.fabricObject);
      this.fabricObject = img;
      this.imageHeight = img.height;
      this.imageWidth = img.width;
      this.fabricObject.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false,
        mtr: true,
      });
      img.set(
        this.fabricObjectAttrs(
          this.props.record.attrs,
          this.props.pixelsPerInch
        )
      );
      img.layer = this.layer();
      this.props.canvas.add(img);
      this.updateLabel();
      this.label.bringToFront();
    });
    return new fabric.Object({ layer: this.layer() });
  }
  labelText() {
    return '';
  }
  shapeAttrs(attrs, pixelsPerInch) {
    return {
      originX: 'center',
      originY: 'center',
      left: attrs.x * pixelsPerInch,
      top: attrs.y * pixelsPerInch,
      angle: attrs.facing,
      scaleX: (attrs.width * pixelsPerInch) / this.imageWidth,
      scaleY: (attrs.height * pixelsPerInch) / this.imageHeight,
    };
  }
}
