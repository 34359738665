import React, { Component, Fragment } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import Dialog from '../WTDialog';
import withWidth, { isWidthUp } from '../withWidth';

class SearchDeckDialog extends Component {
  state = {
    selected: [],
    text: '',
  };
  select(i) {
    const { selected } = this.state;
    if (selected.includes(i)) {
      const newValue = [...selected];
      newValue.splice(selected.indexOf(i), 1);
      this.setState({ selected: newValue });
    } else {
      this.setState({ selected: [...selected, i] });
    }
  }
  card = (card, i) => {
    const { selected, text } = this.state;

    if (text.length > 0) {
      const textLetters = text.split('').map((l) => l.toLowerCase());
      let match = true;
      let lastMatch = 0;
      let label =
        card.label.toLowerCase() + ' ' + (card.keywords || '').toLowerCase();
      textLetters.forEach((l) => {
        lastMatch = label.indexOf(l, lastMatch);
        if (lastMatch < 0) match = false;
      });
      if (!match) return null;
    }
    return (
      <Card
        key={i}
        elevation={8}
        style={{
          marginRight: 16,
          marginBottom: 16,
          width: 250,
          borderWidth: 5,
          borderStyle: 'solid',
          borderColor: selected.includes(i) ? '#4caf50' : 'transparent',
        }}
      >
        <CardActionArea
          onClick={() => {
            this.select(i);
          }}
        >
          <CardMedia
            style={{ height: 350 }}
            title={card.label}
            image={card.frontImageUrl}
          />
        </CardActionArea>
      </Card>
    );
  };
  play = (faceUp) => {
    const { connection, selection } = this.props;
    const { selected } = this.state;
    const { cards } = selection.attrs;
    const newCards = [];
    selected.forEach((i) => {
      newCards.push(cards[i]);
    });
    const cardsCopy = cards.filter((card, i) => !selected.includes(i));
    if (cardsCopy.length === 0) {
      selection.remove();
    } else {
      selection.update({ cards: cardsCopy });
    }
    newCards.forEach((card, i) => {
      connection.add('deck', {
        ...selection.attrs,
        x: selection.attrs.x + (selection.attrs.width + 0.5) * (i + 1),
        cards: [card],
        faceUp,
      });
    });

    this.close();
  };
  keepSelected = () => {
    const { selection } = this.props;
    const { selected } = this.state;
    const newCards = selected.map((i) => selection.attrs.cards[i]);
    selection.update({ cards: newCards });
    this.close();
  };
  removeSelected = () => {
    const { selection } = this.props;
    const { selected } = this.state;
    const newCards = selection.attrs.cards.filter(
      (card, i) => !selected.includes(i)
    );
    selection.update({ cards: newCards });
    this.close();
  };
  content() {
    const { text } = this.state;
    let { cards } = this.props.selection.attrs;
    return (
      <Fragment>
        <TextField
          name="search"
          label="Search"
          value={text}
          onChange={(e) => {
            this.setState({ text: e.target.value });
          }}
          fullWidth
          style={{ marginBottom: 16 }}
          autoFocus={isWidthUp('sm', this.props.width)}
        />
        <div
          style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
        >
          {cards.map(this.card)}
        </div>
      </Fragment>
    );
  }
  close = () => {
    this.setState({ selected: [] });
    this.props.onClose();
  };
  render() {
    const { open, selection } = this.props;
    if (!open || !selection) return null;
    const { selected } = this.state;

    return (
      <Dialog open fullScreen onClose={this.close}>
        <DialogTitle>
          Search Deck
          {selected.length > 0 && ` (Selected ${selected.length})`}
        </DialogTitle>
        <DialogContent>{this.content()}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={selected.length === 0}
            onClick={this.keepSelected}
          >
            Keep Selected
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={selected.length === 0}
            onClick={this.removeSelected}
          >
            Remove Selected
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={selected.length === 0}
            onClick={() => this.play(true)}
          >
            Play Face Up
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={selected.length === 0}
            onClick={() => this.play(false)}
          >
            Play Face Down
          </Button>
          <Button onClick={this.close}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withWidth()(SearchDeckDialog);
