import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';

class WTDialog extends Component {
  render() {
    let { fullScreen } = this.props;
    return (
      <Dialog
        style={{
          padding:
            'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
        }}
        fullScreen={fullScreen}
        {...this.props}
      />
    );
  }
}

export default WTDialog;
