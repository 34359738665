import BaseWidget from './BaseWidget';
import { fabric } from 'fabric-with-gestures';
import { LAYER_WIDGETS } from '../Layers';

export default class Image extends BaseWidget {
  objects() {
    fabric.Image.fromURL(this.props.record.attrs.imageUrl, (img) => {
      this.props.canvas.remove(this.fabricObject);
      this.fabricObject = img;
      this.imageHeight = img.height;
      this.imageWidth = img.width;
      this.fabricObject.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false,
        mtr: true,
      });
      img.set(
        this.fabricObjectAttrs(
          this.props.record.attrs,
          this.props.pixelsPerInch
        )
      );
      img.layer = LAYER_WIDGETS;
      this.props.canvas.add(img);
    });
    return new fabric.Object();
  }
  fabricObjectAttrs(attrs, pixelsPerInch) {
    const objectAttrs = super.fabricObjectAttrs(attrs, pixelsPerInch);
    return {
      ...objectAttrs,
      scaleX: (this.props.record.attrs.width * pixelsPerInch) / this.imageWidth,
      scaleY:
        (this.props.record.attrs.height * pixelsPerInch) / this.imageHeight,
      originX: 'center',
      originY: 'center',
    };
  }
  canRotate() {
    return true;
  }
}
