import React, { Component, Fragment } from 'react';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';

import modelToToken from './modelToToken';
import ColorPicker from '../../../game/ColorPicker';
import Dialog from '../../../WTDialog';
import Models from '../data/models';
import { widthFor } from '../../../map/Token';

const TINTS = [
  '#f44336', // Red
  '#2196f3', // "Blue"
  '#673ab7', // "Deep Purple",
  '#4caf50', // "Green"
  '#ff5722', // "Deep Orange",
  '#009688', // "Teal",
  '#e91e63', // "Pink",
  '#4caf50', // "Green",
  '#00bcd4', // "Cyan",
  '#9c27b0', //: "Purple",
  '#3f51b5', //: "Indigo",
  '#03a9f4', //: "Light Blue",
  '#795548', //: "Brown",
  '#9e9e9e', //: "Grey",
  '#607d8b', //: "Blue Grey"'
];

function compareString(a, b) {
  if (!a) return false;
  const aNormalized = a.replace(/,/, '');
  const bNormalized = (b || '').replace(/,/, '');
  return (
    aNormalized.localeCompare(bNormalized, undefined, {
      sensitivity: 'base',
    }) === 0
  );
}

function textToModels(text, { color, deployTop }) {
  const lines = text.split(/\n/);
  const groups = [];
  let currentGroup = [];
  let tintIndex = 0;
  groups.push(currentGroup);
  lines.forEach((line) => {
    if (!line.match(/^\s*-/)) {
      currentGroup = [];
      groups.push(currentGroup);
    }
    currentGroup.push(line);
  });
  const counts = {};
  return groups
    .map((group, groupNum) => {
      const models = [];
      let tint;
      group.forEach((line, groupLineNum) => {
        const groupId = `${color}-${groupNum}`;
        const identicalCount = lines.filter(
          (otherLine) => otherLine === line
        ).length;

        let name = line.replace(/\[[^\]]*\]/g, '');
        name = name.replace(/^\s*-/, '');
        name = name.replace(/ <!.+!>/, '');
        const isAttached = line.match(/^\s*-/);
        let count = 1;

        const min = name.indexOf(' (min)') >= 0;
        const max = name.indexOf(' (max)') >= 0;
        name = name.replace(' (min)', '').replace(' (max)', '');

        let countPattern = / \((\d+)\)/;
        let match = name.match(countPattern);
        if (match) {
          count = parseInt(match[1], 10);
          name = name.replace(countPattern, '').trim();
        }

        let unitCount;
        countPattern = /& (\d)+/; // Leader & 9 Grunts. Crew Chief & 5 Grunts
        match = name.match(countPattern);
        if (match) {
          unitCount = parseInt(match[1], 10);
          name = name.replace(countPattern, '').trim();
        }

        match = name.match(/\S+ - (.*)/);
        if (match) {
          name = name.replace(' - ' + match[1], '');
        }
        name = name.trim();

        if (name === "Wrack (The Creator's Might)") {
          name = 'Wrack';
          count = 3;
        }
        if (name === 'Blighted Nyss Swordsman Abbot & Champion') {
          // Conflict Chamber typo
          name = 'Blighted Nyss Swordsmen Abbot & Champion';
        }
        if (name === 'Helga the Conquerer') {
          // War Room misspelling
          name = 'Helga the Conqueror';
        }
        if (name === 'Gatorman Posse (Blindwater)') {
          // to be safe when removing old version
          name = 'Gatorman Posse';
        }

        const model = Models.find(
          (model) =>
            compareString(model.name, name) || compareString(model.alias, name)
        );

        if (model) {
          if (!tint) {
            tint = TINTS[tintIndex];
            tintIndex = (tintIndex + 1) % TINTS.length;
          }
          if (min && model.min) count = model.min;
          if (max && model.max) count = model.max;
          if (model.count) count = model.count;

          if (unitCount) {
            if (unitCount === model.min || unitCount === model.min - 1) {
              count = model.min;
            } else if (unitCount === model.max || unitCount === model.max - 1) {
              count = model.max;
            } else if (
              unitCount === model.count ||
              unitCount === model.count - 1
            ) {
              count = model.count;
            }
          }

          const leader =
            !isAttached &&
            (count > 1 || model.includes) &&
            model.type.indexOf('Unit') >= 0;
          if (!tint && (leader || count > 1 || identicalCount > 1)) {
            tint = TINTS[tintIndex];
            tintIndex += 1;
            tintIndex = tintIndex % TINTS.length;
          }

          for (var i = 0; i < count; i++) {
            const effects = [];
            const count = (counts[name] || 0) + 1;
            counts[name] = count;
            if (leader) {
              if (i === 0) {
                effects.push({
                  color: tint,
                  text: 'Leader',
                  icon: '/emoji/star.png',
                });
              } else {
                effects.push({ color: tint, text: `#${i}` });
              }
            } else if (identicalCount > 1 || count > 1) {
              effects.push({ color: tint, text: `#${count}` });
            }
            if (model.standardBearer) {
              effects.push({
                color: '#9e9e9e',
                text: 'Standard Bearer',
                icon: '/emoji/triangular_flag_on_post.png',
              });
            }
            models.push({
              model,
              width: widthFor(model.size),
              effects,
              name,
              tint,
              group: groupId,
            });
          }
          (model.includes || []).forEach((included) => {
            let includeCount = included.count || 1;
            if (min && included.min) includeCount = included.min;
            if (max && included.max) includeCount = included.max;

            if (unitCount) {
              if (
                unitCount === included.min ||
                unitCount === included.min - 1
              ) {
                includeCount = included.min;
              } else if (
                unitCount === included.max ||
                unitCount === included.max - 1
              ) {
                includeCount = included.max;
              } else if (
                unitCount === included.count ||
                unitCount === included.count - 1
              ) {
                includeCount = included.count;
              }
            }

            const includesHasLeader = included.type === 'Unit';
            for (var i = 0; i < includeCount; i++) {
              const effects = [];
              if (includesHasLeader && i === 0) {
                effects.push({
                  color: tint,
                  text: 'Leader',
                  icon: '/emoji/star.png',
                });
              } else if (includeCount > 1) {
                effects.push({ color: tint, text: `#${i + 1}` });
              }
              if (included.standardBearer) {
                effects.push({
                  color: '#9e9e9e',
                  text: 'Standard Bearer',
                  icon: '/emoji/triangular_flag_on_post.png',
                });
              }
              models.push({
                model: included,
                width: widthFor(included.size),
                effects,
                name: included.alias || included.name,
                tint: tint,
                group: groupId,
              });
            }
          });
        }
      });
      return models;
    })
    .filter((group) => group.length > 0);
}

const FIVE_MAN_POSITIONS = [
  { x: 0, y: 0 },
  { x: 1, y: 0 },
  { x: 0.5, y: Math.sqrt(3) / 2 },
  { x: -1, y: 0 },
  { x: -0.5, y: Math.sqrt(3) / 2 },
];

const SIX_MAN_POSITIONS = [
  { x: 0, y: 0 },
  { x: 1, y: 0 },
  { x: 0, y: 1 },
  { x: 1, y: 1 },
  { x: -1, y: 0 },
  { x: -1, y: 1 },
  { x: 0, y: 2 },
  { x: 1, y: 2 },
  { x: -1, y: 2 },
];

const TEN_MAN_POSITIONS = [
  { x: 0, y: 0 },
  { x: 1, y: 0 },
  { x: -1, y: 0 },
  { x: 2, y: 0 },
  { x: -2, y: 0 },
  { x: 0, y: 1 },
  { x: 1, y: 1 },
  { x: -1, y: 1 },
  { x: 2, y: 1 },
  { x: -2, y: 1 },
  { x: 0, y: 2 },
  { x: 1, y: 2 },
  { x: -1, y: 2 },
  { x: 2, y: 2 },
  { x: -2, y: 2 },
];

function setFormation(group, offsetDirection) {
  if (
    Math.max(...group.map((model) => model.width)) !==
    Math.min(...group.map((model) => model.width))
  ) {
    // inconsistent widths
    let x = 0;
    group.forEach((model) => {
      model.y = 0;
      model.x = x + model.width / 2;
      x += model.width;
    });
    return;
  }
  const totalModels = group.length;
  let positions;
  if (totalModels === 3 || totalModels === 5) {
    positions = FIVE_MAN_POSITIONS;
  } else if (totalModels <= 9) {
    positions = SIX_MAN_POSITIONS;
  } else {
    positions = TEN_MAN_POSITIONS;
  }
  group.forEach((model, i) => {
    model.x = positions[i].x * model.width;
    model.y = positions[i].y * model.width * offsetDirection;
  });
}

export default class DeployDialog extends Component {
  state = {
    deployTop: true,
    deployPlayer: 1,
    text: '',
  };
  addModel = ({
    model,
    deployLine,
    effects,
    group,
    name,
    tint,
    width,
    xOffset,
    yOffset,
    x,
    y,
  }) => {
    const { color } = this.props;
    const { deployTop } = this.state;
    const facing = deployTop ? 180 : 0;
    const tokenAttrs = {
      ...modelToToken(model),
      width,
      effects,
      color,
      facing,
      x: x + xOffset,
      y: y + deployLine + yOffset,
      group,
    };
    this.props.onAddToken(tokenAttrs);
  };
  deploy = () => {
    const { color } = this.props;
    let { deployTop } = this.state;
    const player = this.state.deployPlayer;
    const list = this.props.game.attrs[`player${player}List`];
    let text = list ? list.text : this.state.text;

    const groups = textToModels(text, { color, deployTop });
    let x = 12;
    let deployLine = deployTop ? 15 : 33;
    let offsetDirection = deployTop ? -1 : 1;
    groups.forEach((models, groupNum) => {
      if (modelToToken(models[0].model).resource2) {
        // battlegroup
        let x = 0;
        models.forEach((model) => {
          model.y = 0;
          model.x = x + model.width / 2;
          x += model.width;
        });
      } else {
        setFormation(models, offsetDirection);
      }
      const minX = Math.min(
        ...models.map((model) => model.x - model.width / 2)
      );
      const maxX = Math.max(
        ...models.map((model) => model.x + model.width / 2)
      );
      const groupWidth = maxX - minX;
      models.forEach((model) => {
        this.addModel({
          ...model,
          deployLine,
          yOffset: (offsetDirection * model.width) / 2,
          xOffset: x - minX,
        });
      });
      x += groupWidth;
      if (x > 36) {
        x = 12;
        deployLine = deployLine - offsetDirection * 4;
        if (deployLine < 3) deployLine = 3;
        if (deployLine > 45) deployLine = 45;
      }
    });
    this.props.game.update({ deploy: true });
    this.props.onClose();
  };
  chooseList = () => {
    const { game } = this.props;
    return (
      <FormControl fullWidth component="fieldset" style={{ marginBottom: 16 }}>
        <FormLabel component="legend">Choose List</FormLabel>
        <RadioGroup
          aria-label="deployTop"
          name="deployTop"
          value={this.state.deployPlayer}
          onChange={(e) => {
            this.setState({ deployPlayer: parseInt(e.target.value, 10) });
          }}
        >
          {[1, 2].map((player, i) => (
            <FormControlLabel
              key={i}
              value={player}
              control={<Radio />}
              label={`${game.attrs[`player${player}Label`]}: ${game.attrs[`player${player}List`].label}`}
              style={{ marginBottom: -16 }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  };
  enterList = () => {
    const { text } = this.state;
    return (
      <TextField
        autoFocus
        margin="normal"
        fullWidth
        multiline
        rows={3}
        label="Paste your list here"
        helperText="Use 'Copy Army Text' in War Room"
        value={text}
        onChange={(e) => {
          this.setState({ text: e.target.value });
        }}
      />
    );
  };
  dialogContent = () => {
    const { color, game } = this.props;
    const { deployTop } = this.state;
    return (
      <Fragment>
        {game.attrs.player1List && game.attrs.player2List
          ? this.chooseList()
          : this.enterList()}
        <FormControl
          fullWidth
          component="fieldset"
          style={{ marginBottom: 16 }}
        >
          <FormLabel component="legend">Deploy Side</FormLabel>
          <RadioGroup
            aria-label="deployTop"
            name="deployTop"
            value={deployTop ? 'true' : 'false'}
            onChange={(e) => {
              this.setState({ deployTop: e.target.value === 'true' });
            }}
          >
            <FormControlLabel
              style={{ marginBottom: -16 }}
              value="true"
              control={<Radio />}
              label="Top"
            />
            <FormControlLabel
              style={{ marginBottom: -16 }}
              value="false"
              control={<Radio />}
              label="Bottom"
            />
          </RadioGroup>
        </FormControl>
        <ColorPicker
          label="Base Color"
          value={color}
          onChange={(e) => this.props.onChangeColor(e.target.value)}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={this.deploy}
          style={{ marginTop: 16 }}
        >
          Deploy
        </Button>
      </Fragment>
    );
  };
  render() {
    const { onClose, open } = this.props;
    if (!open) return null;

    return (
      <Dialog open maxWidth="sm" fullWidth onClose={onClose}>
        <DialogTitle>Deploy</DialogTitle>
        <DialogContent>{this.dialogContent()}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
