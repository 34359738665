import { sizeFor } from '../../../map/Token';
import { totalFromGrid } from './DamageGrid';

export default function (model) {
  const { width, height, shape } = sizeFor(model.size);
  const tokenAttrs = {
    label: model.alias || model.name,
    deletable: true,
    width,
    height,
    shape,
  };
  if (model.avatar) {
    tokenAttrs.imageUrl = model.avatar;
  }
  if (model.health) {
    tokenAttrs.resource1 = model.health;
    tokenAttrs.resource1Max = model.health;
  }
  if (model.essence !== undefined) {
    tokenAttrs.resource2 = model.essence;
    tokenAttrs.resource2Label = 'Essence';
  }
  if (model.focus !== undefined) {
    tokenAttrs.resource2 = model.focus;
    tokenAttrs.resource2Label = 'Focus';
  }
  if (model.fury !== undefined) {
    tokenAttrs.resource2 = model.fury;
    tokenAttrs.resource2Label = 'Fury';
  }
  if (model.resource3 !== undefined) {
    tokenAttrs.resource3Label = model.resource3Label;
    tokenAttrs.resource3 = model.resource3;
  }
  if (model.damageGrid) {
    tokenAttrs.damageGrid = model.damageGrid;
    tokenAttrs.resource1Max = tokenAttrs.resource1 = totalFromGrid(
      tokenAttrs.damageGrid
    );
  }
  tokenAttrs.maxMeleeRange = model.maxMeleeRange || 0;
  return tokenAttrs;
}
