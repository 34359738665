import React, { Component, Fragment } from 'react';

import { Redirect } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import ReplayIcon from '@mui/icons-material/Replay';

import JoinGameDialog from './JoinGameDialog';
import ResumeGameDialog from './ResumeGameDialog';
import SignInButton from '../signup/SignInButton';

export default class Home extends Component {
  state = {};
  resume = () => {
    this.setState({ showResume: true });
  };
  join = () => {
    this.setState({ showJoin: true });
  };
  welcome() {
    return (
      <div
        style={{
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Grid container style={{ maxWidth: 600 }} spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              textAlign: 'center',
              paddingLeft: 16,
              paddingRight: 16,
              marginTop: 32,
              marginBottom: 32,
            }}
          >
            <SignInButton
              style={{ position: 'absolute', top: 40, right: 10 }}
            />
            {this.props.logoPath && (
              <img
                src={this.props.logoPath}
                alt={this.props.system}
                style={{ objectFit: 'contain', width: '100%', height: 250 }}
              />
            )}
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 16, paddingRight: 16 }}>
            <Typography variant="body2" color="textSecondary">
              {this.props.description}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <List>
              <ListItem button component="a" onClick={this.props.onPlay}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText>Start a new game</ListItemText>
              </ListItem>
              <ListItem button component="a" onClick={this.resume}>
                <ListItemIcon>
                  <ReplayIcon />
                </ListItemIcon>
                <ListItemText>Continue game</ListItemText>
              </ListItem>
              <ListItem button component="a" onClick={this.join}>
                <ListItemIcon>
                  <LinkIcon />
                </ListItemIcon>
                <ListItemText>Join game</ListItemText>
              </ListItem>
              {this.props.children}
            </List>
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 16, paddingRight: 16 }}>
            {this.props.footer && (
              <Typography paragraph variant="body2" color="textSecondary">
                {this.props.footer}
              </Typography>
            )}
            <Typography
              paragraph
              variant="body2"
              color="textSecondary"
              align="right"
            >
              Powered by{' '}
              <a href="/">
                wartable.online{' '}
                <img
                  alt=""
                  src="/logo/wartablelogo.png"
                  style={{ height: 14, width: 14 }}
                />
              </a>
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
  render() {
    let { showJoin, showResume } = this.state;
    return (
      <Fragment>
        {showResume && (
          <ResumeGameDialog
            open
            system={this.props.system}
            onClose={() => {
              this.setState({ showResume: false });
            }}
          />
        )}
        {showJoin && (
          <JoinGameDialog
            onClose={() => {
              this.setState({ showJoin: false });
            }}
          />
        )}
        {this.welcome()}
      </Fragment>
    );
  }
}
