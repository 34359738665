import React, { Component } from 'react';
import { fabric } from 'fabric-with-gestures';
import { throttle } from 'lodash';
import ReactGA from 'react-ga4';

import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

import Background from './Background';
import Deck from './Deck';
import DeploymentLines from './DeploymentLines';
import Model from './Model';
import Ping from './Ping';
import Records from '../game/Records';
import Shape from './Shape';
import Widget from './Widget';

class Map extends Component {
  keysdown = new Set();
  cleanupHooks = [];
  state = {
    canvasInited: false,
  };
  componentDidMount() {
    const canvas = this.props.canvas;
    canvas.initialize('c', {
      height: this.props.canvasHeight,
      width: this.props.canvasWidth,
      selection: false,
      selectionKey: 'shiftKey',
      preserveObjectStacking: true,
      skipOffscreen: false,
      defaultCursor: 'grab',
    });
    canvas.orderObjects = function () {
      this._objects.sort((o1, o2) => o1.layer - o2.layer);
    };
    canvas.on('object:added', (e) => canvas.orderObjects());
    this.setDimensions();
    this.bindPanAndZoomEvents();
    this.bindSelectionEvents();
    this.bindObjectEvents();
    this.bindKeyboardEvents();
    this.centerMap();

    this.setState({ canvasInited: true });
  }
  componentWillUnmount() {
    this.cleanupHooks.forEach((h) => h());
  }
  centerMap() {
    const { canvas, height, width } = this.props;
    const scaleFactor = this.scaleFactor();
    const isTabletMobile = window.innerWidth < 960;
    const contentHeight = isTabletMobile
      ? window.innerHeight - 64 - 64
      : window.innerHeight - 64;
    const contentWidth = isTabletMobile
      ? window.innerWidth
      : window.innerWidth - 250 - 250;
    const zoomFactor = Math.min(contentWidth / width, contentHeight / height);
    canvas.setZoom(zoomFactor);
    let xOffset = 0.5 * (window.innerWidth - contentWidth);
    if (contentWidth > contentHeight) {
      xOffset = xOffset + 0.5 * (contentWidth - contentHeight);
    }
    let yOffset = 60;
    if (isTabletMobile) {
      yOffset = 120;
    }
    canvas.absolutePan(
      new fabric.Point(
        (-1 * xOffset) / scaleFactor,
        (-1 * yOffset) / scaleFactor
      )
    );
  }
  scaleFactor() {
    const { canvasHeight, height } = this.props;
    return height / canvasHeight;
  }
  bindPanAndZoomEvents() {
    const { canvas } = this.props;
    canvas.on('mouse:wheel', ({ e }) => {
      const scaleFactor = this.scaleFactor();
      const x = e.offsetX / scaleFactor;
      const y = e.offsetY / scaleFactor;
      // avoid mouse wheel zooming too far
      const deltaY = Math.max(Math.min(e.deltaY, 10), -10);
      const zoom = (-1 * deltaY) / 200;
      this.props.onZoomToPoint({ x, y }, zoom);
      e.preventDefault();
      e.stopPropagation();
    });
    canvas.on('touch:gesture', (event) => {
      // https://stackoverflow.com/questions/45110576/fabricjs-touch-pan-zoom-entire-canvas
      if (!event.e.touches || event.e.touches.length !== 2) return;
      if (event.self.state === 'start') {
        this.zoomStartScale = canvas.getZoom();
      }
      let newZoom = this.zoomStartScale * event.self.scale;
      this.props.onZoomToPoint(event.self, newZoom - canvas.getZoom());
    });
    canvas.on('mouse:down', ({ e }) => {
      if (!canvas.getActiveObject()) {
        if (e.pageX || e.touches[0]) {
          this.isDragging = true;
          this.lastPosX = e.pageX || e.touches[0].pageX;
          this.lastPosY = e.pageY || e.touches[0].pageY;
        }
        canvas.set('defaultCursor', 'grabbing');
      }
    });
    canvas.on('mouse:move', ({ e }) => {
      if (!e.pageX && !e.touches) return;
      if (this.isDragging && !this.props.canvas.selection) {
        if (e.pageX || e.touches[0]) {
          let pageX = e.pageX || e.touches[0].pageX;
          let pageY = e.pageY || e.touches[0].pageY;
          var delta = new fabric.Point(
            pageX - this.lastPosX,
            pageY - this.lastPosY
          );
          this.lastPosX = pageX;
          this.lastPosY = pageY;
          canvas.relativePan(delta);
        }
      }
    });
    canvas.on('mouse:up', () => {
      this.isDragging = false;
      canvas.set('defaultCursor', 'grab');
    });
  }
  bindSelectionEvents() {
    let { canvas, connection } = this.props;
    canvas.on('selection:created', this.onSelectionUpdated);
    canvas.on('selection:updated', this.onSelectionUpdated);
    canvas.on('selection:cleared', this.onSelectionCleared);
    canvas.on('mouse:dblclick', this.onDoubleClick);
    canvas.on('touch:longpress', this.onLongPress);

    connection.onChange('deck', this.onRecordChanged);
    connection.onRemove('deck', this.onRecordRemoved);
    connection.onChange('shape', this.onRecordChanged);
    connection.onRemove('shape', this.onRecordRemoved);
    connection.onChange('token', this.onRecordChanged);
    connection.onRemove('token', this.onRecordRemoved);
    connection.onChange('widget', this.onRecordChanged);
    connection.onRemove('widget', this.onRecordRemoved);
  }
  bindKeyboardEvents() {
    document.addEventListener('keydown', this.onKeydown);
    this.cleanupHooks.push(() =>
      document.removeEventListener('keydown', this.onKeydown)
    );

    document.addEventListener('keyup', this.onKeyup);
    this.cleanupHooks.push(() =>
      document.removeEventListener('keyup', this.onKeyup)
    );

    const handlePanInterval = window.setInterval(this.handlePan, 25);
    this.cleanupHooks.push(() => window.clearInterval(handlePanInterval));
  }
  onKeydown = (e) => {
    if (
      (document.activeElement &&
        ['TEXTAREA', 'INPUT'].includes(document.activeElement.tagName)) ||
      document.querySelector('[role="dialog"]')
    ) {
      return;
    }
    if (['Escape'].includes(e.code)) {
      this.props.canvas.discardActiveObject();
      ReactGA.event({
        category: 'Keyboard Shortcut',
        action: 'Unselect',
      });
    }
    if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.code)) {
      ReactGA.event({
        category: 'Keyboard Shortcut',
        action: 'Move Model',
      });
      this.keysdown.add(e.code);
      this.handleMoves();
    }
    if (['KeyW', 'KeyA', 'KeyD', 'KeyS'].includes(e.code)) {
      ReactGA.event({
        category: 'Keyboard Shortcut',
        action: 'Pan Map',
      });
      this.keysdown.add(e.code);
    }
    if (['ShiftLeft', 'ShiftRight'].includes(e.code)) {
      this.keysdown.add('Shift');
      if (this.props.game.attrs.deploy) {
        this.props.canvas.set({ selection: true });
      }
    }
    if (
      ['ControlLeft', 'ControlRight', 'MetaLeft', 'MetaRight'].includes(e.code)
    ) {
      if (
        this.props.game.attrs.doubleMoveCost &&
        this.props.selection &&
        !this.props.selection.attrs.doubleMoveCost
      ) {
        this.handleMoves();
        this.props.selection.update({ doubleMoveCost: true });
      }
    }
    if (['Digit0', 'Numpad0'].includes(e.code) && e.shiftKey) {
      this.centerMap();
      ReactGA.event({
        category: 'Keyboard Shortcut',
        action: 'Center Map',
      });
    }
  };
  onKeyup = (e) => {
    if (
      [
        'ArrowLeft',
        'ArrowRight',
        'ArrowUp',
        'ArrowDown',
        'KeyW',
        'KeyA',
        'KeyD',
        'KeyS',
      ].includes(e.code)
    ) {
      this.keysdown.delete(e.code);
    }
    if (['ShiftLeft', 'ShiftRight'].includes(e.code)) {
      this.keysdown.delete('Shift');
      this.props.canvas.set({ selection: false });
    }
    if (
      ['ControlLeft', 'ControlRight', 'MetaLeft', 'MetaRight'].includes(e.code)
    ) {
      if (
        this.props.game.attrs.doubleMoveCost &&
        this.props.selection &&
        this.props.selection.attrs.doubleMoveCost
      ) {
        this.handleMoves();
        this.props.selection.update({ doubleMoveCost: false });
      }
    }
  };
  onDoubleClick = () => {
    const { canvas, game, selection } = this.props;
    if (!game.attrs.deploy) return;
    if (!selection || !selection.attrs.group) return;

    const { group } = selection.attrs;
    const objects = canvas
      .getObjects()
      .filter((object) => object.record && object.record.attrs.group === group);
    const activeSelection = new fabric.ActiveSelection(objects, { canvas });
    canvas.setActiveObject(activeSelection);
  };
  onLongPress = (event) => {
    const { canvas, pixelsPerInch } = this.props;
    if (canvas.getObjects().find((o) => o.record && o.record.type === 'ping'))
      return;
    if (event.e.touches && !event.e.touches.length) return;

    const canvasElement = event.e.target;
    const boundingClientRect = canvasElement.getBoundingClientRect();

    const { tl, br } = canvas.vptCoords;
    const scrollLeftUnits = tl.x;
    const scrollTopUnits = tl.y;

    const canvasVirtualWidth = br.x - tl.x;
    const canvasVirtualHeight = br.y - tl.y;
    const canvasRenderedWidth = boundingClientRect.width;
    const canvasRenderedHeight = boundingClientRect.height;
    const screenToCoordinateRatioWidth =
      canvasVirtualWidth / canvasRenderedWidth;
    const screenToCoordinateRatioHeight =
      canvasVirtualHeight / canvasRenderedHeight;

    const { clientX, clientY } = event.e.touches ? event.e.touches[0] : event.e;
    const clickLeftCoordinates = clientX * screenToCoordinateRatioWidth;
    const clickTopCoordinates = clientY * screenToCoordinateRatioHeight;

    const x = (clickLeftCoordinates + scrollLeftUnits) / pixelsPerInch;
    const y = (clickTopCoordinates + scrollTopUnits) / pixelsPerInch;

    this.props.connection.add('ping', { x, y });
  };
  handlePan = () => {
    let x = 0;
    let y = 0;
    if (this.keysdown.has('KeyW')) {
      y = 10;
    }
    if (this.keysdown.has('KeyS')) {
      y = -10;
    }
    if (this.keysdown.has('KeyA')) {
      x = 10;
    }
    if (this.keysdown.has('KeyD')) {
      x = -10;
    }
    if (x || y) {
      this.props.canvas.relativePan(new fabric.Point(x, y));
    }
  };
  handleMoves = () => {
    const selection = this.props.selection;
    if (!selection) return;
    if (selection.attrs.immobile) return;

    let facing, x, y;
    let facingChange = this.keysdown.has('Shift') ? 1 : 10;
    if (
      this.keysdown.has('ArrowLeft') &&
      selection.attrs.facing !== undefined
    ) {
      facing = selection.attrs.facing - facingChange;
    }
    if (
      this.keysdown.has('ArrowRight') &&
      selection.attrs.facing !== undefined
    ) {
      facing = selection.attrs.facing + facingChange;
    }
    let distance = this.keysdown.has('Shift') ? 0.1 : 1;
    if (
      this.keysdown.has('ArrowUp') &&
      selection.attrs.x !== undefined &&
      selection.attrs.facing !== undefined
    ) {
      x =
        selection.attrs.x +
        distance * Math.cos(((selection.attrs.facing - 90) * Math.PI) / 180);
      y =
        selection.attrs.y +
        distance * Math.sin(((selection.attrs.facing - 90) * Math.PI) / 180);
    }
    if (
      this.keysdown.has('ArrowDown') &&
      selection.attrs.x !== undefined &&
      selection.attrs.facing !== undefined
    ) {
      x =
        selection.attrs.x +
        distance * Math.cos(((selection.attrs.facing + 90) * Math.PI) / 180);
      y =
        selection.attrs.y +
        distance * Math.sin(((selection.attrs.facing + 90) * Math.PI) / 180);
    }
    this.moveRecord(selection, {
      x: x || selection.attrs.x,
      y: y || selection.attrs.y,
      facing: facing === undefined ? selection.attrs.facing : facing,
    });
  };
  onRecordChanged = (record) => {
    const { selections } = this.props;
    const recordIndex = selections.findIndex(
      (selection) => selection.path === record.path
    );
    if (recordIndex >= 0) {
      const newSelections = [...selections];
      newSelections.splice(recordIndex, 1, record);
      this.props.onSelectionChanged(newSelections);
    }
  };
  onRecordRemoved = (key) => {
    const { canvas, selections } = this.props;
    const recordIndex = selections.findIndex(
      (selection) => selection.path === key
    );
    if (recordIndex >= 0) {
      const newSelections = [...selections];
      newSelections.splice(recordIndex, 1);
      this.props.onSelectionChanged(newSelections);
    }
    let activeObjects = this.props.canvas.getActiveObjects();
    const index = activeObjects.findIndex(
      (o) => o.record && o.record.path === key
    );
    if (index >= 0) {
      activeObjects = [...activeObjects];
      activeObjects.splice(index, 1);
      if (activeObjects.length > 0) {
        const activeSelection = new fabric.ActiveSelection(activeObjects, {
          canvas,
        });
        canvas.setActiveObject(activeSelection);
      } else {
        canvas.discardActiveObject();
      }
      canvas.requestRenderAll();
    }
  };
  bindObjectEvents() {
    const { canvas } = this.props;
    canvas.on('object:moving', this.onObjectMoving);
    canvas.on('object:rotating', this.onObjectMoving);
    canvas.on('object:moved', this.onObjectMoved);
  }
  confirmMoves(record) {
    if (this.props.game.attrs.noConfirmMoves) return false;
    if (record.attrs.label === 'Soul') return true;
    const { deploy, phase } = this.props.game.attrs;
    if (deploy) return false;
    if (!phase) return true;
    if (phase.indexOf('deploy') >= 0) return false;
    return record.attrs.resource1;
  }
  onObjectMoving = throttle(
    ({ target }) => {
      let objectsWithRecord = [];
      if (target.type === 'activeSelection') {
        // objectsWithRecord = target.getObjects().filter(o => o.record);
        return; // avoid things slowing to a halt when dragging multiple records
      } else if (target.record) {
        objectsWithRecord = [target];
      }
      objectsWithRecord.forEach((object) => {
        let record = object.record;
        let left = object.group
          ? object.group.left + object.left + object.group.width / 2
          : object.left;
        let top = object.group
          ? object.group.top + object.top + object.group.height / 2
          : object.top;
        let x = left / this.props.pixelsPerInch;
        let y = top / this.props.pixelsPerInch;
        let facing = object.angle || 0;

        let moves = record.attrs.moves || [];
        const confirmMoves = this.confirmMoves(record);
        if (!confirmMoves) {
          moves = [];
        }
        if (moves.length === 0 && confirmMoves) {
          moves = [...moves];
          moves.push({
            facing,
            x: record.attrs.x,
            y: record.attrs.y,
            doubleCost: record.attrs.doubleMoveCost || false,
          });
        }
        record.update({ x, y, facing, moves });
      });
    },
    200,
    { trailing: true }
  );
  moveRecord(record, { x, y, facing }) {
    const { movingModels } = this.props;
    const moves = record.attrs.moves || [];
    if (
      movingModels.indexOf(record.id) === -1 &&
      !this.props.game.attrs.deploy
    ) {
      movingModels.push(record.id);
    }
    if (this.confirmMoves(record)) {
      if (moves.length === 0) {
        moves.push({
          facing: facing || 0,
          x: record.attrs.x,
          y: record.attrs.y,
        });
      }
      moves.push({
        facing: facing || 0,
        x,
        y,
        doubleCost: record.attrs.doubleMoveCost || false,
      });
    }
    record.update({ x, y, moves, facing: facing, originalFacing: facing });
  }
  onObjectMoved = ({ target }) => {
    this.onObjectMoving.cancel();
    let objectsWithRecord = [];
    if (target.type === 'activeSelection') {
      objectsWithRecord = target.getObjects().filter((o) => o.record);
    } else if (target.record) {
      objectsWithRecord = [target];
    }
    objectsWithRecord.forEach((object) => {
      let record = object.record;
      let left = object.group
        ? object.group.left + object.left + object.group.width / 2
        : object.left;
      let top = object.group
        ? object.group.top + object.top + object.group.height / 2
        : object.top;
      let x = left / this.props.pixelsPerInch;
      let y = top / this.props.pixelsPerInch;
      let facing = object.angle;

      let dropped = false;
      if (record.type === 'deck' && !record.attrs.cannotBeMerged) {
        this.props.canvas.forEachObject((o) => {
          if (
            o.record?.id !== object.record.id &&
            o.record?.type === 'deck' &&
            object.intersectsWithObject(o)
          ) {
            dropped = true;
            const newCards = [...o.record.attrs.cards, ...record.attrs.cards];
            o.record.update({ cards: newCards }).then(() => record.remove());
          }
        });
      }

      if (!dropped) {
        this.moveRecord(record, { x, y, facing });
      }
    });
  };
  onSelectionUpdated = ({ target }) => {
    let record = target.record;
    target.set({
      cornerSize: 25 * this.props.canvas.getZoom(),
      rotatingPointOffset: 30 * this.props.canvas.getZoom(),
      cornerColor: '#fff',
      borderColor: '#fff',
    });
    if (record) {
      if (window.cardsPopout && record.attrs.cards) {
        window.cardsPopout.postMessage({
          title: record.attrs.label,
          cards: record.attrs.cards,
        });
      }
      this.props.onSelectionChanged([record]);
    } else if (target.type === 'activeSelection') {
      if (
        target.getObjects().filter((o) => o.lockMovementX || o.lockMovementY)
          .length > 0
      ) {
        // can't multi-select immobile objects
        target.set({ lockMovementX: true, lockMovementY: true });
      }
      target.set({
        hasControls: false,
        borderColor: '#fff',
        cornerColor: '#fff',
      });
      this.props.onSelectionChanged(
        target
          .getObjects()
          .filter((o) => o.record)
          .map((o) => o.record)
      );
      target.bringToFront();
    } else {
      this.onSelectionCleared();
    }
  };
  onSelectionCleared = () => {
    this.props.onSelectionChanged(undefined);
  };
  componentDidUpdate(prevProps) {
    if (
      prevProps.width !== this.props.width ||
      prevProps.height !== this.props.height
    ) {
      this.setDimensions();
    }
    this.props.canvas.requestRenderAll();
  }
  setDimensions() {
    this.props.canvas.setDimensions(
      {
        width: `${this.props.width}px`,
        height: `${this.props.height}px`,
      },
      { cssOnly: true }
    );
  }
  snackbarContent() {
    if (this.props.editMapMode) {
      return (
        <SnackbarContent
          message="Editing Map"
          action={
            <Button
              onClick={this.props.exitEditMap}
              color="secondary"
              size="small"
            >
              Done
            </Button>
          }
        />
      );
    }
    if (this.props.game.attrs.deploy) {
      return (
        <SnackbarContent
          message="Deploying"
          action={
            <Button
              onClick={() => {
                this.props.game.update({ deploy: false });
              }}
              color="secondary"
              size="small"
            >
              Done
            </Button>
          }
        />
      );
    }
    return null;
  }
  render() {
    let {
      canvas,
      connection,
      extensions,
      editMapMode,
      game,
      height,
      pixelsPerInch,
      selections,
      width,
      canvasWidth,
      canvasHeight,
      clearPendingMove,
    } = this.props;
    const snackbarContent = this.snackbarContent();
    const ConfiguredDeck = extensions.Deck || Deck;
    return (
      <div style={{ width: width, height: height, backgroundColor: '#000' }}>
        <canvas id="c"></canvas>
        {this.state.canvasInited && (
          <React.Fragment>
            <Background
              canvas={canvas}
              width={canvasWidth}
              height={canvasHeight}
              shape={game.attrs.shape}
              url={game.attrs.backgroundImageUrl}
            />
            <Records type="shape" connection={connection}>
              {(record) => (
                <Shape
                  key={record.path}
                  record={record}
                  pixelsPerInch={pixelsPerInch}
                  canvas={canvas}
                  edit={editMapMode}
                />
              )}
            </Records>
            {game.attrs.deploy && (
              <>
                <Records type="deployment" connection={connection}>
                  {(record) => (
                    <Shape
                      key={record.path}
                      record={record}
                      pixelsPerInch={pixelsPerInch}
                      canvas={canvas}
                      edit={editMapMode}
                    />
                  )}
                </Records>
              </>
            )}
            <Records type="ping" connection={connection}>
              {(record) => (
                <Ping
                  key={record.path}
                  record={record}
                  canvas={canvas}
                  pixelsPerInch={pixelsPerInch}
                />
              )}
            </Records>
            <Records type="token" connection={connection}>
              {(record) => (
                <Model
                  key={record.path}
                  record={record}
                  selections={selections}
                  canvas={canvas}
                  pixelsPerInch={pixelsPerInch}
                  showArcs={game.attrs.showArcs}
                  showDistance={!game.attrs.hideDistance}
                  clearPendingMove={clearPendingMove}
                  editMapMode={editMapMode}
                />
              )}
            </Records>
            {!editMapMode && (
              <>
                <Records type="deck" connection={connection}>
                  {(record) => (
                    <ConfiguredDeck
                      key={record.path}
                      record={record}
                      selections={selections}
                      canvas={canvas}
                      pixelsPerInch={pixelsPerInch}
                    />
                  )}
                </Records>
                <Records type="widget" connection={connection}>
                  {(record) => (
                    <Widget
                      key={record.path}
                      record={record}
                      pixelsPerInch={pixelsPerInch}
                      canvas={canvas}
                      extensions={extensions}
                    />
                  )}
                </Records>
              </>
            )}
            {game.attrs.deploy && (
              <DeploymentLines
                extensions={extensions}
                canvas={canvas}
                game={game}
                pixelsPerInch={pixelsPerInch}
              />
            )}
          </React.Fragment>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={snackbarContent !== null}
          style={{ marginTop: 55, zIndex: 1100 }}
        >
          {snackbarContent}
        </Snackbar>
      </div>
    );
  }
}

export default Map;
