import React, { Component, Fragment } from 'react';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';

import Dialog from '../WTDialog';

const keyboardShortcuts = ({ extensions, game }) => [
  {
    title: 'General',
    shortcuts: [
      { label: 'Command Mode', icon: '/' },
      { label: 'Keyboard Shortcuts', icon: 'Shift + /' },
      { label: 'Roll X dice', icon: '1 - 9' },
    ].concat(extensions.keyboardShortcutsGeneral || []),
  },
  {
    title: 'Selected Model',
    shortcuts: [
      { label: 'Remove', icon: 'Delete' },
      { label: 'Copy', icon: 'Ctrl + C' },
      { label: 'Forward 1”', icon: '↑' },
      { label: 'Forward 0.1”', icon: 'Shift + ↑' },
      { label: 'Backward 1”', icon: '↓' },
      { label: 'Backward 0.1”', icon: 'Shift+↓' },
      { label: 'Left 10°', icon: '←' },
      { label: 'Left 1°', icon: 'Shift + ←' },
      { label: 'Right 10°', icon: '→' },
      { label: 'Right 1°', icon: 'Shift + →' },
      { label: 'Deselect', icon: 'Escape' },
      { label: 'Confirm Move', icon: 'Enter' },
      { label: 'Confirm All Moves', icon: 'Shift + Enter' },
      { label: 'Toggle Effects Dialog', icon: ';' },
      { label: 'Toggle R Effect', icon: 'R' },
      { label: 'Toggle G Effect', icon: 'G' },
      { label: 'Toggle B Effect', icon: 'B' },
      { label: `Subtract ${game.attrs.resource1Label}`, icon: '-' },
      { label: `Add ${game.attrs.resource1Label}`, icon: '=' },
      { label: `Subtract ${game.attrs.resource2Label}`, icon: '[' },
      { label: `Add ${game.attrs.resource2Label}`, icon: ']' },
      { label: `Remove ${game.attrs.resource3Label}`, icon: 'Shift + [' },
      { label: `Add ${game.attrs.resource3Label}`, icon: 'Shift + ]' },
    ].concat(
      game.attrs.doubleMoveCost
        ? [{ label: '2x Move Cost', icon: 'Ctrl / Command + Drag' }]
        : []
    ),
  },
  {
    title: 'Map',
    shortcuts: [
      { label: 'Pan Up', icon: 'W' },
      { label: 'Pan Down', icon: 'S' },
      { label: 'Pan Left', icon: 'A' },
      { label: 'Pan Right', icon: 'D' },
      { label: 'Zoom In', icon: 'Shift + =' },
      { label: 'Zoom Out', icon: 'Shift + -' },
      { label: 'Center Map', icon: 'Shift + 0' },
    ],
  },
  {
    title: 'Clock',
    shortcuts: [
      { label: 'Switch Player', icon: 'Space' },
      { label: 'Pause / Unpause', icon: 'Shift + Space' },
    ],
  },
];

export default class KeyboardShortcutsDialogs extends Component {
  render() {
    const { extensions, game, onClose, open } = this.props;
    if (!open) return null;

    return (
      <Dialog open onClose={onClose}>
        <DialogTitle>Keyboard Shortcuts</DialogTitle>
        <DialogContent>
          <List style={{ marginLeft: -16 }}>
            {keyboardShortcuts({ extensions, game }).map((section) => {
              const shortcuts = section.shortcuts;
              if (!shortcuts.length) return null;
              return (
                <Fragment key={section.title}>
                  <ListSubheader style={{ backgroundColor: '#fff' }}>
                    {section.title}
                  </ListSubheader>
                  {shortcuts.map((shortcut) => (
                    <ListItem dense key={shortcut.label}>
                      <ListItemText
                        style={{ paddingRight: 100 }}
                        primary={shortcut.label}
                      />
                      <ListItemSecondaryAction>
                        <Typography variant="body2">{shortcut.icon}</Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </Fragment>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}
