import { fabric } from 'fabric-with-gestures';
import BaseShape from './BaseShape';

export default class Text extends BaseShape {
  createFabricObject() {
    return new fabric.Text('', {
      textAlign: 'center',
      originX: 'center',
      originY: 'center',
      fontFamily: 'Roboto',
      hasControls: false,
      layer: this.layer(),
    });
  }
  shapeAttrs(attrs, pixelsPerInch) {
    return {
      left: pixelsPerInch * attrs.x,
      top: pixelsPerInch * attrs.y,
      fill: attrs.stroke,
      fontSize: pixelsPerInch / 2.0,
      angle: attrs.facing,
      text: attrs.text,
    };
  }
}
