import React, { Fragment } from 'react';

export default [
  
  {
    label: 'Necrotyr18',
    credit: (
      <Fragment>
        These maps were designed for for Streamroller 2025 by Necrotyr18, a long-time supporter of War
        Table.
      </Fragment>
    ),
    maps: [
      {
        "label": "Color Guard 1",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Color-Guard-1.jpg",
        "gameId": "-OI8seXhR3fbAXnlDKnn"
      },
      {
        "label": "Color Guard 2",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Color-Guard-2.jpg",
        "gameId": "-OI8v20yK1kFFQPnDoji"
      },
      {
        "label": "Color Guard 3",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Color-Guard-3.jpg",
        "gameId": "-OI8tiwYpLxK_dL7ZeeA"
      },
      {
        "label": "Color Guard 4",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Color-Guard-4.jpg",
        "gameId": "-OI8vUAdVJZvozCPom11"
      },
      {
        "label": "Color Guard 5",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Color-Guard-5.jpg",
        "gameId": "-OI8w5UxszCEKSrDtyKr"
      },
      {
        "label": "Trench Warfare 1",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Trench-Warfare-1.jpg",
        "gameId": "-OI8wTP8GvuquzAv-VcU"
      },
      {
        "label": "Trench Warfare 2",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Trench-Warfare-2.jpg",
        "gameId": "-OI8wpAW8aVvoUmNWEDD"
      },
      {
        "label": "Trench Warfare 3",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Trench-Warfare-3.jpg",
        "gameId": "-OI8xEw4ec_PQaA68OMj"
      },
      {
        "label": "Trench Warfare 4",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Trench-Warfare-4.jpg",
        "gameId": "-OI8xcQEOR1ig4YixDLS"
      },
      {
        "label": "Trench Warfare 5",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Trench-Warfare-5.jpg",
        "gameId": "-OI8y5uSPsuHWh3z3QIh"
      },
      {
        "label": "Wolves At Our Heels 1",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Wolves-At-Our-Heels-1.jpg",
        "gameId": "-OI8yWp-_GwwQdJgqVWB"
      },
      {
        "label": "Wolves At Our Heels 2",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Wolves-At-Our-Heels-2.jpg",
        "gameId": "-OI8yxoo_MC6IhNv3iHK"
      },
      {
        "label": "Wolves At Our Heels 3",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Wolves-At-Our-Heels-3.jpg",
        "gameId": "-OI8zVueqHVl6u-RCX4B"
      },
      {
        "label": "Wolves At Our Heels 4",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Wolves-At-Our-Heels-4.jpg",
        "gameId": "-OI8zvnUZXFKGltG3bVJ"
      },
      {
        "label": "Wolves At Our Heels 5",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Wolves-At-Our-Heels-5.jpg",
        "gameId": "-OI9-Kz3moEwj1ylDLRj"
      },
      {
        "label": "Payload 1",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Payload-1.jpg",
        "gameId": "-OI9-tb3QSJcHXh28t0R"
      },
      {
        "label": "Payload 2",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Payload-2.jpg",
        "gameId": "-OI90EKALcPhAqFNdF7r"
      },
      {
        "label": "Payload 3",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Payload-3.jpg",
        "gameId": "-OI91_SNTaMVABXfFbgZ"
      },
      {
        "label": "Payload 4",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Payload-4.jpg",
        "gameId": "-OI92q-AFMrEf80LEXwC"
      },
      {
        "label": "Payload 5",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Payload-5.jpg",
        "gameId": "-OI93rGuq71h6XstPCrW"
      },
      {
        "label": "Two Fronts 1",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Two-Fronts-1.jpg",
        "gameId": "-OI95dnqzLBmE-zhFcSB"
      },
      {
        "label": "Two Fronts 2",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Two-Fronts-2.jpg",
        "gameId": "-OI97ZyxPdA5uYsV9G_z"
      },
      {
        "label": "Two Fronts 3",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Two-Fronts-3.jpg",
        "gameId": "-OI9AdQFe1VD9f4hMeIy"
      },
      {
        "label": "Two Fronts 4",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Two-Fronts-4.jpg",
        "gameId": "-OI9DBe9DBRX77E-q9Fx"
      },
      {
        "label": "Two Fronts 5",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Two-Fronts-5.jpg",
        "gameId": "-OI9FJ_5j7fgnPU1qEqo"
      },
      {
        "label": "Best Laid Plans 1",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Best-Laid-Plans-1.jpg",
        "gameId": "-OI9GjTQrtuktBOB3GOH"
      },
      {
        "label": "Best Laid Plans 2",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Best-Laid-Plans-2.jpg",
        "gameId": "-OI9HpjG7xJfejR8PTHn"
      },
      {
        "label": "Best Laid Plans 3",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Best-Laid-Plans-3.jpg",
        "gameId": "-OI9KBh3zol2Tw4K5Lxp"
      },
      {
        "label": "Best Laid Plans 4",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Best-Laid-Plans-4.jpg",
        "gameId": "-OI9L7tgb15JZnP04jkh"
      },
      {
        "label": "Best Laid Plans 5",
        "imageUrl": "/Warmahordes/maps/Necrotyr-Best-Laid-Plans-5.jpg",
        "gameId": "-OI9MA2vpyBSZZNPqJCu"
      }
    ],
  },
  {
    label: 'Mario',
    credit: (
      <Fragment>
        These maps were designed for Steamroller 2024 by Mario.
      </Fragment>
    ),
    maps: [
      {
        label: 'Tower Siege',
        imageUrl: '/Warmahordes/maps/Mario-Tower-Siege.jpg',
        gameId: '-NoKj2ZxWyl1nAx9Kadb',
      },
      {
        label: 'Village Defense',
        imageUrl: '/Warmahordes/maps/Mario-Village-Defense.jpg',
        gameId: '-NoJcZcnL0E6iCRdAVYe',
      },
      {
        label: 'Beach Assault',
        imageUrl: '/Warmahordes/maps/Mario-Beach-Assault.jpg',
        gameId: '-NoJQDe2lj4Qc1KMghnJ',
      },
      {
        label: 'The Path More Friequently Travelled',
        imageUrl:
          '/Warmahordes/maps/Mario-The-Path-More-Friequently-Travelled.jpg',
        gameId: '-NoJU4nsqNfgSj9EpeUp',
      },
      {
        label: 'Volcano Town',
        imageUrl: '/Warmahordes/maps/Mario-Volcano-Town.jpg',
        gameId: '-NoJXduy7h0tPivNufQM',
      },
      {
        label: 'Ranger Station',
        imageUrl: '/Warmahordes/maps/Mario-Ranger-Station.jpg',
        gameId: '-NoJc7Pmk-JgS1-_S_cG',
      },
      {
        label: 'Hamlet in the Hills',
        imageUrl: '/Warmahordes/maps/Mario-Hamlet-in-the-Hills.jpg',
        gameId: '-NoNAhrknqfCB5VmjJwV',
      },
      {
        label: 'Battle in the streets',
        imageUrl: '/Warmahordes/maps/Mario-Battle-in-the-streets.jpg',
        gameId: '-NoKVPTCeknNtGI6sHD2',
      },
      {
        label: 'The Castle that Sank into the Swamp',
        imageUrl:
          '/Warmahordes/maps/Mario-The-Castle-that-Sank-into-the-Swamp.jpg',
        gameId: '-NoKdKUCmUMlFKIY3JMD',
      },
      {
        label: 'Abandoned outpost',
        imageUrl: '/Warmahordes/maps/Mario-Abandoned-outpost.jpg',
        gameId: '-NoKi9bEYsca3_WPbhQx',
      },
      {
        label: 'Approaching Storm',
        imageUrl: '/Warmahordes/maps/Mario-Approaching-Storm.jpg',
        gameId: '-NoQ2u_kmJUXPROO4Uqf',
      },
      {
        label: 'Town Square',
        imageUrl: '/Warmahordes/maps/Mario-Town-Square.jpg',
        gameId: '-NoQ7SUFDNJq_X3unRMX',
      },
      {
        label: "Hero's Graveyard",
        imageUrl: '/Warmahordes/maps/Mario-Heros-Graveyard.jpg',
        gameId: '-NoQApI5J6VPbJEwQpoc',
      },
      {
        label: 'Necrotite Mining Facility',
        imageUrl: '/Warmahordes/maps/Mario-Necrotite-Mining-Facility.jpg',
        gameId: '-NoQEWvScXsAEl4nX4hM',
      },
    ],
  },
  {
    label: 'Line of Sight',
    credit: (
      <Fragment>
        These maps were contributed by{' '}
        <a
          href="https://twitter.com/chocobsessed_ll"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          Chocobssessed
        </a>{' '}
        and{' '}
        <a
          href="http://twitter.com/los_jaden"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          Jaden
        </a>{' '}
        from{' '}
        <a
          href="https://loswarmachine.com"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          Line of Sight
        </a>
        .
      </Fragment>
    ),
    maps: [
      {
        label: 'A House Divided',
        gameId: '-MDUzjNWI1NcT4ZsENHH',
        imageUrl: '/Warmahordes/maps/AHouseDivided.jpg',
      },
      {
        label: 'Between A Rock And A Burnt Place',
        gameId: '-MKvDS3lvJXB8D8fRkJU',
        imageUrl: '/Warmahordes/maps/BetweenARockAndABurntPlace.jpg',
      },
      {
        label: 'Between Two Cottages',
        gameId: '-MDZhjmvK3VjaG2CWCMC',
        imageUrl: '/Warmahordes/maps/BetweenTwoCottages.jpg',
      },
      {
        label: 'Boarding Action',
        gameId: '-MDWn5tEVDpkKHzYBZN7',
        imageUrl: '/Warmahordes/maps/BoardingAction.jpg',
      },
      {
        label: 'Castle Siege',
        gameId: '-MKvBpk_Lmyxa2tq_34l',
        imageUrl: '/Warmahordes/maps/CastleSiege.jpg',
      },
      {
        label: 'Corridors',
        gameId: '-MKvC_svhWPxtV_ENp4U',
        imageUrl: '/Warmahordes/maps/Corridors.jpg',
      },
      {
        label: 'Desert',
        gameId: '-MDWgjgff8J3Ba1Jskip',
        imageUrl: '/Warmahordes/maps/Desert.jpg',
      },
      {
        label: 'Fall of Llael',
        gameId: '-MDWe2sg_p1nfToy0Fzd',
        imageUrl: '/Warmahordes/maps/FallOfLlael.jpg',
      },
      {
        label: 'Forest’s Edge',
        gameId: '-MDV5hqLREMIMP53FETs',
        imageUrl: '/Warmahordes/maps/ForestsEdge.jpg',
      },
      {
        label: 'Necrofactory',
        gameId: '-MDWkAlctfmppXg-JZ12',
        imageUrl: '/Warmahordes/maps/Necrofactory.jpg',
      },
      {
        label: 'Night on Blight Mountain',
        gameId: '-MDZfG4OxMDozPRFyTbH',
        imageUrl: '/Warmahordes/maps/NightOnBlightMountain.jpg',
      },
      {
        label: 'Outskirts',
        gameId: '-MDWq933N1wup-QL4zf5',
        imageUrl: '/Warmahordes/maps/Outskirts.jpg',
      },
      {
        label: 'Rubble Rumble',
        gameId: '-MKvE4sVJVGaIIbT1OR8',
        imageUrl: '/Warmahordes/maps/RubbleRumble.jpg',
      },
      {
        label: 'Swamp',
        gameId: '-MDWi30UwxiAi8-djLmU',
        imageUrl: '/Warmahordes/maps/Swamp.jpg',
      },
      {
        label: 'The Path Less Traveled',
        gameId: '-MDWoS4800MSwkKC_-ph',
        imageUrl: '/Warmahordes/maps/ThePathLessTraveled.jpg',
      },
      {
        label: 'Three Groves',
        gameId: '-MKvD0S2PkyQXDM8e_ca',
        imageUrl: '/Warmahordes/maps/ThePathLessTraveled.jpg',
      },
      {
        label: 'Trinity',
        gameId: '-MDV6zEJAhH_8cOmyTVR',
        imageUrl: '/Warmahordes/maps/Trinity.jpg',
      },
      {
        label: 'Trench Warfare',
        gameId: '-MDWbmvj64eu5qZedGv5',
        imageUrl: '/Warmahordes/maps/TrenchWarfare.jpg',
      },
      {
        label: 'Volcano',
        gameId: '-MDWcs5WBrHFVCND9YCK',
        imageUrl: '/Warmahordes/maps/Volcano.jpg',
      },
    ],
  },
  {
    label: 'VTC 2021',
    credit: (
      <Fragment>
        The{' '}
        <a
          href="https://knightsmachine.com/blogs/general/vtc-2021-is-coming"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          2021 Virtual Team Championship
        </a>{' '}
        is done but you can still{' '}
        <a
          href="https://docs.google.com/document/d/19JlGdqarkK5Gi6o2ksNaq91jMlkg5levMoN0LbJkw2M/edit?usp=sharing"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          get the map links from this doc
        </a>
        .
      </Fragment>
    ),
    maps: [],
  },
  {
    label: 'VTC 2020',
    credit: (
      <Fragment>
        The{' '}
        <a
          href="https://knightsmachine.com/2020/06/12/vtc-announcement/"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          2020 Virtual Team Championship
        </a>{' '}
        is done but you can still{' '}
        <a
          href="https://docs.google.com/document/d/19JlGdqarkK5Gi6o2ksNaq91jMlkg5levMoN0LbJkw2M/edit?usp=sharing"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          get the map links from this doc
        </a>
        .
      </Fragment>
    ),
    maps: [],
  },
];
