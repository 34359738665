export default [
  {
    id: 'Warmachine',
    image: '/Warmahordes/mk4.png',
  },
  {
    id: 'GuildBall',
    image: '/GuildBall/banner.jpg',
  },
  {
    id: 'Warmahordes',
    image: '/Warmahordes/banner.jpg',
  },
];
