import React, { Component, Fragment } from 'react';
import ReactGA from 'react-ga4';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import CasinoIcon from '@mui/icons-material/Casino';

import { drawerWidth } from './SelectionDrawer';
import Dialog from '../WTDialog';

class RollDialog extends Component {
  state = {
    selectedPlayer: 0,
    rolls: [],
  };
  componentDidMount() {
    window.addEventListener('keydown', this.keydown);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.keydown);
  }
  keydown = (e) => {
    const { extensions } = this.props;
    if (
      (document.activeElement &&
        ['TEXTAREA', 'INPUT'].includes(document.activeElement.tagName)) ||
      document.querySelector('[role="dialog"]')
    ) {
      return;
    }
    for (var i = 0; i < 10; i++) {
      if (e.code === `Digit${i}` || e.code === `Numpad${i}`) {
        if (e.metaKey || e.ctrlKey || e.altKey) return;

        ReactGA.event({
          category: 'Keyboard Shortcut',
          action: 'Roll',
          value: i,
        });

        if (extensions.numberKeyBindings) {
          extensions.numberKeyBindings({
            log: this.log,
            num: i,
            rollDice: this.rollDice,
            shift: e.shiftKey,
          });
        } else {
          let dice = i;
          if (dice === 0) dice = 10;
          if (e.shiftKey) dice = dice + 10;

          return this.roll(dice, 6);
        }
      }
    }
  };
  roll = (num, die, label) => {
    ReactGA.event({
      category: 'Roll',
      action: `${num}d${die}`,
    });
    let text;
    die = parseInt(die, 10);
    let rolls = this.rollDice(num, die);
    text = `${num}d${die}: ${rolls.join(' + ')}`;
    if (rolls.length > 1) {
      let total = rolls.reduce((total, num) => {
        return total + num;
      }, 0);
      text = `${text} = ${total}`;
    }
    this.log(text, label);
    this.close();
  };
  log = (text, label) => {
    this.props.addLog(text, this.secondary(label));
  };
  secondary(label) {
    let { selectedPlayer } = this.state;
    const playerLabel = this.props.game.attrs[`player${selectedPlayer}Label`];
    return [playerLabel, label].filter((e) => e).join(' - ');
  }
  rollDice(num, die) {
    let rolls = [];
    for (var i = 0; i < num; i++) {
      rolls.push(Math.floor(Math.random() * die) + 1);
    }
    return rolls;
  }
  close = () => {
    this.props.onClose();
    this.setState({ rolls: [] });
  };
  render() {
    const { extensions, game, open, system } = this.props;

    return (
      <Fragment>
        <Fab
          color="primary"
          style={{
            position: 'fixed',
            margin: 8 * 2,
            right: drawerWidth,
            bottom: 0,
          }}
          onClick={this.props.onRoll}
        >
          <CasinoIcon />
        </Fab>
        <Dialog open={open} maxWidth="sm" onClose={this.close}>
          <DialogTitle>Roll</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Who’s Rolling?</FormLabel>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={`${this.state.selectedPlayer}`}
                    onChange={(e) =>
                      this.setState({
                        selectedPlayer: parseInt(e.target.value, 10),
                      })
                    }
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label={game.attrs.player1Label}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio color="primary" />}
                      label={game.attrs.player2Label}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio color="primary" />}
                      label="Not selected"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {extensions.RollSelection ? (
                <extensions.RollSelection
                  addLog={this.log}
                  rollDice={this.rollDice}
                  onClose={this.close}
                />
              ) : (
                <Fragment>
                  <Grid item xs={12} style={{ marginTop: 16 }}>
                    <FormLabel component="legend">Roll d6</FormLabel>
                  </Grid>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num) => (
                    <Grid key={num} item xs={2}>
                      <Button onClick={() => this.roll(num, 6)}>{num}d6</Button>
                    </Grid>
                  ))}
                </Fragment>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.close}>Close</Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

export default RollDialog;
