import React, { Component, Fragment } from 'react';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Dialog from '../../../WTDialog';

export default class Header extends Component {
  state = {};
  playerSection(player) {
    const { game } = this.props;
    if (!game.attrs[`player${player}GamePlan`]) return null;
    return (
      <Button
        color="inherit"
        style={{ paddingRight: 0, paddingBottom: 0, paddingTop: 0 }}
        onClick={() => {
          this.setState({ showPlayer: player });
        }}
      >
        <span
          style={{
            maxWidth: 150,
            textAlign: 'center',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {game.attrs[`player${player}Label`]}
          <br />
          {game.attrs[`player${player}GamePlan`].label}
        </span>
      </Button>
    );
  }
  render() {
    const { game } = this.props;
    const { showPlayer } = this.state;
    if (!game.attrs.gamePlansRevealed) return null;

    return (
      <Fragment>
        {this.playerSection(1)}
        {this.playerSection(2)}
        {showPlayer && (
          <Dialog
            open
            fullScreen
            onClose={() => this.setState({ showPlayer: null })}
          >
            <DialogContent>
              <img
                alt=""
                src={game.attrs[`player${showPlayer}GamePlan`].imageUrl}
                style={{ objectFit: 'contain', height: '100%', width: '100%' }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ showPlayer: null })}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Fragment>
    );
  }
}
