import React, { Component, Fragment } from 'react';
import ReactGA from 'react-ga4';

import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import AddEffectDialog from '../game/AddEffectDialog';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

export default class Conditions extends Component {
  state = {
    addEffectOpen: false,
  };
  componentDidMount() {
    document.addEventListener('keyup', this.keyup);
  }
  componentWillUnmount() {
    document.removeEventListener('keyup', this.keyup);
  }
  keyup = (e) => {
    if (
      (document.activeElement &&
        ['INPUT', 'TEXTAREA'].includes(document.activeElement.tagName)) ||
      document.querySelector('[role="dialog"]')
    )
      return;
    if (e.code === 'Semicolon') {
      ReactGA.event({
        category: 'Keyboard Shortcut',
        action: 'Open Effects',
      });
      this.setState({ addEffectOpen: true });
    }
  };
  handleClick(condition) {
    let { selection } = this.props;
    let conditions = selection.attrs.conditions;
    if (conditions.includes(condition)) {
      conditions = conditions.replace(condition, '');
    } else {
      conditions = conditions + condition;
    }
    selection.update({ conditions: conditions });
  }
  removeEffect(i) {
    const { selection } = this.props;
    const { effects } = selection.attrs;
    const removedEffect = effects[i];
    const newEffects = effects.slice(0);
    newEffects.splice(i, 1);
    this.props.log(
      `Removed ${removedEffect.text} from ${selection.attrs.label}`
    );
    selection.update({ effects: newEffects });
  }
  render() {
    let { effects, selection } = this.props;

    return (
      <Fragment>
        <List
          style={{ marginLeft: -8, marginRight: -8 }}
          dense
          subheader={<ListSubheader>Effects</ListSubheader>}
        >
          {(selection.attrs.effects || []).map((effect, i) => {
            const labelText =
              effect.labelText ||
              effect.text
                .split(/\s/)
                .reduce(
                  (response, word) =>
                    (response +=
                      word.indexOf('#') === 0 ? word : word.slice(0, 1)),
                  ''
                )
                .slice(0, 2);
            return (
              <ListItem
                key={`effect${i}`}
                button
                onClick={() => {
                  alert(
                    `${effect.text}${effect.description ? '\n\n' + effect.description : ''}`
                  );
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    alt={effect.text}
                    src={effect.icon}
                    style={{ backgroundColor: effect.color }}
                  >
                    {labelText}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>{effect.text}</ListItemText>
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => {
                      this.removeEffect(i);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
          <ListItem
            button
            onClick={() => {
              this.setState({ addEffectOpen: true });
            }}
          >
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText>Add Effect</ListItemText>
          </ListItem>
        </List>
        <AddEffectDialog
          effects={effects}
          game={this.props.game}
          log={this.props.log}
          selection={selection}
          open={this.state.addEffectOpen}
          onClose={() => {
            this.setState({ addEffectOpen: false });
          }}
        />
      </Fragment>
    );
  }
}
