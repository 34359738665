export default [
  { label: 'Deviation Template', type: 'DeviationTemplate' },
  {
    label: 'Spray Template',
    type: 'SprayTemplate',
    attrs: { modelOffset: true },
  },
  {
    label: 'Feat Token',
    type: 'Token',
    attrs: { label: 'Feat', fill: '#f44336', width: 1.9685 },
  },
  {
    label: 'Wall',
    type: 'WallTemplate',
    attrs: { label: 'Wall', fill: '#9e9e9e', height: 0.75, width: 4 },
  },
];
