import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { ref, set } from '@firebase/database';

import { copyGame, db } from '../initFirebase';

export default class CopyGame extends Component {
  state = {
    gameId: null,
  };
  constructor(props) {
    super(props);
    let { id } = props.match.params;
    copyGame(id, props.user.uid).then(({ gameId, inviteCode }) => {
      set(ref(db, `/gamesByUser/${props.user.uid}/${gameId}`), {
        inviteCode,
        sort: new Date().getTime(),
      }).then(() => this.setState({ gameId }));
    });
  }
  render() {
    const { gameId } = this.state;
    if (!gameId) {
      return null;
    }
    return <Redirect to={`/games/${gameId}`} />;
  }
}
