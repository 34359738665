import React, { Component, Fragment } from 'react';

import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import withWidth from '../withWidth';
import red from '@mui/material/colors/red';

import AlarmIcon from '@mui/icons-material/Alarm';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import CasinoIcon from '@mui/icons-material/Casino';
import CategoryIcon from '@mui/icons-material/Category';
import EditIcon from '@mui/icons-material/Edit';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FeedbackIcon from '@mui/icons-material/Feedback';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ShareIcon from '@mui/icons-material/Share';
import SubjectIcon from '@mui/icons-material/Subject';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

import ActionButton from './ActionButton';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

class ActionDrawer extends Component {
  support = () => {
    window.location.href = 'https://www.patreon.com/bePatron?c=2057410';
  };
  feedback = () => {
    window.location.href = 'https://forms.gle/9G8ijjR2xtTxpdZg6';
  };
  stopPropagation(callback) {
    return function (e) {
      e.stopPropagation();
      callback(e);
    };
  }
  content() {
    const { extensions, editMapMode } = this.props;
    return (
      <Fragment>
        <List style={{ paddingTop: 64 }}>
          <ActionButton
            onClick={this.props.onInvite}
            icon={ShareIcon}
            primary="Invite"
          />
          <ActionButton
            onClick={this.props.onAddShape}
            icon={PhotoLibraryIcon}
            primary="Add Terrain"
          />
          <ActionButton
            icon={EditIcon}
            toggled={editMapMode}
            onClick={this.props.onEditMap}
            primary="Move Terrain"
          />
          <ActionButton
            icon={AlarmIcon}
            onClick={this.props.onClock}
            primary="Clock"
          />
        </List>
        <Divider />
        <List>
          {(extensions.gameActions || []).map((GameAction, i) => (
            <GameAction
              key={i}
              game={this.props.game}
              log={this.props.addLog}
              onAddToken={this.props.onAddToken}
              addWidget={this.props.addWidget}
              color={this.props.color}
              connection={this.props.connection}
              onChangeColor={this.props.onChangeColor}
              user={this.props.user}
            />
          ))}
          {extensions.deployment && (
            <ActionButton
              toggled={this.props.deploy}
              icon={ViewHeadlineIcon}
              onClick={this.props.onToggleDeploymentLines}
              primary="Deployment Lines"
            />
          )}
          <ActionButton
            icon={PersonAddIcon}
            onClick={this.props.onAddModel}
            primary="Add Model"
          />
          <ActionButton
            icon={CategoryIcon}
            onClick={this.props.onAddWidget}
            primary="Add Widget"
          />
          <ActionButton
            icon={CasinoIcon}
            onClick={this.props.onRoll}
            primary="Roll"
          />
          <ActionButton
            icon={SubjectIcon}
            onClick={this.props.onLog}
            primary="Log"
          />
          <ActionButton
            icon={ZoomInIcon}
            onClick={this.stopPropagation(this.props.onZoomIn)}
            primary="Zoom In"
          />
          <ActionButton
            icon={ZoomOutIcon}
            onClick={this.stopPropagation(this.props.onZoomOut)}
            primary="Zoom Out"
          />
          <ActionButton
            icon={KeyboardIcon}
            onClick={this.props.onKeyboardShortcuts}
            primary="Keyboard Shortcuts"
          />
        </List>
        <Divider />
        <List style={{ marginBottom: 50 }}>
          <ActionButton
            icon={AutorenewIcon}
            onClick={() => {
              if (window.confirm('Are you sure?')) {
                this.props.onResetGame();
              }
            }}
            primary="Reset Game"
          />
          <ActionButton
            icon={FeedbackIcon}
            onClick={this.feedback}
            primary="Feedback / Bugs"
          />
          <ActionButton
            icon={FavoriteBorderIcon}
            iconColor={red[400]}
            onClick={this.support}
            primary="Support War Table"
          />
          <ActionButton
            icon={ExitToAppIcon}
            onClick={this.props.onExit}
            primary="Exit"
          />
        </List>
      </Fragment>
    );
  }
  render() {
    const { open } = this.props;

    return (
      <Fragment>
        <Drawer
          style={{ whiteSpace: 'nowrap', width: drawerWidth }}
          variant="temporary"
          open={open}
          onClose={this.props.onClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
          }}
          onClick={this.props.onClose}
        >
          {this.content()}
        </Drawer>
        <Drawer
          variant="permanent"
          style={{ whiteSpace: 'nowrap', width: drawerWidth }}
          onClose={this.props.onClose}
          sx={{
            display: { xs: 'none', sm: 'block' },
          }}
          open={open}
        >
          {this.content()}
        </Drawer>
      </Fragment>
    );
  }
}

export default withWidth()(ActionDrawer);
