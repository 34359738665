import { ref, runTransaction } from '@firebase/database';

import { db } from '../../initFirebase';

export default function ({ player, game, log }) {
  let markedExpired = false;
  runTransaction(ref(db, game.path), (currentData) => {
    if (currentData && !currentData[`clockPlayer${player}Expired`]) {
      markedExpired = true;
      currentData[`clockPlayer${player}Expired`] = true;
    } else {
      markedExpired = false;
    }
    return currentData;
  }).then(() => {
    if (markedExpired) {
      log(`${game.attrs[`player${player}Label`]} clock expired`);
    }
  });
}
