import React, { Component, Fragment } from 'react';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import ColorPicker from './ColorPicker';
import Conditions from '../map/Conditions';
import NumberInput from '../NumberInput';

export default class EditSelectionState extends Component {
  editShapeState() {
    const selection = this.props.selection;
    return (
      <React.Fragment>
        {selection.attrs.text !== undefined && (
          <TextField
            name="text"
            label="Text"
            value={selection.attrs.text}
            onChange={this.handleChange}
            inputRef={(node) => {
              if (!node) {
                return;
              }
              node.addEventListener('click', function () {
                this.select();
              });
            }}
            fullWidth
          />
        )}
        {selection.attrs.label !== undefined && (
          <TextField
            name="label"
            label="Label"
            value={selection.attrs.label}
            onChange={this.handleChange}
            inputRef={(node) => {
              if (!node) {
                return;
              }
              node.addEventListener('click', function () {
                this.select();
              });
            }}
            fullWidth
            variant="standard"
          />
        )}
        <TextField
          name="x"
          type="number"
          label="X"
          value={selection.attrs.x}
          onChange={this.handleChange}
          inputRef={(node) => {
            if (!node) {
              return;
            }
            node.addEventListener('click', function () {
              this.select();
            });
          }}
          style={{ width: '45%', marginRight: '5%' }}
          margin="normal"
        />
        <TextField
          name="y"
          type="number"
          label="Y"
          value={selection.attrs.y}
          onChange={this.handleChange}
          inputRef={(node) => {
            if (!node) {
              return;
            }
            node.addEventListener('click', function () {
              this.select();
            });
          }}
          style={{ width: '45%', marginRight: '5%' }}
          margin="normal"
        />
        {selection.attrs.width !== undefined && (
          <TextField
            name="width"
            type="number"
            label="Width"
            value={selection.attrs.width}
            onChange={this.handleChange}
            inputRef={(node) => {
              if (!node) {
                return;
              }
              node.addEventListener('click', function () {
                this.select();
              });
            }}
            style={{ width: '45%', marginRight: '5%' }}
            margin="normal"
          />
        )}
        {selection.attrs.height !== undefined && (
          <TextField
            name="height"
            type="number"
            label="Height"
            value={selection.attrs.height}
            onChange={this.handleChange}
            inputRef={(node) => {
              if (!node) {
                return;
              }
              node.addEventListener('click', function () {
                this.select();
              });
            }}
            style={{ width: '45%', marginRight: '5%' }}
            margin="normal"
          />
        )}
        {selection.attrs.facing !== undefined && (
          <TextField
            name="facing"
            type="number"
            label="Facing"
            value={selection.attrs.facing}
            onChange={this.handleChange}
            inputRef={(node) => {
              if (!node) {
                return;
              }
              node.addEventListener('click', function () {
                this.select();
              });
            }}
            style={{ width: '45%', marginRight: '5%' }}
            margin="normal"
          />
        )}
        {selection.attrs.stroke !== undefined && (
          <FormControl
            style={{ width: '45%', marginRight: '5%' }}
            margin="normal"
          >
            <ColorPicker
              label="Stroke"
              name="stroke"
              includeWhite
              value={selection.attrs.stroke}
              onChange={this.handleChange}
            />
          </FormControl>
        )}
        {selection.attrs.fill !== undefined && (
          <FormControl
            style={{ width: '45%', marginRight: '5%' }}
            margin="normal"
          >
            <ColorPicker
              label="Fill"
              name="fill"
              value={selection.attrs.fill}
              onChange={this.handleChange}
              includeWhite
              includeTransparent
            />
          </FormControl>
        )}
      </React.Fragment>
    );
  }
  editTokenState() {
    const { extensions, effects, game, selection } = this.props;
    return (
      <Fragment>
        <Grid container spacing={1} style={{ marginBottom: 16 }}>
          {extensions.Damage ? (
            <Grid item xs={12}>
              <extensions.Damage
                game={game}
                selection={selection}
                onChange={this.handleChange}
              />
            </Grid>
          ) : (
            <Grid item xs={4} md={12}>
              <NumberInput
                fullWidth
                name="resource1"
                min="0"
                label={`${selection.attrs.resource1Label || game.attrs.resource1Label}${selection.attrs.resource1Max ? ` (Max ${selection.attrs.resource1Max})` : ''}`}
                value={selection.attrs.resource1}
                onChange={this.handleChange}
              />
            </Grid>
          )}
          <Grid item xs={4} md={6}>
            <NumberInput
              fullWidth
              name="resource2"
              min="0"
              label={
                selection.attrs.resource2Label || game.attrs.resource2Label
              }
              value={selection.attrs.resource2}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={4} md={6}>
            <NumberInput
              fullWidth
              name="resource3"
              min="0"
              label={
                selection.attrs.resource3Label || game.attrs.resource3Label
              }
              value={selection.attrs.resource3}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <NumberInput
              fullWidth
              name="auraRadius"
              label="Aura Size"
              value={selection.attrs.auraRadius || 0}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <ColorPicker
                label="Aura Color"
                name="auraColor"
                value={selection.attrs.auraColor || ''}
                onChange={this.handleChange}
                includeTransparent
              />
            </FormControl>
          </Grid>
          {selection.attrs.maxMeleeRange !== undefined && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel variant="standard">Melee Range</InputLabel>
                <Select
                  fullWidth
                  name="maxMeleeRange"
                  value={selection.attrs.maxMeleeRange}
                  onChange={this.handleChange}
                  variant="standard"
                >
                  {[
                    0, 0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                    16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                  ].map((i) => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
        <Conditions
          effects={effects}
          game={game}
          log={this.props.log}
          selection={selection}
          onAddEffect={this.props.onAddEffect}
        />
      </Fragment>
    );
  }
  editWidgetState() {
    const selection = this.props.selection;
    return (
      <React.Fragment>
        {selection.attrs.label !== undefined && (
          <TextField
            name="label"
            label="Label"
            value={selection.attrs.label}
            onChange={this.handleChange}
            inputRef={(node) => {
              if (!node) {
                return;
              }
              node.addEventListener('click', function () {
                this.select();
              });
            }}
            margin="normal"
            fullWidth
            variant="standard"
          />
        )}
        {selection.attrs.size !== undefined && (
          <NumberInput
            name="size"
            label="Size"
            min={0}
            value={selection.attrs.size}
            onChange={this.handleChange}
            margin="normal"
            fullWidth
          />
        )}
        {selection.attrs.value !== undefined && (
          <NumberInput
            name="value"
            label="Value"
            min={0}
            value={selection.attrs.value}
            onChange={this.handleChange}
            margin="normal"
            fullWidth
          />
        )}
        {selection.attrs.fill !== undefined && (
          <FormControl fullWidth margin="normal">
            <ColorPicker
              label="Background"
              name="fill"
              value={selection.attrs.fill}
              onChange={this.handleChange}
            />
          </FormControl>
        )}
      </React.Fragment>
    );
  }
  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.type === 'number') {
      value = parseFloat(e.target.value, 10);
      if (isNaN(value)) {
        value = '';
      }
    }
    if (
      this.props.selection.attrs.widgetType === 'Counter' &&
      e.target.name === 'value'
    ) {
      this.props.log(
        `${this.props.selection.attrs.label} counter changed to ${value}`
      );
    }
    this.props.selection.update({ [e.target.name]: value });
  };
  render() {
    let selection = this.props.selection;
    if (selection && selection.type === 'shape') {
      return this.editShapeState();
    }
    if (selection && selection.type === 'token') {
      return this.editTokenState();
    }
    if (selection && selection.type === 'widget') {
      return this.editWidgetState();
    }
    return null;
  }
}
