import { sizeFor } from '../../../map/Token';

export default function modelToToken(model) {
  const { width, height, shape } = sizeFor(model.size);
  const tokenAttrs = {
    label: model.alias || model.name,
    deletable: true,
    width,
    height,
    shape,
  };
  if (model.avatar) {
    tokenAttrs.imageUrl = model.avatar;
  }
  if (model.health) {
    tokenAttrs.resource1 = model.health;
    tokenAttrs.resource1Max = model.health;
  }
  if (model.influenceMax !== undefined) {
    tokenAttrs.resource2 = 0;
    tokenAttrs.influenceMax = tokenAttrs.resource2Max = model.influenceMax;
    tokenAttrs.influence = model.influence;
  }
  if (model.cards) {
    tokenAttrs.cards = model.cards;
  }
  tokenAttrs.maxMeleeRange = model.maxMeleeRange || 0;
  return tokenAttrs;
}
