import React, { Component } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';

import { auth } from '../initFirebase';
import Dialog from '../WTDialog';

class SignUpDialog extends Component {
  state = {
    email: '',
    password: '',
    emailError: '',
    passwordError: '',
  };
  signUp() {
    this.setState({ emailError: '', passwordError: '' });
    createUserWithEmailAndPassword(auth, this.state.email, this.state.password)
      .then(() => {
        let user = auth.currentUser;
        sendEmailVerification(user);
      })
      .catch((error) => {
        if (error.code === 'auth/weak-password') {
          this.setState({ passwordError: error.message });
        } else {
          this.setState({ emailError: error.message });
        }
      });
  }
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Sign Up</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography gutterBottom>
              Please enter your email address and password. By proceeding, you
              accept War Table’s{' '}
              <a
                href="/terms-and-conditions.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a href="/privacy.html" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
              .
            </Typography>
            <Typography gutterBottom>
              Already have an account?{' '}
              <Button onClick={this.props.onSignIn}>Sign In</Button>
            </Typography>
          </DialogContentText>
          <TextField
            autoFocus
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            margin="normal"
            value={this.state.email}
            onChange={({ target }) => {
              this.setState({ email: target.value });
            }}
            error={this.state.emailError.length > 0}
            helperText={this.state.emailError}
          />
          <TextField
            id="password"
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            value={this.state.password}
            onChange={({ target }) => {
              this.setState({ password: target.value });
            }}
            error={this.state.passwordError.length > 0}
            helperText={this.state.passwordError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">
            Close
          </Button>
          <Button
            onClick={this.signUp.bind(this)}
            color="primary"
            variant="contained"
          >
            Sign Up
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default SignUpDialog;
