export default {
  id: 'Bushido',
  title: 'Bushido - Risen Sun',
  description:
    'Bushido - Risen Sun is a game of savage battles, of cunning stratagems, where debts of honour are paid in blood.',
  disclaimer: '* War Table is not affiliated with GCT Studios.',
  banner: '/Bushido/banner.jpg',
  rules: 'https://gctstudios.com/pages/bushido-rules-videos',
  gameSettings: {
    system: 'Bushido',
    width: 24,
    height: 24,
    resource1Label: 'Health',
    resource2Label: 'Ki',
    resource3Label: '',
    name: 'Bushido',
    backgroundImageUrl: '/Bushido/backgrounds/bushido_forest.jpg',
    showArcs: true,
    player1Color: '#673ab7',
    player1Label: 'Player 1',
    player2Color: '#00bcd4',
    player2Label: 'Player 2',
    clockEnabled: false,
    clockPaused: true,
    clockActivePlayer: 1,
    clockPlayer1TimeRemaining: 60 * 60 * 1000,
    clockPlayer2TimeRemaining: 60 * 60 * 1000,
  },
};
