import AddModelDialog from './AddModelDialog';
import commands from './commands';
import DamageGrid, { applyGridDamage } from './DamageGrid';
import deployment from './deployment';
import DeviationTemplate from './DeviationTemplate';
import gameActions from './gameActions';
import modelToToken from './modelToToken';
import numberKeyBindings from './numberKeyBindings';
import RollSelection from './RollSelection';
import SprayTemplate from './SprayTemplate';
import Terrain from './Terrain';

export default {
  applyDamage: applyGridDamage,
  AddModelDialog,
  commands,
  Damage: DamageGrid,
  deployment,
  gameActions,
  keyboardShortcutsGeneral: [
    { label: 'Roll X dice (damage)', icon: 'Shift + 1 - 9' },
  ],
  modelToToken,
  numberKeyBindings,
  RollSelection,
  Terrain,
  widgets: {
    DeviationTemplate,
    SprayTemplate,
  },
};
