import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import HelpIcon from '@mui/icons-material/Help';

import GameScreen from './GameScreen';
import { loadextensions, loadSettings } from '../modules';
import { createGame } from '../initFirebase';

export default class extends Component {
  state = {};
  async componentDidMount() {
    const { moduleId } = this.props.match.params;
    const module = await loadSettings(moduleId);
    const extensions = await loadextensions(moduleId);
    this.setState({ extensions, module });
  }
  onPlay = async () => {
    const { user } = this.props;
    const { module, extensions } = this.state;
    const connection = await createGame(user.uid, module.gameSettings || {});
    if (extensions.setup) {
      await extensions.setup(connection);
    }
    this.setState({ gameId: connection.id });
  };
  render() {
    const { gameId, module } = this.state;
    if (gameId) {
      return <Redirect to={`/games/${gameId}`} />;
    }
    if (!module) {
      return;
    }

    return (
      <GameScreen
        onPlay={this.onPlay}
        logoPath={module.banner}
        system={module.id}
        description={module.description}
        footer={module.disclaimer}
      >
        {module.discord && (
          <ListItem
            button
            component="a"
            target="_blank"
            rel="no-referrer no-opener"
            href={module.discord}
          >
            <ListItemIcon>
              <ChatBubbleOutlineIcon />
            </ListItemIcon>
            <ListItemText>Find opponents on Discord</ListItemText>
          </ListItem>
        )}

        {module.rules && (
          <ListItem
            button
            component="a"
            target="_blank"
            rel="no-referrer no-opener"
            href={module.rules}
          >
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText>Learn to play</ListItemText>
          </ListItem>
        )}
      </GameScreen>
    );
  }
}
