import { Component } from 'react';
import { fabric } from 'fabric-with-gestures';

import { LAYER_PATHS } from './Layers';

export default class Path extends Component {
  points() {
    const { pixelsPerInch, waypoints } = this.props;
    return waypoints.map((waypoint) => {
      return {
        x: waypoint.x * pixelsPerInch,
        y: waypoint.y * pixelsPerInch,
        doubleCost: waypoint.doubleCost,
      };
    });
  }
  componentWillUnmount() {
    if (!this.object) return;
    this.props.canvas.remove(this.object);
  }
  componentDidMount() {
    this.renderLine();
  }
  componentDidUpdate() {
    this.renderLine();
  }
  renderLine() {
    const { canvas, pixelsPerInch, width } = this.props;
    if (this.object) {
      canvas.remove(this.object);
    }
    const circles = [];
    const lines = [];
    const points = this.points();
    const strokeWidth = width * pixelsPerInch;
    points.forEach((point, i) => {
      const nextPoint = points[i + 1] || {};
      const fill =
        i > 0 && point.doubleCost && nextPoint.doubleCost ? '#009688' : '#fff';
      circles.push(
        new fabric.Circle({
          left: point.x,
          top: point.y,
          radius: strokeWidth / 2,
          strokeWidth: 0,
          fill,
          originX: 'center',
          originY: 'center',
        })
      );
      if (i > 0) {
        const lastPoint = points[i - 1];
        const stroke = point.doubleCost ? '#009688' : '#fff';
        lines.push(
          new fabric.Line([lastPoint.x, lastPoint.y, point.x, point.y], {
            stroke,
            strokeWidth: width * pixelsPerInch,
            originX: 'center',
            originY: 'center',
          })
        );
      }
    });
    this.object = new fabric.Group(lines.concat(circles), {
      opacity: 0.3,
      evented: false,
      selectable: false,
      layer: LAYER_PATHS,
    });
    canvas.insertAt(this.object, 1);
  }
  render() {
    return null;
  }
}
