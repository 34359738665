export default [
  {
    name: 'Ball',
    size: '30mm',
    avatar: '/GuildBall/avatars/ball.jpg',
    guilds: [],
  },
  {
    name: 'Midas',
    type: 'Captain',
    guilds: ['Alchemists'],
    size: '30mm',
    avatar: '/GuildBall/avatars/midas.jpg',
    health: 14,
    influence: 4,
    influenceMax: 6,
    cards: ['/GuildBall/cards/midas-0.jpg', '/GuildBall/cards/midas-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Smoke',
    type: 'Captain',
    guilds: ['Alchemists'],
    size: '30mm',
    avatar: '/GuildBall/avatars/smoke.jpg',
    health: 16,
    influence: 4,
    influenceMax: 6,
    cards: ['/GuildBall/cards/smoke-0.jpg', '/GuildBall/cards/smoke-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Soma',
    type: 'Captain',
    guilds: ['Alchemists'],
    size: '30mm',
    avatar: '/GuildBall/avatars/soma.jpg',
    health: 16,
    influence: 4,
    influenceMax: 4,
    cards: ['/GuildBall/cards/soma-0.jpg', '/GuildBall/cards/soma-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Pneuma',
    type: 'Captain',
    guilds: ['Alchemists'],
    size: '30mm',
    avatar: '/GuildBall/avatars/pneuma.jpg',
    health: 10,
    influence: 4,
    influenceMax: 4,
    cards: ['/GuildBall/cards/pneuma-0.jpg', '/GuildBall/cards/pneuma-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Flask',
    type: 'Mascot',
    guilds: ['Alchemists'],
    size: '30mm',
    avatar: '/GuildBall/avatars/flask.jpg',
    health: 7,
    influence: 1,
    influenceMax: 2,
    cards: ['/GuildBall/cards/flask-0.jpg', '/GuildBall/cards/flask-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Naja',
    type: 'Mascot',
    guilds: ['Alchemists'],
    size: '30mm',
    avatar: '/GuildBall/avatars/naja.jpg',
    health: 6,
    influence: 1,
    influenceMax: 3,
    cards: ['/GuildBall/cards/naja-0.jpg', '/GuildBall/cards/naja-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Calculus',
    type: 'Squaddie',
    guilds: ['Alchemists'],
    size: '30mm',
    avatar: '/GuildBall/avatars/calculus.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: [
      '/GuildBall/cards/calculus-0.jpg',
      '/GuildBall/cards/calculus-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Veteran Calculus',
    type: 'Squaddie',
    guilds: ['Alchemists'],
    size: '30mm',
    avatar: '/GuildBall/avatars/veteran_calculus.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: [
      '/GuildBall/cards/vcalculus-0.jpg',
      '/GuildBall/cards/vcalculus-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Crucible',
    type: 'Squaddie',
    guilds: ['Alchemists'],
    size: '40mm',
    avatar: '/GuildBall/avatars/crucible.jpg',
    health: 14,
    influence: 2,
    influenceMax: 3,
    cards: [
      '/GuildBall/cards/crucible-0.jpg',
      '/GuildBall/cards/crucible-1.jpg',
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Kami',
    type: 'Squaddie',
    guilds: ['Alchemists'],
    size: '30mm',
    avatar: '/GuildBall/avatars/kami.jpg',
    health: 12,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/kami-0.jpg', '/GuildBall/cards/kami-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Katalyst',
    type: 'Squaddie',
    guilds: ['Alchemists'],
    size: '40mm',
    avatar: '/GuildBall/avatars/katalyst.jpg',
    health: 24,
    influence: 2,
    influenceMax: 4,
    cards: [
      '/GuildBall/cards/katalyst-0.jpg',
      '/GuildBall/cards/katalyst-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Veteran Katalyst',
    type: 'Squaddie',
    guilds: ['Alchemists'],
    size: '50mm',
    avatar: '/GuildBall/avatars/veteran_katalyst.jpg',
    health: 24,
    influence: 2,
    influenceMax: 3,
    cards: [
      '/GuildBall/cards/vkatalyst-0.jpg',
      '/GuildBall/cards/vkatalyst-1.jpg',
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Mercury',
    type: 'Squaddie',
    guilds: ['Alchemists'],
    size: '30mm',
    avatar: '/GuildBall/avatars/mercury.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/mercury-0.jpg', '/GuildBall/cards/mercury-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Venin',
    type: 'Squaddie',
    guilds: ['Alchemists'],
    size: '30mm',
    avatar: '/GuildBall/avatars/venin.jpg',
    health: 13,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/venin-0.jpg', '/GuildBall/cards/venin-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Vitriol',
    type: 'Squaddie',
    guilds: ['Alchemists'],
    size: '30mm',
    avatar: '/GuildBall/avatars/vitriol.jpg',
    health: 11,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/vitriol-0.jpg', '/GuildBall/cards/vitriol-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Alloy',
    type: 'Squaddie',
    guilds: ['Blacksmiths'],
    size: '30mm',
    avatar: '/GuildBall/avatars/alloy.jpg',
    health: 12,
    influence: 1,
    influenceMax: 4,
    cards: ['/GuildBall/cards/alloy-0.jpg', '/GuildBall/cards/alloy-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Anvil',
    type: 'Squaddie',
    guilds: ['Blacksmiths'],
    size: '40mm',
    avatar: '/GuildBall/avatars/anvil.jpg',
    health: 21,
    influence: 3,
    influenceMax: 3,
    cards: ['/GuildBall/cards/anvil-0.jpg', '/GuildBall/cards/anvil-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Bolt',
    type: 'Squaddie',
    guilds: ['Blacksmiths'],
    size: '30mm',
    avatar: '/GuildBall/avatars/bolt.jpg',
    health: 13,
    influence: 1,
    influenceMax: 3,
    cards: ['/GuildBall/cards/bolt-0.jpg', '/GuildBall/cards/bolt-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Burnish',
    type: 'Squaddie',
    guilds: ['Blacksmiths'],
    size: '40mm',
    avatar: '/GuildBall/avatars/burnish.jpg',
    health: 18,
    influence: 3,
    influenceMax: 3,
    cards: ['/GuildBall/cards/burnish-0.jpg', '/GuildBall/cards/burnish-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Cast',
    type: 'Squaddie',
    guilds: ['Blacksmiths'],
    size: '30mm',
    avatar: '/GuildBall/avatars/cast.jpg',
    health: 13,
    influence: 1,
    influenceMax: 4,
    cards: ['/GuildBall/cards/cast-0.jpg', '/GuildBall/cards/cast-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Cinder',
    type: 'Squaddie',
    guilds: ['Blacksmiths'],
    size: '30mm',
    avatar: '/GuildBall/avatars/cinder.jpg',
    health: 13,
    influence: 1,
    influenceMax: 4,
    cards: ['/GuildBall/cards/cinder-0.jpg', '/GuildBall/cards/cinder-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Veteran Cinder',
    type: 'Squaddie',
    guilds: ['Blacksmiths'],
    size: '30mm',
    avatar: '/GuildBall/avatars/veteran_cinder.jpg',
    health: 12,
    influence: 1,
    influenceMax: 4,
    cards: ['/GuildBall/cards/vcinder-0.jpg', '/GuildBall/cards/vcinder-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Culverin',
    type: 'Squaddie',
    guilds: ['Blacksmiths'],
    size: '40mm',
    avatar: '/GuildBall/avatars/culverin.jpg',
    health: 19,
    influence: 3,
    influenceMax: 3,
    cards: [
      '/GuildBall/cards/culverin-0.jpg',
      '/GuildBall/cards/culverin-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Cutlass',
    type: 'Squaddie',
    guilds: ['Blacksmiths'],
    size: '30mm',
    avatar: '/GuildBall/avatars/cutlass.jpg',
    health: 13,
    influence: 1,
    influenceMax: 3,
    cards: ['/GuildBall/cards/cutlass-0.jpg', '/GuildBall/cards/cutlass-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Farris',
    type: 'Squaddie',
    guilds: ['Blacksmiths'],
    size: '50mm',
    avatar: '/GuildBall/avatars/farris.jpg',
    health: 18,
    influence: 3,
    influenceMax: 3,
    cards: ['/GuildBall/cards/farris-0.jpg', '/GuildBall/cards/farris-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Ferrite',
    type: 'Squaddie',
    guilds: ['Blacksmiths'],
    size: '30mm',
    avatar: '/GuildBall/avatars/ferrite.jpg',
    health: 17,
    influence: 3,
    influenceMax: 3,
    cards: ['/GuildBall/cards/ferrite-0.jpg', '/GuildBall/cards/ferrite-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Furnace',
    type: 'Squaddie',
    guilds: ['Blacksmiths'],
    size: '40mm',
    avatar: '/GuildBall/avatars/furnace.jpg',
    health: 20,
    influence: 3,
    influenceMax: 3,
    cards: ['/GuildBall/cards/furnace-0.jpg', '/GuildBall/cards/furnace-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Hearth',
    type: 'Squaddie',
    guilds: ['Blacksmiths'],
    size: '40mm',
    avatar: '/GuildBall/avatars/hearth.jpg',
    health: 23,
    influence: 3,
    influenceMax: 3,
    cards: ['/GuildBall/cards/hearth-0.jpg', '/GuildBall/cards/hearth-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Iron',
    type: 'Squaddie',
    guilds: ['Blacksmiths'],
    size: '40mm',
    avatar: '/GuildBall/avatars/iron.jpg',
    health: 13,
    influence: 1,
    influenceMax: 4,
    cards: ['/GuildBall/cards/iron-0.jpg', '/GuildBall/cards/iron-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Sledge',
    type: 'Squaddie',
    guilds: ['Blacksmiths'],
    size: '30mm',
    avatar: '/GuildBall/avatars/sledge.jpg',
    health: 13,
    influence: 1,
    influenceMax: 3,
    cards: ['/GuildBall/cards/sledge-0.jpg', '/GuildBall/cards/sledge-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Tapper',
    type: 'Captain',
    guilds: ['Brewers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/tapper.jpg',
    health: 20,
    influence: 3,
    influenceMax: 6,
    cards: ['/GuildBall/cards/tapper-0.jpg', '/GuildBall/cards/tapper-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Scum',
    type: 'Mascot',
    guilds: ['Brewers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/scum.jpg',
    health: 6,
    influence: 1,
    influenceMax: 2,
    cards: ['/GuildBall/cards/scum-0.jpg', '/GuildBall/cards/scum-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Hooper',
    type: 'Squaddie',
    guilds: ['Brewers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/hooper.jpg',
    health: 16,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/hooper-0.jpg', '/GuildBall/cards/hooper-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Spigot',
    type: 'Squaddie',
    guilds: ['Brewers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/spigot.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/spigot-0.jpg', '/GuildBall/cards/spigot-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Friday',
    type: 'Squaddie',
    guilds: ['Brewers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/friday.jpg',
    health: 12,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/friday-0.jpg', '/GuildBall/cards/friday-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Stave',
    type: 'Squaddie',
    guilds: ['Brewers'],
    size: '40mm',
    avatar: '/GuildBall/avatars/stave.jpg',
    health: 23,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/stave-0.jpg', '/GuildBall/cards/stave-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Corker',
    type: 'Captain',
    guilds: ['Brewers'],
    size: '40mm',
    avatar: '/GuildBall/avatars/corker.jpg',
    health: 24,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/corker-0.jpg', '/GuildBall/cards/corker-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Esters',
    type: 'Captain',
    guilds: ['Brewers'],
    size: '40mm',
    avatar: '/GuildBall/avatars/esters.jpg',
    health: 22,
    influence: 4,
    influenceMax: 6,
    cards: ['/GuildBall/cards/esters-0.jpg', '/GuildBall/cards/esters-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Quaff',
    type: 'Mascot',
    guilds: ['Brewers'],
    size: '40mm',
    avatar: '/GuildBall/avatars/quaff.jpg',
    health: 8,
    influence: 1,
    influenceMax: 3,
    cards: ['/GuildBall/cards/quaff-0.jpg', '/GuildBall/cards/quaff-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Veteran Decimate',
    type: 'Squaddie',
    guilds: ['Brewers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/veteran_decimate.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: [
      '/GuildBall/cards/vdecimate-0.jpg',
      '/GuildBall/cards/vdecimate-1.jpg',
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Flea',
    type: 'Squaddie',
    guilds: ['Brewers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/flea.jpg',
    health: 20,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/flea-0.jpg', '/GuildBall/cards/flea-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Mash',
    type: 'Squaddie',
    guilds: ['Brewers'],
    size: '40mm',
    avatar: '/GuildBall/avatars/mash.jpg',
    health: 15,
    influence: 4,
    influenceMax: 6,
    cards: ['/GuildBall/cards/mash-0.jpg', '/GuildBall/cards/mash-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Pint Pot',
    type: 'Squaddie',
    guilds: ['Brewers'],
    size: '40mm',
    avatar: '/GuildBall/avatars/pint_pot.jpg',
    health: 20,
    influence: 2,
    influenceMax: 2,
    cards: ['/GuildBall/cards/pintpot-0.jpg', '/GuildBall/cards/pintpot-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Veteran Spigot',
    type: 'Squaddie',
    guilds: ['Brewers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/veteran_spigot.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/vspigot-0.jpg', '/GuildBall/cards/vspigot-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Stoker',
    type: 'Squaddie',
    guilds: ['Brewers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/stoker.jpg',
    health: 16,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/stoker-0.jpg', '/GuildBall/cards/stoker-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Veteran Boar',
    type: 'Captain',
    guilds: ['Butchers'],
    size: '40mm',
    avatar: '/GuildBall/avatars/veteran_boar.jpg',
    health: 22,
    influence: 3,
    influenceMax: 2,
    cards: ['/GuildBall/cards/vboar-0.jpg', '/GuildBall/cards/vboar-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Fillet',
    type: 'Captain',
    guilds: ['Butchers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/fillet.jpg',
    health: 14,
    influence: 4,
    influenceMax: 6,
    cards: ['/GuildBall/cards/fillet-0.jpg', '/GuildBall/cards/fillet-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Ox',
    type: 'Captain',
    guilds: ['Butchers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/ox.jpg',
    health: 17,
    influence: 4,
    influenceMax: 5,
    cards: ['/GuildBall/cards/ox-0.jpg', '/GuildBall/cards/ox-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Princess',
    type: 'Mascot',
    guilds: ['Butchers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/princess.jpg',
    health: 8,
    influence: 1,
    influenceMax: 2,
    cards: [
      '/GuildBall/cards/princess-0.jpg',
      '/GuildBall/cards/princess-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Truffles',
    type: 'Mascot',
    guilds: ['Butchers'],
    size: '40mm',
    avatar: '/GuildBall/avatars/truffles.jpg',
    health: 11,
    influence: 1,
    influenceMax: 2,
    cards: [
      '/GuildBall/cards/truffles-0.jpg',
      '/GuildBall/cards/truffles-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Boar',
    type: 'Squaddie',
    guilds: ['Butchers'],
    size: '40mm',
    avatar: '/GuildBall/avatars/boar.jpg',
    health: 20,
    influence: 1,
    influenceMax: 1,
    cards: ['/GuildBall/cards/boar-0.jpg', '/GuildBall/cards/boar-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Boiler',
    type: 'Squaddie',
    guilds: ['Butchers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/boiler.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/boiler-0.jpg', '/GuildBall/cards/boiler-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Brisket',
    type: 'Squaddie',
    guilds: ['Butchers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/brisket.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/brisket-0.jpg', '/GuildBall/cards/brisket-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Veteran Brisket',
    type: 'Squaddie',
    guilds: ['Butchers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/veteran_brisket.jpg',
    health: 13,
    influence: 2,
    influenceMax: 4,
    cards: [
      '/GuildBall/cards/vbrisket-0.jpg',
      '/GuildBall/cards/vbrisket-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Veteran Gutter',
    type: 'Squaddie',
    guilds: ['Butchers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/veteran_gutter.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/vgutter-0.jpg', '/GuildBall/cards/vgutter-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Layne',
    type: 'Squaddie',
    guilds: ['Butchers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/layne.jpg',
    health: 13,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/layne-0.jpg', '/GuildBall/cards/layne-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Meathook',
    type: 'Squaddie',
    guilds: ['Butchers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/meathook.jpg',
    health: 14,
    influence: 2,
    influenceMax: 3,
    cards: [
      '/GuildBall/cards/meathook-0.jpg',
      '/GuildBall/cards/meathook-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Veteran Ox',
    type: 'Squaddie',
    guilds: ['Butchers'],
    size: '40mm',
    avatar: '/GuildBall/avatars/veteran_ox.jpg',
    health: 19,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/vox-0.jpg', '/GuildBall/cards/vox-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Shank',
    type: 'Squaddie',
    guilds: ['Butchers', 'Cooks'],
    size: '30mm',
    avatar: '/GuildBall/avatars/shank.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/shank-0.jpg', '/GuildBall/cards/shank-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Tenderiser',
    type: 'Squaddie',
    guilds: ['Butchers', 'Cooks'],
    size: '40mm',
    avatar: '/GuildBall/avatars/tenderiser.jpg',
    health: 19,
    influence: 2,
    influenceMax: 4,
    cards: [
      '/GuildBall/cards/tenderiser-0.jpg',
      '/GuildBall/cards/tenderiser-1.jpg',
    ],
    maxMeleeRange: 2,
  },

  {
    name: 'Wellington',
    type: 'Captain',
    guilds: ['Cooks'],
    size: '30mm',
    avatar: '/GuildBall/avatars/wellington.jpg',
    health: 16,
    influence: 4,
    influenceMax: 6,
    cards: [
      '/GuildBall/cards/wellington-0.jpg',
      '/GuildBall/cards/wellington-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Pepper',
    type: 'Masot',
    guilds: ['Cooks'],
    size: '30mm',
    avatar: '/GuildBall/avatars/pepper.jpg',
    health: 6,
    influence: 1,
    influenceMax: 2,
    cards: ['/GuildBall/cards/pepper-0.jpg', '/GuildBall/cards/pepper-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Cinnamon',
    type: 'Squaddie',
    guilds: ['Butchers', 'Cooks'],
    size: '30mm',
    avatar: '/GuildBall/avatars/cinnamon.jpg',
    health: 11,
    influence: 2,
    influenceMax: 3,
    cards: [
      '/GuildBall/cards/cinnamon-0.jpg',
      '/GuildBall/cards/cinnamon-1.jpg',
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Roast',
    type: 'Squaddie',
    guilds: ['Butchers', 'Cooks'],
    size: '40mm',
    avatar: '/GuildBall/avatars/roast.jpg',
    health: 18,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/roast-0.jpg', '/GuildBall/cards/roast-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Spice',
    type: 'Squaddie',
    guilds: ['Cooks'],
    size: '30mm',
    avatar: '/GuildBall/avatars/spice.jpg',
    health: 14,
    influence: 2,
    influenceMax: 2,
    cards: ['/GuildBall/cards/spice-0.jpg', '/GuildBall/cards/spice-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Sugar',
    type: 'Squaddie',
    guilds: ['Cooks'],
    size: '30mm',
    avatar: '/GuildBall/avatars/sugar.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/sugar-0.jpg', '/GuildBall/cards/sugar-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Ballista',
    type: 'Captain',
    guilds: ['Engineers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/ballista.jpg',
    health: 18,
    influence: 4,
    influenceMax: 6,
    cards: [
      '/GuildBall/cards/ballista-0.jpg',
      '/GuildBall/cards/ballista-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Pin Vice',
    type: 'Captain',
    guilds: ['Engineers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/pin_vice.jpg',
    health: 11,
    influence: 4,
    influenceMax: 6,
    cards: ['/GuildBall/cards/pinvice-0.jpg', '/GuildBall/cards/pinvice-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Rivet',
    type: 'Captain',
    guilds: ['Engineers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/rivet.jpg',
    health: 18,
    influence: 4,
    influenceMax: 6,
    cards: ['/GuildBall/cards/rivet-0.jpg', '/GuildBall/cards/rivet-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Mainspring',
    type: 'Mascot',
    guilds: ['Engineers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/mainspring.jpg',
    health: 7,
    influence: 1,
    influenceMax: 3,
    cards: [
      '/GuildBall/cards/mainspring-0.jpg',
      '/GuildBall/cards/mainspring-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Mother',
    type: 'Mascot',
    guilds: ['Engineers'],
    size: '40mm',
    avatar: '/GuildBall/avatars/mother.jpg',
    health: 7,
    influence: 1,
    influenceMax: 3,
    cards: ['/GuildBall/cards/mother-0.jpg', '/GuildBall/cards/mother-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Colossus',
    type: 'Squaddie',
    guilds: ['Engineers', 'Miners'],
    size: '50mm',
    avatar: '/GuildBall/avatars/colossus.jpg',
    health: 20,
    influence: 2,
    influenceMax: 3,
    cards: [
      '/GuildBall/cards/colossus-0.jpg',
      '/GuildBall/cards/colossus-1.jpg',
    ],
    maxMeleeRange: 2,
  },
  {
    name: "Harriet 'The Hat'",
    type: 'Squaddie',
    guilds: ['Engineers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/harriet_the_hat.jpg',
    health: 16,
    influence: 2,
    influenceMax: 3,
    cards: [
      '/GuildBall/cards/vharriet-0.jpg',
      '/GuildBall/cards/vharriet-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Hoist',
    type: 'Squaddie',
    guilds: ['Engineers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/hoist.jpg',
    health: 13,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/hoist-0.jpg', '/GuildBall/cards/hoist-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Locus',
    type: 'Squaddie',
    guilds: ['Engineers'],
    size: '40mm',
    avatar: '/GuildBall/avatars/locus.jpg',
    health: 15,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/locus-0.jpg', '/GuildBall/cards/locus-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Nomad',
    type: 'Squaddie',
    guilds: ['Engineers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/nomad.jpg',
    health: 13,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/nomad-0.jpg', '/GuildBall/cards/nomad-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Ratchet',
    type: 'Squaddie',
    guilds: ['Engineers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/ratchet.jpg',
    health: 17,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/ratchet-0.jpg', '/GuildBall/cards/ratchet-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Salvo',
    type: 'Squaddie',
    guilds: ['Engineers', 'Miners'],
    size: '30mm',
    avatar: '/GuildBall/avatars/salvo.jpg',
    health: 16,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/salvo-0.jpg', '/GuildBall/cards/salvo-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Velocity',
    type: 'Squaddie',
    guilds: ['Engineers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/velocity.jpg',
    health: 9,
    influence: 2,
    influenceMax: 4,
    cards: [
      '/GuildBall/cards/velocity-0.jpg',
      '/GuildBall/cards/velocity-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Veteran Velocity',
    type: 'Squaddie',
    guilds: ['Engineers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/veteran_velocity.jpg',
    health: 10,
    influence: 2,
    influenceMax: 4,
    cards: [
      '/GuildBall/cards/vvelocity-0.jpg',
      '/GuildBall/cards/vvelocity-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Compound',
    type: 'Squaddie',
    guilds: ['Alchemists', 'Engineers'],
    size: '40mm',
    avatar: '/GuildBall/avatars/compound.jpg',
    health: 18,
    influence: 2,
    influenceMax: 4,
    cards: [
      '/GuildBall/cards/compound-0.jpg',
      '/GuildBall/cards/compound-1.jpg',
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Devana',
    type: 'Captain',
    guilds: ['Falconers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/devana.jpg',
    health: 16,
    influence: 4,
    influenceMax: 6,
    cards: ['/GuildBall/cards/devana-0.jpg', '/GuildBall/cards/devana-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Frelsi',
    type: 'Mascot',
    guilds: ['Falconers'],
    size: '40mm',
    avatar: '/GuildBall/avatars/frelsi.jpg',
    health: 10,
    influence: 1,
    influenceMax: 3,
    cards: ['/GuildBall/cards/frelsi-0.jpg', '/GuildBall/cards/frelsi-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Ikaros',
    type: 'Squaddie',
    guilds: ['Falconers'],
    size: '40mm',
    avatar: '/GuildBall/avatars/ikaros.jpg',
    health: 13,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/ikaros-0.jpg', '/GuildBall/cards/ikaros-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Mataagi',
    type: 'Squaddie',
    guilds: ['Falconers', 'Hunters'],
    size: '30mm',
    avatar: '/GuildBall/avatars/mataagi.jpg',
    health: 12,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/mataagi-0.jpg', '/GuildBall/cards/mataagi-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Minerva',
    type: 'Squaddie',
    guilds: ['Falconers', 'Hunters'],
    size: '30mm',
    avatar: '/GuildBall/avatars/minerva.jpg',
    health: 15,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/minerva-0.jpg', '/GuildBall/cards/minerva-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Rundaas',
    type: 'Squaddie',
    guilds: ['Falconers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/rundaas.jpg',
    health: 13,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/rundaas-0.jpg', '/GuildBall/cards/rundaas-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Harvest Marker',
    type: 'Token',
    guilds: ['Farmers'],
    size: '30mm',
  },
  {
    name: 'Grange',
    type: 'Captain',
    guilds: ['Farmers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/grange.jpg',
    health: 26,
    influence: 5,
    influenceMax: 6,
    cards: ['/GuildBall/cards/grange-0.jpg', '/GuildBall/cards/grange-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Thresher',
    type: 'Captain',
    guilds: ['Farmers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/thresher.jpg',
    health: 20,
    influence: 3,
    influenceMax: 5,
    cards: [
      '/GuildBall/cards/thresher-0.jpg',
      '/GuildBall/cards/thresher-1.jpg',
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Festival',
    type: 'Captain',
    guilds: ['Farmers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/festival.jpg',
    health: 22,
    influence: 5,
    influenceMax: 6,
    cards: [
      '/GuildBall/cards/festival-0.jpg',
      '/GuildBall/cards/festival-1.jpg',
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Buckwheat',
    type: 'Mascot',
    guilds: ['Farmers'],
    size: '40mm',
    avatar: '/GuildBall/avatars/buckwheat.jpg',
    health: 9,
    influence: 1,
    influenceMax: 3,
    cards: [
      '/GuildBall/cards/buckwheat-0.jpg',
      '/GuildBall/cards/buckwheat-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Peck',
    type: 'Mascot',
    guilds: ['Farmers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/peck.jpg',
    health: 6,
    influence: 1,
    influenceMax: 2,
    cards: ['/GuildBall/cards/peck-0.jpg', '/GuildBall/cards/peck-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Amber',
    type: 'Squaddie',
    guilds: ['Farmers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/amber.jpg',
    health: 18,
    influence: 1,
    influenceMax: 4,
    cards: ['/GuildBall/cards/amber-0.jpg', '/GuildBall/cards/amber-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Bushel',
    type: 'Squaddie',
    guilds: ['Farmers', 'Shepherds'],
    size: '30mm',
    avatar: '/GuildBall/avatars/bushel.jpg',
    health: 12,
    influence: 1,
    influenceMax: 4,
    cards: ['/GuildBall/cards/bushel-0.jpg', '/GuildBall/cards/bushel-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Fallow',
    type: 'Squaddie',
    guilds: ['Farmers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/fallow.jpg',
    health: 19,
    influence: 0,
    influenceMax: 4,
    cards: ['/GuildBall/cards/fallow-0.jpg', '/GuildBall/cards/fallow-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Harrow',
    type: 'Squaddie',
    guilds: ['Farmers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/harrow.jpg',
    health: 17,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/harrow-0.jpg', '/GuildBall/cards/harrow-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Veteran Honour',
    type: 'Squaddie',
    guilds: ['Farmers', 'Shepherds'],
    size: '40mm',
    avatar: '/GuildBall/avatars/veteran_honour.jpg',
    health: 17,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/vhonour-0.jpg', '/GuildBall/cards/vhonour-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Jackstraw',
    type: 'Squaddie',
    guilds: ['Farmers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/jackstraw.jpg',
    health: 10,
    influence: 2,
    influenceMax: 3,
    cards: [
      '/GuildBall/cards/jackstraw-0.jpg',
      '/GuildBall/cards/jackstraw-1.jpg',
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Millstone',
    type: 'Squaddie',
    guilds: ['Farmers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/millstone.jpg',
    health: 17,
    influence: 2,
    influenceMax: 3,
    cards: [
      '/GuildBall/cards/millstone-0.jpg',
      '/GuildBall/cards/millstone-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Ploughman',
    type: 'Squaddie',
    guilds: ['Farmers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/ploughman.jpg',
    health: 20,
    influence: 2,
    influenceMax: 3,
    cards: [
      '/GuildBall/cards/ploughman-0.jpg',
      '/GuildBall/cards/ploughman-1.jpg',
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Tater',
    type: 'Squaddie',
    guilds: ['Farmers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/tater.jpg',
    health: 18,
    influence: 1,
    influenceMax: 4,
    cards: ['/GuildBall/cards/tater-0.jpg', '/GuildBall/cards/tater-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Windle',
    type: 'Squaddie',
    guilds: ['Farmers'],
    size: '40mm',
    avatar: '/GuildBall/avatars/windle.jpg',
    health: 22,
    influence: 1,
    influenceMax: 2,
    cards: ['/GuildBall/cards/windle-0.jpg', '/GuildBall/cards/windle-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Corsair',
    type: 'Captain',
    guilds: ['Fishermen'],
    size: '40mm',
    avatar: '/GuildBall/avatars/corsair.jpg',
    health: 18,
    influence: 4,
    influenceMax: 6,
    cards: ['/GuildBall/cards/corsair-0.jpg', '/GuildBall/cards/corsair-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Shark',
    type: 'Captain',
    guilds: ['Fishermen'],
    size: '30mm',
    avatar: '/GuildBall/avatars/shark.jpg',
    health: 16,
    influence: 4,
    influenceMax: 6,
    cards: ['/GuildBall/cards/shark-0.jpg', '/GuildBall/cards/shark-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Yukai',
    type: 'Captain',
    guilds: ['Fishermen'],
    size: '30mm',
    avatar: '/GuildBall/avatars/yukai.jpg',
    health: 14,
    influence: 4,
    influenceMax: 6,
    cards: ['/GuildBall/cards/yukai-0.jpg', '/GuildBall/cards/yukai-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Salt',
    type: 'Mascot',
    guilds: ['Fishermen'],
    size: '30mm',
    avatar: '/GuildBall/avatars/salt.jpg',
    health: 7,
    influence: 1,
    influenceMax: 3,
    cards: ['/GuildBall/cards/salt-0.jpg', '/GuildBall/cards/salt-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Tentacles',
    type: 'Mascot',
    guilds: ['Fishermen'],
    size: '40mm',
    avatar: '/GuildBall/avatars/tentacles.jpg',
    health: 9,
    influence: 1,
    influenceMax: 2,
    cards: [
      '/GuildBall/cards/tentacles-0.jpg',
      '/GuildBall/cards/tentacles-1.jpg',
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Angel',
    type: 'Squaddie',
    guilds: ['Fishermen', 'Navigators'],
    size: '30mm',
    avatar: '/GuildBall/avatars/angel.jpg',
    health: 12,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/angel-0.jpg', '/GuildBall/cards/angel-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Greyscales',
    type: 'Squaddie',
    guilds: ['Fishermen'],
    size: '30mm',
    avatar: '/GuildBall/avatars/greyscales.jpg',
    health: 13,
    influence: 2,
    influenceMax: 4,
    cards: [
      '/GuildBall/cards/greyscales-0.jpg',
      '/GuildBall/cards/greyscales-1.jpg',
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Hag',
    type: 'Squaddie',
    guilds: ['Fishermen'],
    size: '30mm',
    avatar: '/GuildBall/avatars/hag.jpg',
    health: 13,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/hag-0.jpg', '/GuildBall/cards/hag-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Jac',
    type: 'Squaddie',
    guilds: ['Fishermen'],
    size: '30mm',
    avatar: '/GuildBall/avatars/jac.jpg',
    health: 19,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/jac-0.jpg', '/GuildBall/cards/jac-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Knuckles',
    type: 'Squaddie',
    guilds: ['Fishermen'],
    size: '40mm',
    avatar: '/GuildBall/avatars/knuckles.jpg',
    health: 19,
    influence: 2,
    influenceMax: 4,
    cards: [
      '/GuildBall/cards/knuckles-0.jpg',
      '/GuildBall/cards/knuckles-1.jpg',
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Kraken',
    type: 'Squaddie',
    guilds: ['Fishermen'],
    size: '40mm',
    avatar: '/GuildBall/avatars/kraken.jpg',
    health: 20,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/kraken-0.jpg', '/GuildBall/cards/kraken-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Sakana',
    type: 'Squaddie',
    guilds: ['Fishermen'],
    size: '30mm',
    avatar: '/GuildBall/avatars/sakana.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/sakana-0.jpg', '/GuildBall/cards/sakana-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Veteran Sakana',
    type: 'Squaddie',
    guilds: ['Fishermen'],
    size: '30mm',
    avatar: '/GuildBall/avatars/veteran_sakana.jpg',
    health: 14,
    influence: 1,
    influenceMax: 3,
    cards: ['/GuildBall/cards/vsakana-0.jpg', '/GuildBall/cards/vsakana-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Siren',
    type: 'Squaddie',
    guilds: ['Fishermen', 'Navigators'],
    size: '30mm',
    avatar: '/GuildBall/avatars/siren.jpg',
    health: 10,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/siren-0.jpg', '/GuildBall/cards/siren-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Veteran Siren',
    type: 'Squaddie',
    guilds: ['Fishermen'],
    size: '30mm',
    avatar: '/GuildBall/avatars/veteran_siren.jpg',
    health: 10,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/vsiren-0.jpg', '/GuildBall/cards/vsiren-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Trap Marker',
    type: 'Token',
    guilds: ['Hunters'],
    size: '30mm',
  },
  {
    name: 'Skatha',
    type: 'Captain',
    guilds: ['Hunters'],
    size: '30mm',
    avatar: '/GuildBall/avatars/skatha.jpg',
    health: 14,
    influence: 4,
    influenceMax: 6,
    cards: ['/GuildBall/cards/skatha-0.jpg', '/GuildBall/cards/skatha-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Steeljaw',
    type: 'Captain',
    guilds: ['Hunters'],
    size: '30mm',
    avatar: '/GuildBall/avatars/steeljaw.jpg',
    health: 16,
    influence: 4,
    influenceMax: 6,
    cards: [
      '/GuildBall/cards/steeljaw-0.jpg',
      '/GuildBall/cards/steeljaw-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Theron',
    type: 'Captain',
    guilds: ['Hunters'],
    size: '30mm',
    avatar: '/GuildBall/avatars/theron.jpg',
    health: 16,
    influence: 4,
    influenceMax: 6,
    cards: ['/GuildBall/cards/theron-0.jpg', '/GuildBall/cards/theron-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Fahad',
    type: 'Mascot',
    guilds: ['Hunters'],
    size: '40mm',
    avatar: '/GuildBall/avatars/fahad.jpg',
    health: 6,
    influence: 1,
    influenceMax: 2,
    cards: ['/GuildBall/cards/fahad-0.jpg', '/GuildBall/cards/fahad-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Snow',
    type: 'Mascot',
    guilds: ['Hunters'],
    size: '30mm',
    avatar: '/GuildBall/avatars/snow.jpg',
    health: 8,
    influence: 1,
    influenceMax: 3,
    cards: ['/GuildBall/cards/snow-0.jpg', '/GuildBall/cards/snow-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Chaska',
    type: 'Squaddie',
    guilds: ['Hunters'],
    size: '30mm',
    avatar: '/GuildBall/avatars/chaska.jpg',
    health: 16,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/chaska-0.jpg', '/GuildBall/cards/chaska-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Edge',
    type: 'Squaddie',
    guilds: ['Hunters'],
    size: '30mm',
    avatar: '/GuildBall/avatars/edge.jpg',
    health: 13,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/edge-0.jpg', '/GuildBall/cards/edge-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Egret',
    type: 'Squaddie',
    guilds: ['Falconers', 'Hunters'],
    size: '30mm',
    avatar: '/GuildBall/avatars/egret.jpg',
    health: 12,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/egret-0.jpg', '/GuildBall/cards/egret-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Hearne',
    type: 'Squaddie',
    guilds: ['Hunters'],
    size: '40mm',
    avatar: '/GuildBall/avatars/hearne.jpg',
    health: 20,
    influence: 1,
    influenceMax: 3,
    cards: ['/GuildBall/cards/hearne-0.jpg', '/GuildBall/cards/hearne-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Veteran Hearne',
    type: 'Squaddie',
    guilds: ['Falconers', 'Hunters'],
    size: '40mm',
    avatar: '/GuildBall/avatars/veteran_hearne.jpg',
    health: 20,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/vhearne-0.jpg', '/GuildBall/cards/vhearne-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Jaecar',
    type: 'Squaddie',
    guilds: ['Hunters'],
    size: '30mm',
    avatar: '/GuildBall/avatars/jaecar.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/jaecar-0.jpg', '/GuildBall/cards/jaecar-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Veteran Minx',
    type: 'Squaddie',
    guilds: ['Hunters'],
    size: '30mm',
    avatar: '/GuildBall/avatars/veteran_minx.jpg',
    health: 12,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/vminx-0.jpg', '/GuildBall/cards/vminx-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Seenah',
    type: 'Squaddie',
    guilds: ['Hunters'],
    size: '50mm',
    avatar: '/GuildBall/avatars/seenah.jpg',
    health: 21,
    influence: 1,
    influenceMax: 2,
    cards: ['/GuildBall/cards/seenah-0.jpg', '/GuildBall/cards/seenah-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Ulfr',
    type: 'Squaddie',
    guilds: ['Hunters'],
    size: '30mm',
    avatar: '/GuildBall/avatars/ulfr.jpg',
    health: 16,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/ulfr-0.jpg', '/GuildBall/cards/ulfr-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Zarola',
    type: 'Squaddie',
    guilds: ['Hunters'],
    size: '30mm',
    avatar: '/GuildBall/avatars/zarola.jpg',
    health: 12,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/zarola-0.jpg', '/GuildBall/cards/zarola-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Beacon',
    type: 'Squaddie',
    guilds: ['Lamplighters'],
    size: '40mm',
    avatar: '/GuildBall/avatars/beacon.jpg',
    cards: ['/GuildBall/cards/beacon-0.jpg', '/GuildBall/cards/beacon-1.jpg'],
    health: 22,
    influence: 2,
    influenceMax: 4,
    maxMeleeRange: 2,
  },
  {
    name: 'Heat',
    type: 'Squaddie',
    guilds: ['Lamplighters'],
    size: '40mm',
    avatar: '/GuildBall/avatars/heat.jpg',
    cards: ['/GuildBall/cards/heat-0.jpg', '/GuildBall/cards/heat-1.jpg'],
    health: 14,
    influence: 2,
    influenceMax: 4,
    maxMeleeRange: 1,
  },
  {
    name: 'Nightlight',
    type: 'Captain',
    guilds: ['Lamplighters'],
    size: '30mm',
    avatar: '/GuildBall/avatars/nightlight.jpg',
    cards: [
      '/GuildBall/cards/nightlight-0.jpg',
      '/GuildBall/cards/nightlight-1.jpg',
    ],
    health: 14,
    influence: 4,
    influenceMax: 6,
    maxMeleeRange: 1,
  },
  {
    name: 'Phosphor',
    type: 'Squaddie',
    guilds: ['Lamplighters'],
    size: '30mm',
    avatar: '/GuildBall/avatars/phosphore.jpg',
    cards: [
      '/GuildBall/cards/phosphor-0.jpg',
      '/GuildBall/cards/phosphor-1.jpg',
    ],
    health: 14,
    influence: 2,
    influenceMax: 4,
    maxMeleeRange: 2,
  },
  {
    name: 'Soot',
    type: 'Mascot',
    guilds: ['Lamplighters'],
    size: '30mm',
    avatar: '/GuildBall/avatars/soot.jpg',
    cards: ['/GuildBall/cards/soot-0.jpg', '/GuildBall/cards/soot-1.jpg'],
    health: 8,
    influence: 1,
    influenceMax: 3,
    maxMeleeRange: 1,
  },
  {
    name: 'Wick',
    type: 'Squaddie',
    guilds: ['Lamplighters'],
    size: '30mm',
    avatar: '/GuildBall/avatars/wick.jpg',
    cards: ['/GuildBall/cards/wick-0.jpg', '/GuildBall/cards/wick-1.jpg'],
    health: 14,
    influence: 2,
    influenceMax: 4,
    maxMeleeRange: 1,
  },
  {
    name: 'Honour',
    type: 'Captain',
    guilds: ['Masons'],
    size: '30mm',
    avatar: '/GuildBall/avatars/honour.jpg',
    health: 17,
    influence: 4,
    influenceMax: 6,
    cards: ['/GuildBall/cards/honour-0.jpg', '/GuildBall/cards/honour-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Hammer',
    type: 'Captain',
    guilds: ['Masons'],
    size: '30mm',
    avatar: '/GuildBall/avatars/hammer.jpg',
    health: 18,
    influence: 4,
    influenceMax: 6,
    cards: ['/GuildBall/cards/hammer-0.jpg', '/GuildBall/cards/hammer-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Corbelli',
    type: 'Captain',
    guilds: ['Masons'],
    size: '30mm',
    avatar: '/GuildBall/avatars/corbelli.jpg',
    health: 16,
    influence: 4,
    influenceMax: 6,
    cards: [
      '/GuildBall/cards/corbelli-0.jpg',
      '/GuildBall/cards/corbelli-1.jpg',
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Marbles',
    type: 'Mascot',
    guilds: ['Masons'],
    size: '30mm',
    avatar: '/GuildBall/avatars/marbles.jpg',
    health: 8,
    influence: 1,
    influenceMax: 2,
    cards: ['/GuildBall/cards/marbles-0.jpg', '/GuildBall/cards/marbles-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Wrecker',
    type: 'Mascot',
    guilds: ['Masons'],
    size: '40mm',
    avatar: '/GuildBall/avatars/wrecker.jpg',
    health: 8,
    influence: 1,
    influenceMax: 3,
    cards: ['/GuildBall/cards/wrecker-0.jpg', '/GuildBall/cards/wrecker-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Brick',
    type: 'Squaddie',
    guilds: ['Masons'],
    size: '40mm',
    avatar: '/GuildBall/avatars/brick.jpg',
    health: 19,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/brick-0.jpg', '/GuildBall/cards/brick-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Champ',
    type: 'Squaddie',
    guilds: ['Masons'],
    size: '30mm',
    avatar: '/GuildBall/avatars/champ.jpg',
    health: 16,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/champ-0.jpg', '/GuildBall/cards/champ-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Chisel',
    type: 'Squaddie',
    guilds: ['Masons'],
    size: '30mm',
    avatar: '/GuildBall/avatars/chisel.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/chisel-0.jpg', '/GuildBall/cards/chisel-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Veteran Chisel',
    type: 'Squaddie',
    guilds: ['Masons'],
    size: '30mm',
    avatar: '/GuildBall/avatars/veteran_chisel.jpg',
    health: 13,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/vchisel-0.jpg', '/GuildBall/cards/vchisel-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Flint',
    type: 'Squaddie',
    guilds: ['Masons'],
    size: '30mm',
    avatar: '/GuildBall/avatars/flint.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/flint-0.jpg', '/GuildBall/cards/flint-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Granite',
    type: 'Squaddie',
    guilds: ['Masons'],
    size: '40mm',
    avatar: '/GuildBall/avatars/granite.jpg',
    health: 20,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/granite-0.jpg', '/GuildBall/cards/granite-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Harmony',
    type: 'Squaddie',
    guilds: ['Masons'],
    size: '30mm',
    avatar: '/GuildBall/avatars/harmony.jpg',
    health: 12,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/harmony-0.jpg', '/GuildBall/cards/harmony-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Veteran Harmony',
    type: 'Squaddie',
    guilds: ['Masons'],
    size: '30mm',
    avatar: '/GuildBall/avatars/veteran_harmony.jpg',
    health: 12,
    influence: 2,
    influenceMax: 4,
    cards: [
      '/GuildBall/cards/vharmony-0.jpg',
      '/GuildBall/cards/vharmony-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Mallet',
    type: 'Squaddie',
    guilds: ['Masons'],
    size: '30mm',
    avatar: '/GuildBall/avatars/mallet.jpg',
    health: 16,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/mallet-0.jpg', '/GuildBall/cards/mallet-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Tower',
    type: 'Squaddie',
    guilds: ['Masons'],
    size: '30mm',
    avatar: '/GuildBall/avatars/tower.jpg',
    health: 18,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/tower-0.jpg', '/GuildBall/cards/tower-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Lucky',
    type: 'Squaddie',
    guilds: ['Brewers', 'Masons'],
    size: '30mm',
    avatar: '/GuildBall/avatars/lucky.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/lucky-0.jpg', '/GuildBall/cards/lucky-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Shaft',
    type: 'Captain',
    guilds: ['Miners'],
    size: '30mm',
    avatar: '/GuildBall/avatars/shaft.jpg',
    health: 16,
    influence: 4,
    influenceMax: 6,
    cards: ['/GuildBall/cards/shaft-0.jpg', '/GuildBall/cards/shaft-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Digger',
    type: 'Mascot',
    guilds: ['Miners'],
    size: '30mm',
    avatar: '/GuildBall/avatars/digger.jpg',
    health: 10,
    influence: 1,
    influenceMax: 3,
    cards: ['/GuildBall/cards/digger-0.jpg', '/GuildBall/cards/digger-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Fuse',
    type: 'Squaddie',
    guilds: ['Engineers', 'Miners'],
    size: '30mm',
    avatar: '/GuildBall/avatars/fuse.jpg',
    health: 16,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/fuse-0.jpg', '/GuildBall/cards/fuse-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Spade',
    type: 'Squaddie',
    guilds: ['Engineers', 'Miners'],
    size: '30mm',
    avatar: '/GuildBall/avatars/spade.jpg',
    health: 15,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/spade-0.jpg', '/GuildBall/cards/spade-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Fissure',
    type: 'Squaddie',
    guilds: ['Miners'],
    size: '50mm',
    avatar: '/GuildBall/avatars/fissure.jpg',
    health: 20,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/fissure-0.jpg', '/GuildBall/cards/fissure-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Mule',
    type: 'Squaddie',
    guilds: ['Miners'],
    size: '50mm',
    avatar: '/GuildBall/avatars/mule.jpg',
    health: 16,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/mule-0.jpg', '/GuildBall/cards/mule-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Mourn',
    type: 'Captain',
    guilds: ['Morticians'],
    size: '30mm',
    avatar: '/GuildBall/avatars/mourn.jpg',
    health: 14,
    influence: 4,
    influenceMax: 4,
    cards: ['/GuildBall/cards/mourn-0.jpg', '/GuildBall/cards/mourn-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Obulus',
    type: 'Captain',
    guilds: ['Morticians'],
    size: '30mm',
    avatar: '/GuildBall/avatars/obulus.jpg',
    health: 14,
    influence: 5,
    influenceMax: 7,
    cards: ['/GuildBall/cards/obulus-0.jpg', '/GuildBall/cards/obulus-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Scalpel',
    type: 'Captain',
    guilds: ['Morticians'],
    size: '30mm',
    avatar: '/GuildBall/avatars/scalpel.jpg',
    health: 16,
    influence: 4,
    influenceMax: 6,
    cards: ['/GuildBall/cards/scalpel-0.jpg', '/GuildBall/cards/scalpel-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Dirge',
    type: 'Mascot',
    guilds: ['Morticians'],
    size: '30mm',
    avatar: '/GuildBall/avatars/dirge.jpg',
    health: 7,
    influence: 1,
    influenceMax: 3,
    cards: ['/GuildBall/cards/dirge-0.jpg', '/GuildBall/cards/dirge-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Vileswarm',
    type: 'Mascot',
    guilds: ['Morticians'],
    size: '40mm',
    avatar: '/GuildBall/avatars/vileswarm.jpg',
    health: 9,
    influence: 1,
    influenceMax: 2,
    cards: [
      '/GuildBall/cards/vileswarm-0.jpg',
      '/GuildBall/cards/vileswarm-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Bonesaw',
    type: 'Squaddie',
    guilds: ['Morticians', 'Ratcatchers'],
    size: '50mm',
    avatar: '/GuildBall/avatars/bonesaw.jpg',
    health: 12,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/bonesaw-0.jpg', '/GuildBall/cards/bonesaw-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Brainpan',
    type: 'Squaddie',
    guilds: ['Morticians'],
    size: '30mm',
    avatar: '/GuildBall/avatars/brainpan.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: [
      '/GuildBall/cards/brainpan-0.jpg',
      '/GuildBall/cards/brainpan-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Casket',
    type: 'Squaddie',
    guilds: ['Morticians'],
    size: '40mm',
    avatar: '/GuildBall/avatars/casket.jpg',
    health: 17,
    influence: 1,
    influenceMax: 4,
    cards: ['/GuildBall/cards/casket-0.jpg', '/GuildBall/cards/casket-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Cosset',
    type: 'Squaddie',
    guilds: ['Morticians'],
    size: '30mm',
    avatar: '/GuildBall/avatars/cosset.jpg',
    health: 12,
    influence: 2,
    influenceMax: 2,
    cards: ['/GuildBall/cards/cosset-0.jpg', '/GuildBall/cards/cosset-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Gaffer',
    type: 'Squaddie',
    guilds: ['Morticians'],
    size: '30mm',
    avatar: '/GuildBall/avatars/gaffer.jpg',
    health: 16,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/gaffer-0.jpg', '/GuildBall/cards/gaffer-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Ghast',
    type: 'Squaddie',
    guilds: ['Morticians'],
    size: '40mm',
    avatar: '/GuildBall/avatars/ghast.jpg',
    health: 21,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/ghast-0.jpg', '/GuildBall/cards/ghast-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Graves',
    type: 'Squaddie',
    guilds: ['Morticians'],
    size: '30mm',
    avatar: '/GuildBall/avatars/graves.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/graves-0.jpg', '/GuildBall/cards/graves-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Veteran Graves',
    type: 'Squaddie',
    guilds: ['Morticians', 'Ratcatchers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/veteran_graves.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/vgraves-0.jpg', '/GuildBall/cards/vgraves-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Veteran Hemlocke',
    type: 'Squaddie',
    guilds: ['Morticians'],
    size: '30mm',
    avatar: '/GuildBall/avatars/veteran_hemlocke.jpg',
    health: 12,
    influence: 2,
    influenceMax: 3,
    cards: [
      '/GuildBall/cards/vhemlocke-0.jpg',
      '/GuildBall/cards/vhemlocke-1.jpg',
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Memory',
    type: 'Squaddie',
    guilds: ['Morticians'],
    size: '30mm',
    avatar: '/GuildBall/avatars/memory.jpg',
    health: 3,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/memory-0.jpg', '/GuildBall/cards/memory-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Silence',
    type: 'Squaddie',
    guilds: ['Morticians'],
    size: '30mm',
    avatar: '/GuildBall/avatars/silence.jpg',
    health: 12,
    influence: 0,
    influenceMax: 0,
    cards: ['/GuildBall/cards/silence-0.jpg', '/GuildBall/cards/silence-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Windfinder',
    type: 'Captain',
    guilds: ['Navigators'],
    size: '30mm',
    avatar: '/GuildBall/avatars/windfinder.jpg',
    health: 14,
    influence: 4,
    influenceMax: 6,
    cards: [
      '/GuildBall/cards/windfinder-0.jpg',
      '/GuildBall/cards/windfinder-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Wander',
    type: 'Mascot',
    guilds: ['Navigators'],
    size: '30mm',
    avatar: '/GuildBall/avatars/wander.jpg',
    health: 9,
    influence: 1,
    influenceMax: 2,
    cards: ['/GuildBall/cards/wander-0.jpg', '/GuildBall/cards/wander-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Azimuth',
    type: 'Squaddie',
    guilds: ['Navigators'],
    size: '40mm',
    avatar: '/GuildBall/avatars/azimuth.jpg',
    health: 18,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/azimuth-0.jpg', '/GuildBall/cards/azimuth-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Ebb',
    type: 'Squaddie',
    guilds: ['Navigators'],
    size: '30mm',
    avatar: '/GuildBall/avatars/ebb.jpg',
    health: 12,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/ebb-0.jpg', '/GuildBall/cards/ebb-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Fathom',
    type: 'Squaddie',
    guilds: ['Fishermen', 'Navigators'],
    size: '30mm',
    avatar: '/GuildBall/avatars/fathom.jpg',
    health: 12,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/fathom-0.jpg', '/GuildBall/cards/fathom-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Horizon',
    type: 'Squaddie',
    guilds: ['Fishermen', 'Navigators'],
    size: '30mm',
    avatar: '/GuildBall/avatars/horizon.jpg',
    health: 8,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/horizon-0.jpg', '/GuildBall/cards/horizon-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Pelage',
    type: 'Squaddie',
    guilds: ['Morticians', 'Ratcatchers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/pelage.jpg',
    health: 12,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/pelage-0.jpg', '/GuildBall/cards/pelage-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Skulk',
    type: 'Squaddie',
    guilds: ['Morticians', 'Ratcatchers'],
    size: '40mm',
    avatar: '/GuildBall/avatars/skulk.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/skulk-0.jpg', '/GuildBall/cards/skulk-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Piper',
    type: 'Captain',
    guilds: ['Ratcatchers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/piper.jpg',
    health: 14,
    influence: 4,
    influenceMax: 6,
    cards: ['/GuildBall/cards/piper-0.jpg', '/GuildBall/cards/piper-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Squeak',
    type: 'Mascot',
    guilds: ['Ratcatchers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/squeak.jpg',
    health: 10,
    influence: 1,
    influenceMax: 3,
    cards: ['/GuildBall/cards/squeak-0.jpg', '/GuildBall/cards/squeak-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Miasma',
    type: 'Squaddie',
    guilds: ['Ratcatchers'],
    size: '30mm',
    avatar: '/GuildBall/avatars/miasma.jpg',
    health: 16,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/miasma-0.jpg', '/GuildBall/cards/miasma-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Scourge',
    type: 'Squaddie',
    guilds: ['Ratcatchers'],
    size: '50mm',
    avatar: '/GuildBall/avatars/scourge.jpg',
    health: 17,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/scourge-0.jpg', '/GuildBall/cards/scourge-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Herder',
    type: 'Captain',
    guilds: ['Shepherds'],
    size: '30mm',
    avatar: '/GuildBall/avatars/herder.jpg',
    health: 22,
    influence: 3,
    influenceMax: 6,
    cards: ['/GuildBall/cards/herder-0.jpg', '/GuildBall/cards/herder-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Babe',
    type: 'Mascot',
    guilds: ['Shepherds'],
    size: '30mm',
    avatar: '/GuildBall/avatars/babe.jpg',
    health: 7,
    influence: 1,
    influenceMax: 2,
    cards: ['/GuildBall/cards/babe-0.jpg', '/GuildBall/cards/babe-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Crook',
    type: 'Squaddie',
    guilds: ['Shepherds'],
    size: '30mm',
    avatar: '/GuildBall/avatars/crook.jpg',
    health: 9,
    influence: 1,
    influenceMax: 2,
    cards: ['/GuildBall/cards/crook-0.jpg', '/GuildBall/cards/crook-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Hook',
    type: 'Squaddie',
    guilds: ['Shepherds'],
    size: '30mm',
    avatar: '/GuildBall/avatars/hook.jpg',
    health: 9,
    influence: 1,
    influenceMax: 2,
    cards: ['/GuildBall/cards/hook-0.jpg', '/GuildBall/cards/hook-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Lamb',
    type: 'Squaddie',
    guilds: ['Shepherds'],
    size: '30mm',
    avatar: '/GuildBall/avatars/lamb.jpg',
    health: 20,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/lamb-0.jpg', '/GuildBall/cards/lamb-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Ram',
    type: 'Squaddie',
    guilds: ['Shepherds'],
    size: '40mm',
    avatar: '/GuildBall/avatars/ram.jpg',
    health: 24,
    influence: 2,
    influenceMax: 3,
    cards: ['/GuildBall/cards/ram-0.jpg', '/GuildBall/cards/ram-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Shearer',
    type: 'Squaddie',
    guilds: ['Shepherds'],
    size: '30mm',
    avatar: '/GuildBall/avatars/shearer.jpg',
    health: 16,
    influence: 1,
    influenceMax: 4,
    cards: ['/GuildBall/cards/shearer-0.jpg', '/GuildBall/cards/shearer-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Ball of Light',
    type: 'Token',
    guilds: ['The Order'],
    size: '30mm',
  },
  {
    name: 'Seasoned Brisket',
    type: 'Captain',
    guilds: ['The Order'],
    size: '30mm',
    avatar: '/GuildBall/avatars/seasoned_brisket.jpg',
    health: 15,
    influence: 4,
    influenceMax: 6,
    cards: [
      '/GuildBall/cards/sbrisket-0.jpg',
      '/GuildBall/cards/sbrisket-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Pride',
    type: 'Mascot',
    guilds: ['The Order'],
    size: '50mm',
    avatar: '/GuildBall/avatars/pride.jpg',
    health: 13,
    influence: 1,
    influenceMax: 3,
    cards: ['/GuildBall/cards/pride-0.jpg', '/GuildBall/cards/pride-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Benediction',
    type: 'Squaddie',
    guilds: ['The Order', 'The Union'],
    size: '40mm',
    avatar: '/GuildBall/avatars/benediction.jpg',
    health: 19,
    influence: 2,
    influenceMax: 4,
    cards: [
      '/GuildBall/cards/benediction-0.jpg',
      '/GuildBall/cards/benediction-1.jpg',
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Veteran Fangtooth',
    type: 'Squaddie',
    guilds: ['The Order'],
    size: '40mm',
    avatar: '/GuildBall/avatars/veteran_fangtooth.jpg',
    health: 22,
    influence: 2,
    influenceMax: 3,
    cards: [
      '/GuildBall/cards/vfangtooth-0.jpg',
      '/GuildBall/cards/vfangtooth-1.jpg',
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Grace',
    type: 'Squaddie',
    guilds: ['The Order', 'The Union'],
    size: '30mm',
    avatar: '/GuildBall/avatars/grace.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/grace-0.jpg', '/GuildBall/cards/grace-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: "Harry 'The Hat'",
    type: 'Squaddie',
    guilds: ['The Order', 'The Union'],
    size: '30mm',
    avatar: '/GuildBall/avatars/harry_the_hat.jpg',
    health: 19,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/harry-0.jpg', '/GuildBall/cards/harry-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Mist',
    type: 'Squaddie',
    guilds: ['The Order', 'The Union'],
    size: '30mm',
    avatar: '/GuildBall/avatars/mist.jpg',
    health: 12,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/mist-0.jpg', '/GuildBall/cards/mist-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Seasoned Spigot',
    type: 'Squaddie',
    guilds: ['The Order'],
    size: '30mm',
    avatar: '/GuildBall/avatars/seasoned_spigot.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/sspigot-0.jpg', '/GuildBall/cards/sspigot-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Blackheart',
    type: 'Captain',
    guilds: ['The Union'],
    size: '30mm',
    avatar: '/GuildBall/avatars/blackheart.jpg',
    health: 16,
    influence: 4,
    influenceMax: 6,
    cards: [
      '/GuildBall/cards/blackheart-0.jpg',
      '/GuildBall/cards/blackheart-1.jpg',
    ],
    maxMeleeRange: 2,
  },
  {
    name: 'Veteran Greede',
    type: 'Captain',
    guilds: ['The Union'],
    size: '40mm',
    avatar: '/GuildBall/avatars/veteran_greede.jpg',
    health: 20,
    influence: 6,
    influenceMax: 6,
    cards: ['/GuildBall/cards/vgreede-0.jpg', '/GuildBall/cards/vgreede-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Veteran Rage',
    type: 'Captain',
    guilds: ['The Union'],
    size: '30mm',
    avatar: '/GuildBall/avatars/veteran_rage.jpg',
    health: 17,
    influence: 4,
    influenceMax: 4,
    cards: ['/GuildBall/cards/vrage-0.jpg', '/GuildBall/cards/vrage-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Coin',
    type: 'Mascot',
    guilds: ['The Union'],
    size: '30mm',
    avatar: '/GuildBall/avatars/coin.jpg',
    health: 8,
    influence: 1,
    influenceMax: 2,
    cards: ['/GuildBall/cards/coin-0.jpg', '/GuildBall/cards/coin-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Strongbox',
    type: 'Mascot',
    guilds: ['The Union'],
    size: '40mm',
    avatar: '/GuildBall/avatars/strongbox.jpg',
    health: 10,
    influence: 1,
    influenceMax: 2,
    cards: [
      '/GuildBall/cards/strongbox-0.jpg',
      '/GuildBall/cards/strongbox-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Avarisse',
    type: 'Squaddie',
    guilds: ['The Union'],
    size: '40mm',
    avatar: '/GuildBall/avatars/avarisse.jpg',
    health: 20,
    influence: 2,
    influenceMax: 3,
    cards: [
      '/GuildBall/cards/avarisse-0.jpg',
      '/GuildBall/cards/avarisse-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Greede',
    type: 'Squaddie',
    guilds: ['The Union'],
    size: '30mm',
    avatar: '/GuildBall/avatars/greede.jpg',
    health: 7,
    influence: 0,
    influenceMax: 2,
    cards: ['/GuildBall/cards/greede-0.jpg', '/GuildBall/cards/greede-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Decimate',
    type: 'Squaddie',
    guilds: ['The Union'],
    size: '30mm',
    avatar: '/GuildBall/avatars/decimate.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: [
      '/GuildBall/cards/decimate-0.jpg',
      '/GuildBall/cards/decimate-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Fangtooth',
    type: 'Squaddie',
    guilds: ['The Union'],
    size: '40mm',
    avatar: '/GuildBall/avatars/fangtooth.jpg',
    health: 22,
    influence: 2,
    influenceMax: 3,
    cards: [
      '/GuildBall/cards/fangtooth-0.jpg',
      '/GuildBall/cards/fangtooth-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Gutter',
    type: 'Squaddie',
    guilds: ['The Union'],
    size: '30mm',
    avatar: '/GuildBall/avatars/gutter.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/gutter-0.jpg', '/GuildBall/cards/gutter-1.jpg'],
    maxMeleeRange: 2,
  },
  {
    name: 'Hemlocke',
    type: 'Squaddie',
    guilds: ['The Union'],
    size: '30mm',
    avatar: '/GuildBall/avatars/hemlocke.jpg',
    health: 12,
    influence: 2,
    influenceMax: 4,
    cards: [
      '/GuildBall/cards/hemlocke-0.jpg',
      '/GuildBall/cards/hemlocke-1.jpg',
    ],
    maxMeleeRange: 1,
  },
  {
    name: 'Minx',
    type: 'Squaddie',
    guilds: ['The Union'],
    size: '30mm',
    avatar: '/GuildBall/avatars/minx.jpg',
    health: 14,
    influence: 2,
    influenceMax: 4,
    cards: ['/GuildBall/cards/minx-0.jpg', '/GuildBall/cards/minx-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Rage',
    type: 'Squaddie',
    guilds: ['The Union'],
    size: '30mm',
    avatar: '/GuildBall/avatars/rage.jpg',
    health: 17,
    influence: 1,
    influenceMax: 1,
    cards: ['/GuildBall/cards/rage-0.jpg', '/GuildBall/cards/rage-1.jpg'],
    maxMeleeRange: 1,
  },
  {
    name: 'Snakeskin',
    type: 'Squaddie',
    guilds: ['The Union'],
    size: '30mm',
    avatar: '/GuildBall/avatars/snakeskin.jpg',
    health: 12,
    influence: 2,
    influenceMax: 4,
    cards: [
      '/GuildBall/cards/snakeskin-0.jpg',
      '/GuildBall/cards/snakeskin-1.jpg',
    ],
    maxMeleeRange: 1,
  },
];
