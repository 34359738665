import React, { Component, Fragment } from 'react';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import ActionButton from '../../../game/ActionButton';
import LoadMapDialog from './LoadMapDialog';

export default class extends Component {
  state = {
    open: false,
  };
  render() {
    return (
      <Fragment>
        <ActionButton
          icon={CloudUploadIcon}
          primary="Load Map"
          onClick={() => this.setState({ open: true })}
        />
        <LoadMapDialog
          connection={this.props.connection}
          log={this.props.log}
          game={this.props.game}
          user={this.props.user}
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
        />
      </Fragment>
    );
  }
}
